import "./MyTemplatesPage.css"
import "./JobPage.css"
import {useEffect, useState, useContext, useRef} from "react";
import { Link, useNavigate} from "react-router-dom";
// import {UserContext} from "../Context/UserContext";
import {LeftBarContext} from "../Context/LeftBarContext"
import {UserContext} from "../Context/UserContext";
import LoadMoreButton from '../Button/Button'
import Error from '../modals/Error'
import LoadConfirm from '../modals/LoadConfirm'
import Input from '../modals/Input'
import DelayButton from '../Button/DelayButton'

export default function MyTemplatesPage() {
    const [templates,setTemplates] = useState([])
    // const headItem = useRef(null) // last transaction requested

    // only set when click on search or more items
    const [headItem, setHeadItem] = useState(null) 
    

    const amount = 10 // numbers of transactions to request at a time
    const [reachEnd,setReachEnd] = useState(false)
    const [loading,setLoading] = useState(false)
    const [initialLoading,setInitialLoading] = useState(true)
    const [noFiles,setNoFiles] = useState(false) //no templates
    const [errorMsg, setErrorMsg] = useState(false)

    // const {status, setLock, setSelected} = useContext(UserContext); //re-renders when logout
    const {setLock, setSelected} = useContext(LeftBarContext)
    const { status, setStatus, userInfo, setUserInfo} = useContext(UserContext); //re-renders when logout
    const [search, setSearch] = useState("")
    const [openedDropdown, setOpenedDropdown] = useState("")

    const [deleteTemplateLoading, setDeleteTemplateLoading] = useState(false)
    const [deleteTemplateConfirmMsg, setDeleteTemplateConfirmMsg] = useState(null)
    const [idToDelete, setIdToDelete] = useState(null)

    //for changing title
    const [inputMsg, setInputMsg] = useState(false)
    const [newTitle, setNewTitle] = useState("")
    const [editTitleLoading, setEditTitleLoading] = useState(false)
    const [editTitleError, setEditTitleError] = useState("")
    const [showEditTitleError, setShowEditTitleError] = useState("")

    const [idToRename, setIdToRename] = useState(null)

    const [createTemplateLoading, setCreateTemplateLoading] = useState(false)
    const resumeTitleLimit = 200 //double check limit with backend

    const navigate = useNavigate();

    useEffect(() => {
        setLock(false)
        setSelected("templates")    
    }, []);

    //at search or headItem change or when started
    //even if search triggers it twice b/c setting headItem
    // it still works
    useEffect(() => {

        if (!headItem){
            // setTemplates([])
            setReachEnd(false)
            setLoading(false)
            setInitialLoading(true)
            // setNoFiles(false)
        }

        fetchTemplatesDelay()
    }, [headItem, search]);

    //only place to set nofiles
    useEffect(() => {
        //check if no files
        if (!templates.length){
            setNoFiles(true)
        } else {
            setNoFiles(false)
        }
    }, [templates]);

    useEffect(() => {
        const itemToDelete = templates.find(item => item._id === idToDelete);
        itemToDelete && setDeleteTemplateConfirmMsg({title: "Reminder", desc: `You are about to delete template: ${itemToDelete.title}, are you sure?`})
    }, [idToDelete]);

    useEffect(() => {
        const itemToRename = templates.find(item => item._id === idToRename);
        itemToRename && setInputMsg({title: "Edit Title", desc: ""})
    }, [idToRename]);

    // const initialize = () => {

    //     // headItem.current = null
    //     setHeadItem(null)
    //     setTemplates([])
    //     setReachEnd(false)
    //     setLoading(false)
    //     setInitialLoading(true)
    //     setNoFiles(false)

    // }


    //it's too fast, which pushes the loading down fast
    const fetchTemplatesDelay = async () => {
        setLoading(true)
        const timeout = setTimeout(() => {
            fetchTemplates()
        }, 400)
    }

    const fetchTemplates = async () =>{

        const response = await fetch(process.env.REACT_APP_API_URL + '/resumes', {
            method: 'POST',
            body: JSON.stringify({headItem: headItem, amount: amount, search: search}),
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
        })
        if (response.ok) {
            const jsonResponse = await response.json()
            // make sure the returned response is not empty and is the one we requested
            //both null or have same id
            if (!jsonResponse.headItem && !headItem || (jsonResponse.headItem && headItem && (jsonResponse.headItem._id === headItem._id)) && jsonResponse.search === search ){

                if (jsonResponse.templates.length) {
                    
                    //to force cleanup after reseting headItem
                    if (!jsonResponse.headItem){
                        setTemplates(jsonResponse.templates)
                    } else {
                        setTemplates([...templates, ...jsonResponse.templates])
                    }

                    // headItem.current = jsonResponse.templates[jsonResponse.templates.length - 1]

                }

                if (jsonResponse.templates.length < amount){
                    setReachEnd(true)
                }
                // first request returns nothing means it's empty
                if (!jsonResponse.headItem && !jsonResponse.templates.length){
                    setTemplates([])
                }
                setLoading(false)
                setInitialLoading(false)
            }else{
                console.log("result not up to date, refused")
            }

        } else{
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                setLoading(false)
                setInitialLoading(false)

                //if error reload
                setHeadItem(null)
            }
        }
    }

    const loadMore = () =>{
        templates ? setHeadItem(templates[templates.length - 1]) : setHeadItem(null)
        // fetchTemplatesDelay()
    }

    const handleSearch = (value) => {
        setSearch(value)
        setHeadItem(null)
    }

    function DaysAgo(date) {
        const createdAtDate = new Date(date)
        const currentDate = new Date()
        
        // console.log(`created ${createdAtDate.toISOString()} current ${currentDate.toISOString()}`)
        // Calculate the time difference in milliseconds
        const timeDifference = currentDate - createdAtDate;

        // console.log(timeDifference)
      
        // Calculate the number of days
        const daysAgo = Math.max(0, Math.floor(timeDifference / (1000 * 60 * 60 * 24)))

        if (daysAgo === 1){
            return "1 day ago"
        } else if (daysAgo < 100){
            return daysAgo + " days ago"
        } else {
            return "99+ days ago"
        }
    }

    function dropDown(ev, templateId) {
        ev.preventDefault();
        ev.stopPropagation();

        if (templateId === openedDropdown){
            setOpenedDropdown("");
        } else {
            setOpenedDropdown(templateId);
        }
    }

    function closeDropDown(templateId) {
        // check if some other dropdown replaced it or not
        if (templateId === openedDropdown){
            setOpenedDropdown("");
        }
    }

    function preventParentClick(ev) {
        ev.preventDefault();
        ev.stopPropagation();        
    }

    function createScan(templateId){
        navigate(`/scan?templateId=${templateId}`)
    }

    function remove(templateId){
        setIdToDelete(templateId)
    }



    async function handleTemplateDelete(){
        setDeleteTemplateLoading(true)
        const response = await fetch(process.env.REACT_APP_API_URL + '/delete-template', {
            method: 'POST',
            body: JSON.stringify({ "templateId": idToDelete }),
            headers: {'Content-Type': 'application/json'},
            credentials: 'include' // cookies will be considered credentials
        });
        if (response.ok) {
            const message = await response.json()
            const updatedArray = [...templates];
            const indexToDelete = updatedArray.findIndex(item => item._id === message._id);
            if (indexToDelete !== -1) {
                updatedArray.splice(indexToDelete, 1)
                setTemplates(updatedArray)

                // //all files shown are deleted
                // if (!updatedArray.length){ 
                //     // headItem.current = null
                //     if (reachEnd){
                //         setNoFiles(true)
                //     }
                // }

                //delete last item, update headItem
                // else if (headItem.current._id === message._id){
                //     headItem.current = updatedArray[updatedArray.length - 1]
                // }
            }

            //OPTIONAL CHECK: user can only delete one at a time due to UI limit anyways 
            if (idToDelete === message._id){
                setDeleteTemplateLoading(false)
                setDeleteTemplateConfirmMsg(null)
                setIdToDelete(null)
            }

        } else {
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                setErrorMsg({title: "Oops", desc: message})
                setDeleteTemplateLoading(false)
                setDeleteTemplateConfirmMsg(null)
                setIdToDelete(null)
            }
        }
    }

    function rename(templateId){
        setIdToRename(templateId)
    }

    async function editTitle() {
        setEditTitleLoading(true)
        setShowEditTitleError(false)
        const response = await fetch(process.env.REACT_APP_API_URL + '/resume-title', {
            method: 'PUT',
            body: JSON.stringify({templateId: idToRename, title: newTitle}),
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })
        if (response.ok) {
            const message = await response.json()

            const updatedArray = [...templates];
            const indexToReplace = updatedArray.findIndex(item => item._id === message._id);
            if (indexToReplace !== -1) {
                // Replace the item with the new item
                updatedArray[indexToReplace] = message
                updatedArray.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
                setTemplates(updatedArray)
            }

            //incase the edited one is headItem
            // if (headItem.current._id === message._id){
            //     headItem.current = updatedArray[updatedArray.length - 1]
            // }
 
            //double check
            if (idToRename === message._id && newTitle === message.title){
                setNewTitle("")
                setEditTitleLoading(false)
                setInputMsg(null)
                setIdToRename(null)
            }
        }else{
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                setEditTitleLoading(false)
                setEditTitleError(message)
                setShowEditTitleError(true)           
                // setNewTitle("")
                // setInputMsg(null)
                // setIdToRename(null)
                // setErrorMsg({title: "Oops", desc: message})
            }
        }
    }

    const createTemplate = async () =>{
        setCreateTemplateLoading(true)
        const response = await fetch(process.env.REACT_APP_API_URL + '/new-resume', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
        })
        if (response.ok) {
            setCreateTemplateLoading(false)
            response.json().then(templateData => {
                navigate(`/resume/${templateData._id}`)
            })
        } else{
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                setCreateTemplateLoading(false)
                setErrorMsg({title: "Oops", desc: message})
            }
        }
    }

    function CheckTitleError(value){

        if ( !isNotAllSpaces(value) ){
            return "Title cannot be empty."
        }

        if ( value.length > resumeTitleLimit){
            return `Input exceeds maximum character limit of ${resumeTitleLimit}.`
        }

        return ""
    }

    function isNotAllSpaces(inputString) {
        // Use a regular expression to check if the string contains at least one non-space character
        return /\S/.test(inputString);
    }

    return (
        <>  
            <Error errorMsg={errorMsg} setErrorMsg={setErrorMsg} />
            <LoadConfirm confirmMsg={deleteTemplateConfirmMsg} setConfirmMsg={setDeleteTemplateConfirmMsg} customClick={() => {handleTemplateDelete()}} customClose={() => {setIdToDelete(null)}} loading={deleteTemplateLoading} buttonText="Confirm"/>
            <Input inputMsg={inputMsg} setInputMsg={setInputMsg} 
                buttonText="Confirm" value={newTitle} 
                setValue={setNewTitle} placeholder="Resume title" 
                customClick={editTitle} customClose={() => {setIdToRename(null)}} 
                loading={editTitleLoading} setLoading={setEditTitleLoading} CheckError={CheckTitleError} 
                bottomError={editTitleError} showBottomError={showEditTitleError} 
                setShowBottomError={setShowEditTitleError} />
            <div className="templates-page-wrapper">
                <div className="template-page-top-bar">
                    <div className="top-bar-top">
                        <div className="top-bar-top-left">
                            <div className="top-bar-search">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="top-bar-search-icon">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                </svg>

                                <input 
                                    className="top-bar-search-input"
                                    type="text" 
                                    placeholder={"Search resume"}
                                    value={search} 
                                    maxLength="100"
                                    onChange={ev => handleSearch(ev.target.value)}/>  
                            </div>
                        </div>
                        <div className="top-bar-top-right">
                            <DelayButton buttonClassName="top-bar-new-template" buttonClassNameLoading="top-bar-new-template" isLoading={createTemplateLoading} onClick={() => {createTemplate()}}>
                                <div className="top-bar-new-template-inner-wrapper">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="add-event-plus">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                    </svg>
                                    New resume
                                </div>
                            </DelayButton>
                            <Link className='job-page-account-circle' to={"/membership"}>
                                <div className='job-page-account-text'>{userInfo.username[0].toUpperCase()}</div>
                            </Link>
                        </div>                     
                    </div>
                    <div className="top-bar-bottom">
                        <div className="select-template-table-keys">
                            <div className="template-header-title">Resume title</div>
                            <div className="template-update-time">Last edited</div> 
                        </div>
                    </div>
                </div>
                <div className={initialLoading ? "templates-page-templates-scroll y-no-scroll" : "templates-page-templates-scroll"}>
                    {/* <div className="templates-page-templates-scroll-inner"> */}
                        {!initialLoading && templates.length > 0 && templates.map(template => (
                            <Link className="file" key={template._id} to={`/resume/${template._id}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white" className="templates-page-file-icon">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                </svg>
                                <div className="template-title">{template.title}</div>
                                <div className="template-right-wrapper">
                                    <div className="template-update-time">{DaysAgo(template.updatedAt)}</div>
                                    <div className="template-dropdown" tabIndex={0} onBlur={() => {closeDropDown(template._id)}}>
                                        <div 
                                            className={`template-dropdown-menu ${openedDropdown === template._id && "selected-dropdown"}`}
                                            onClick={(ev) => {preventParentClick(ev)}}
                                        >
                                            <div className="template-dropdown-item" onClick={() => {rename(template._id)}}>Edit title</div>
                                            <div className="template-dropdown-item" onClick={() => {createScan(template._id)}}>Create scan</div>
                                            <div className="template-dropdown-item" onClick={() => {remove(template._id)}}>Remove</div>
                                        </div>
                                        <div className="templates-page-more-icon-wrapper" onClick={(ev) => {dropDown(ev, template._id)}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="templates-page-more-icon">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                                            </svg>
                                        </div>
                                    </div>  
                                </div>
                            
                            </Link>
                        ))}
                        {initialLoading && 
                            <>
                                <div className="skeleton-file">
                                    <div className="template-title">
                                        <div className="skeleton skeleton-text-no-margin last-line"></div>
                                    </div>
                                    <div className="template-right-wrapper">
                                        <div className="skeleton skeleton-text-no-margin"></div>
                                    </div>                                   
                                </div>
                                <div className="skeleton-file">
                                    <div className="template-title">
                                        <div className="skeleton skeleton-text-no-margin last-line"></div>
                                    </div>
                                    <div className="template-right-wrapper">
                                        <div className="skeleton skeleton-text-no-margin"></div>
                                    </div>                                   
                                </div>
                                <div className="skeleton-file">
                                    <div className="template-title">
                                        <div className="skeleton skeleton-text-no-margin last-line"></div>
                                    </div>
                                    <div className="template-right-wrapper">
                                        <div className="skeleton skeleton-text-no-margin"></div>
                                    </div>                                   
                                </div>
                                <div className="skeleton-file">
                                    <div className="template-title">
                                        <div className="skeleton skeleton-text-no-margin last-line"></div>
                                    </div>
                                    <div className="template-right-wrapper">
                                        <div className="skeleton skeleton-text-no-margin"></div>
                                    </div>                                   
                                </div>
                                <div className="skeleton-file">
                                    <div className="template-title">
                                        <div className="skeleton skeleton-text-no-margin last-line"></div>
                                    </div>
                                    <div className="template-right-wrapper">
                                        <div className="skeleton skeleton-text-no-margin"></div>
                                    </div>                                   
                                </div>
                                <div className="skeleton-file">
                                    <div className="template-title">
                                        <div className="skeleton skeleton-text-no-margin last-line"></div>
                                    </div>
                                    <div className="template-right-wrapper">
                                        <div className="skeleton skeleton-text-no-margin"></div>
                                    </div>                                   
                                </div>
                                <div className="skeleton-file">
                                    <div className="template-title">
                                        <div className="skeleton skeleton-text-no-margin last-line"></div>
                                    </div>
                                    <div className="template-right-wrapper">
                                        <div className="skeleton skeleton-text-no-margin"></div>
                                    </div>                                   
                                </div>
                                <div className="skeleton-file">
                                    <div className="template-title">
                                        <div className="skeleton skeleton-text-no-margin last-line"></div>
                                    </div>
                                    <div className="template-right-wrapper">
                                        <div className="skeleton skeleton-text-no-margin"></div>
                                    </div>                                   
                                </div>
                            </>
                        }
                        {noFiles && !initialLoading && reachEnd && <div className="empty">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="empty-icon">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                            </svg>
                            No templates
                        </div>}
                        <div className="templates-scroll-bottom">
                            {!noFiles && reachEnd && <div className="no-more">You've reached the end</div>}
                            {!reachEnd && !initialLoading && 
                                <LoadMoreButton buttonClassName="load-more-button" buttonClassNameLoading="load-more-button-loading" isLoading={loading} onClick={() => {loadMore()}}>Load More</LoadMoreButton>
                            }
                        </div>
                    {/* </div> */}
                </div>
            </div>
        </>

    )

}