import { useContext } from 'react'
import { FormContext } from "./Context/FormContext"
import SectionDetailsOverview from "./SectionDetailsOverview"
import { v4 as uuidv4 } from 'uuid'

export default function FormatAndStyle({results}){

    const suggestionTitle = (suggestion) => {
        if ( suggestion["type"] === "Email" ){
            if ( suggestion["success"] ){
                return suggestion["email"]
            }else{
                return  "No Email Found"
            }
        } else if ( suggestion["type"] === "Phone" ){
            if ( suggestion["success"] ){
                return suggestion["phone"]
            }else{
                return  "No Phone Number Found"
            }
        } else if ( suggestion["type"] === "Personal Pronouns" ){
            if ( suggestion["success"] ){
                return "No Personal Pronouns Found"
            }else{
                const separator = ', ';
                const combinedPronouns = suggestion["pronouns"].join(separator);
                return combinedPronouns
            }
        } else if ( suggestion["type"] === "Sections" ){
            if ( suggestion["success"] ){
                return suggestion.header
            }else{
                return `Missing ${suggestion.headerTitle} Section`
            }
        } else if ( suggestion["type"] === "Word Count" ){
            if ( suggestion["success"] ){
                return "Perect length"
            } else if (suggestion.words > 800){
                return "Resume too long"
            } else {
                return "Resume too short"
            }
        }
    }

    const suggestionDesc = (suggestion) => {
        if ( suggestion["type"] === "Email" ){
            if ( suggestion["success"] ){
                return <div>We found your email <b>{suggestion["email"]}</b> in your resume, this helps recruiters contact you.</div>
            }else{
                return  <div>You did not include a email in your resume. Consider adding one to help recruiters contact you.</div>
            }
        } else if ( suggestion["type"] === "Phone" ){
            if ( suggestion["success"] ){
                return <div>We found your phone number <b>{suggestion["phone"]}</b> in your resume, this helps recruiters contact you.</div>
            }else{
                return  <div>You did not include a phone number in your resume. Consider adding one to help recruiters contact you.</div>
            }
        } else if ( suggestion["type"] === "Personal Pronouns" ){
            if ( suggestion["success"] ){
                return <div>We didn't find any personal pronouns like I or me, good job!</div>
            }else{
                return  <div>Using personal pronouns such as <b>{suggestion["pronouns"][0]}</b> could can come across as unprofessional. Resumes should be written without a subject.</div>
            }
        } else if ( suggestion["type"] === "Sections" ){
            if ( suggestion["success"] ){
                return  <div>We found the <b>{suggestion["headerTitle"].toLowerCase()}</b> section header, this makes your resume more readable.</div>
            } else if ( suggestion["headerTitle"] === "Education" ) {
                return <div>We couldn't find the <b>education</b> section, which is essential for readability. Common headers include: "Education" and "Educational History".</div>
            } else if ( suggestion["headerTitle"] === "Experience" ) {
                return <div>We couldn't find the <b>experience</b> section, which is essential for readability. Common headers include: "Experience", "Work Experience" and "Professional Experience".</div>
            }
        } else if ( suggestion["type"] === "Word Count" ){
            return  <div>There are <b>{suggestion.words}</b> words in your resume. The ideal word count for most resumes is between 400 and 800.</div>
        }
    }


    return(
        <div>    
            <SectionDetailsOverview results={results} section={"Format & Style"}/>
            {/* <div>{results["formatSuggestions"][0].email}</div> */}
            <div className="suggestions-container">
                {results["formatSuggestions"].length > 0 && results["formatSuggestions"].map(suggestion => (
                    <div className="suggestion" key={uuidv4()}>
                         <div className="suggestion-top-bar">
                             <div className="suggestion-type">{suggestion["type"]}</div>
                         </div>
                         <div className="suggestion-main">
                            <div className="suggestion-title-container">
                                <div className= {suggestion["success"]? "suggestion-icon-success":"suggestion-icon-fail"}>{
                                    suggestion["success"]?
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="22px" height="22px" strokeWidth="2" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                    </svg>:
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="22px" height="22px" strokeWidth="2" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                }</div>
                                <div className={suggestion["remove"] ? "suggestion-title-remove" : "suggestion-title"}>{suggestionTitle(suggestion)}</div>
                            </div>
                            <div className="suggestion-desc">{suggestionDesc(suggestion)}</div>
                         </div>
                    </div>
                ))}
            </div>  
        </div>       
    )
}