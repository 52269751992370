// status = pending unless server responses
// display loading while pending (needed when refresh page)
export default function Loading ({text}) {
    return <div className='loader-outer-container'>
        <div className='loader-container'>
            <svg width="101" height="137" viewBox="0 0 101 137" fill="none" xmlns="http://www.w3.org/2000/svg" className="loader-logo">
                <path fillRule="evenodd" clipRule="evenodd" d="M3 108C4.99461 113.986 8.02652 119.068 12.0957 123.246C16.2461 127.562 21.3373 130.827 27.3692 133.041C33.4011 135.255 40.8441 136.361 49.6983 136.361C66.2998 136.361 78.7233 131.492 86.9688 121.752C90.0275 118.17 92.3247 113.586 93.8603 108H69.4488C67.9573 109.762 65.9669 111.275 63.4776 112.538C59.7699 114.42 54.8724 115.36 48.7852 115.36C44.0261 115.36 39.9033 114.696 36.417 113.368C33.0967 112.156 30.3537 110.367 28.1878 108H3Z" fill="#c1bcff"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M55 25.4634C57.4326 25.9074 59.9392 26.1394 62.5 26.1394C79.8499 26.1394 94.7132 15.4925 100.913 0.37579C97.5146 0.878803 94.0378 1.13939 90.5 1.13939C86.1669 1.13939 81.9252 0.748478 77.8081 0C73.899 4.49725 68.296 7.31465 62.0722 7.31465C59.5937 7.31465 57.2138 6.86788 55 6.04617V25.4634Z" fill="#c1bcff"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M48.5 102.14C75.2858 102.14 97 80.4255 97 53.6396C97 26.8538 75.2858 5.13965 48.5 5.13965C21.7142 5.13965 0 26.8538 0 53.6396C0 80.4255 21.7142 102.14 48.5 102.14ZM48 80.1396C61.8071 80.1396 73 68.2752 73 53.6396C73 39.0041 61.8071 27.1396 48 27.1396C34.1929 27.1396 23 39.0041 23 53.6396C23 68.2752 34.1929 80.1396 48 80.1396Z" fill="#c1bcff"/>
            </svg>  
            <svg className="loader-circle-container">
                <circle cx="19" cy="19" r="19"></circle>
            </svg>
            <div className='loading-text'>{text}</div>
        </div>
    </div>
}

