import "./ApplyPage.css"
import { useState, useEffect, useContext } from 'react'
import {Link, useParams, useNavigate} from "react-router-dom"
import {UserContext} from "../Context/UserContext"
import {ApplicationContext} from "../Context/ApplicationContext"
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer, toast, Slide } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Application from "../Application.js"
import Description from "../Description.js"

export default function ApplyPage() {
    const {userInfo, setStatus, setUserInfo} = useContext(UserContext)
    const {setColor, setLoading, newPhone, newLocation, newEmail, setNewEmail, 
        newExperience, newEducation, newResume, newFirstName, 
        newLastName, expArray, eduArray, questions, setQuestions, title, setTitle,
        phone, setPhone, address, setAddress, experience, setExperience, education, setEducation,
        resume, setResume, questionData, setQuestionData, answers} = useContext(ApplicationContext)
    const {company, id} = useParams() //access the parameters of the current URL
    
    //set which page we are on
    const [application, setApplication] = useState(false)

    useEffect(() => {
        fetchJobPost() 
    }, [])

    const sendApplication = async () =>{
        setLoading(true)

        //check for errors here
        if (false){
            const timeout = setTimeout(() => {
                setLoading(false)
            }, 400);
        
            return () => {
                clearTimeout(timeout);
            };
        }

        const answersCopy = { ...answers }
        const otherFiles = []
        let index = 0

        const data = new FormData() //we send text and files together
        data.set('id', id)
        data.set('newPhone', newPhone)
        data.set('newLocation', newLocation)
        data.set('newResume', newResume[0])
        data.set('newFirstName', newFirstName)
        data.set('newLastName', newLastName)
        data.set('expArray', expArray)
        data.set('eduArray', eduArray)

        for (const ans in answers) {
            if (questionData[ans].type === "upload"){
                answersCopy[ans] = {fileIndex: index}

                data.append("otherFiles", answers[ans][0])
                index += 1
            }
        }

        data.set('answers', answersCopy)

        const response = await fetch(process.env.REACT_APP_API_URL + "/apply", {
            method: 'POST',
            body: data,
            credentials: 'include'
        })
        if (response.ok) {
            setLoading(false)
            alert("success")
        } else{
            console.log("failed send application")
        }
    }

    const fetchJobPost = async () =>{
        const response = await fetch(process.env.REACT_APP_API_URL + "/job-post", {
            method: 'POST',
            body: JSON.stringify({id, company}),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        if (response.ok) {
            const jsonData = await response.json()
            const jobPostData = jsonData.jobPost

            setTitle(jobPostData.title)
            setPhone(jobPostData.phone)
            setAddress(jobPostData.address)
            setExperience(jobPostData.experience)
            setEducation(jobPostData.education)
            setResume(jobPostData.resume)
            setColor(jobPostData.color)
            setQuestions(jobPostData.questions)
            setQuestionData(jsonData.questionData)
        } else{
            console.log("failed to get job post")
        }
    }

    return (
        <div className="apply-page">
            <div className="apply-page-top">
                <div className="apply-page-top-text"></div>
                <div className="apply-page-top-buttons">
                    <div className={application ? "apply-page-top-button" 
                    : "apply-page-top-button apply-page-top-button-selected"} onClick={() => {setApplication(false)}}>Description</div>
                    <div className={application ? "apply-page-top-button apply-page-top-button-selected" 
                    : "apply-page-top-button"} onClick={() => {setApplication(true)}}>Application</div>
                </div>
            </div>
            <div className="apply-page-main">{
                application ? 
                <Application sendApplication={sendApplication}/> 
                : <Description />
            }</div>
        </div>
    )
}