import { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {UserContext} from "./Context/UserContext";
import LoadConfirm from './modals/LoadConfirm'
import EventInput from './modals/EventInput'
import Error from './modals/Error'

export default function Event({_id, job, title, description, date, type, completed, createdAt, updatedAt, handleDelete, isLast, selectedJob, setSelectedJob, jobs, setJobs, dashboard = false}){
    const [inputMsg, setInputMsg] = useState(false)
    const [editEventLoading, setEditEventLoading] = useState(false)

    //values to be edited
    const [newDate, setNewDate] = useState("")
    const [newTime, setNewTime] = useState("")
    const [newType, setNewType] = useState("")
    const [newDescription, setNewDescription] = useState("")
    const [newCompleted, setNewCompleted] = useState(null)

    const [inputErrMsg, setInputErrMsg] = useState(null)
    const [showInputErrMsg, setShowInputErrMsg] = useState(false)
    const {setStatus, setUserInfo} = useContext(UserContext)

    const [deleteLoading, setDeleteLoading] = useState(false)
    const [confirmMsg, setConfirmMsg] = useState(false)
    const [errorMsg, setErrorMsg] = useState(false)
    

    // useEffect(() => {
    //     setNewDate(date)
    //     setNewDescription(description)
    // },[date, type, completed, description])

    function localdate(date){
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true, // Use 12-hour time format with AM/PM
        };
        const dateTime = new Date(date);
        return dateTime.toLocaleString(undefined, options);
    }

    function localdateOnly(date){
        const options = {
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true, // Use 12-hour time format with AM/PM
        };
        const dateTime = new Date(date);
        return dateTime.toLocaleString(undefined, options);
    }

    function upcoming(date){
        const now = new Date()
        const target = new Date(date)
        const timeDifference = target.getTime() - now.getTime();

        //allow 1 second client time delay
        if (timeDifference > 1000){
            return true
        } else {
            return false
        }
    }

    async function editEvent(){
        setEditEventLoading(true)

        var year
        var month
        var day
        try{
            const isoDate = new Date(newDate);
            year = isoDate.getFullYear();
            month = isoDate.getMonth(); // Months are 0-based (0 = January, 11 = December)
            day = isoDate.getDate();

        } catch(e){}

        var isoDateTimeString
        if (newDate && newTime){
            isoDateTimeString = "invalid date"
        }
        try{
            const [hours, minutes] = newTime.split(':')
            if (hours >= 0 && hours < 24 && minutes >= 0 && minutes < 60){
                const combinedDate = new Date(year, month, day, hours, minutes, 0);
                isoDateTimeString = combinedDate.toISOString();
            }
        } catch(e){}

        const response = await fetch(process.env.REACT_APP_API_URL + '/event', {
            method: 'PUT',
            body: JSON.stringify({eventId: _id, date: isoDateTimeString, type: newType, description: newDescription, completed: newCompleted}),
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })
        if (response.ok) {

            const eventInfo = await response.json()
            const updatedArray = [...selectedJob.events];
            const indexToReplace = updatedArray.findIndex(item => item._id === _id);
            if (indexToReplace !== -1) {
                // Replace the item with the new item
                updatedArray[indexToReplace] = eventInfo.updatedEvent
                const copyOfSelectedJob = { ...selectedJob }
                copyOfSelectedJob.events = updatedArray
                copyOfSelectedJob.updatedAt = eventInfo.jobDoc.updatedAt
                //sort by newest first
                updatedArray.sort((a, b) => new Date(b.date) - new Date(a.date));
                setSelectedJob(copyOfSelectedJob)
            }

            const copyOfJobs = [...jobs]
            const jobIndexToReplace = copyOfJobs.findIndex(item => item._id === eventInfo.jobDoc._id);
            if (jobIndexToReplace !== -1) {
                // Replace the item with the new item
                copyOfJobs[jobIndexToReplace].updatedAt = eventInfo.jobDoc.updatedAt
                copyOfJobs.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
                setJobs(copyOfJobs)
            }

            // setInputErrMsg("")
            setEditEventLoading(false)
            setInputMsg(null)
        }else{
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                setEditEventLoading(false)
                // show error in form not new modal
                setInputErrMsg(message)
            }
        }
    }

    function capitalizeFirstLetter(inputString) {
        // Check if the inputString is empty or null
        if (!inputString) {
            return inputString;
        }
        
        // Capitalize the first letter and concatenate it with the rest of the string
        return inputString.charAt(0).toUpperCase() + inputString.slice(1);
    }

    async function openInputEventModal(){

        const dateObj = new Date(date)
        // first update form values to current value
        setNewDate(dateObj)

        const hours = dateObj.getHours().toString().padStart(2, '0'); // Get hours and pad with leading zero if needed
        const minutes = dateObj.getMinutes().toString().padStart(2, '0'); // Get minutes and pad with leading zero if needed

        const hhmm = `${hours}:${minutes}`; // Combine hours and minutes in "HH:MM" format
        setNewTime(hhmm)
        setNewDescription(description)

        if (type === "assessment"){
            if (completed === true){
                setNewCompleted({value: true, label: "Completed"})
            } else if (completed === false){
                setNewCompleted({value: false, label: "Incomplete"})
            } else {
                setNewCompleted(null)
            }
        } else {
            setNewCompleted(null)
        }

        if (type === "interview" || type === "assessment"){
            setNewType({value: type, label: capitalizeFirstLetter(type)})
        } else {
            setNewType({value: type, label: `Status - ${capitalizeFirstLetter(type)}`})
        }


        setInputMsg({title: "Edit Event", desc: ""})
        setInputErrMsg("")
    }

    return (
        <>
            {dashboard ?
            <>  
                <Link className="event dashboard-event" to={`/jobs?jobId=${job._id}`}>
                    <div className="dashboard-event-left">
                        {type === "assessment" ? 
                            <div className='event-left-icon-wrapper'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="event-left-icon">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.666 3.888A2.25 2.25 0 0013.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 01-.75.75H9a.75.75 0 01-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 01-2.25 2.25H6.75A2.25 2.25 0 014.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 011.927-.184" />
                                </svg>
                            </div> 
                        :<div className='event-left-icon-wrapper interview'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="event-left-icon">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                            </svg>
                        </div> }
                    </div>
                    <div className="event-right">
                        <div className="dashboard-event-title">{title}</div> 
                        <div className="dashboard-event-company">{job.company}</div> 
                        <div className="dashboard-event-date">{type === "assessment" ? "Due " + localdateOnly(date) : localdateOnly(date)}</div>
                        {/* don't show year */}
                    </div>
                </Link>                                      
            </>
            :<>  
                <Error errorMsg={errorMsg} setErrorMsg={setErrorMsg} />
                <LoadConfirm confirmMsg={confirmMsg} setConfirmMsg={setConfirmMsg} customClick={() => {handleDelete(_id, setDeleteLoading, setConfirmMsg, setErrorMsg)}} loading={deleteLoading} buttonText="Confirm"/>
                <EventInput inputMsg={inputMsg} setInputMsg={setInputMsg} buttonText="Confirm" 
                    customClick={editEvent} loading={editEventLoading} setLoading={setEditEventLoading}
                    newDate={newDate} setNewDate={setNewDate} 
                    newTime={newTime} setNewTime={setNewTime} 
                    newType={newType} setNewType={setNewType}
                    newDescription={newDescription} setNewDescription={setNewDescription}
                    newCompleted={newCompleted} setNewCompleted={setNewCompleted}
                    errorMsg={inputErrMsg} setErrorMsg={setInputErrMsg}
                    setShowBottomError={setShowInputErrMsg}
                    setConfirmMsg={setConfirmMsg}
                />
                <>
                    <div className="event" onClick={() => {openInputEventModal()}}>
                        <div className="event-left">
                            <div className={upcoming(date) ? "event-left-circle-upcoming" : "event-left-circle"}></div> 
                            {!isLast && <div className={upcoming(date) ? "event-line-upcoming" : "event-line"}></div>}
                        </div>
                        <div className="event-right">
                            <div className="event-right-top">
                                <div className="event-title">{title}</div> 
                                {(completed === true || completed === false) && 
                                    <div className={completed ? "event-complete" : "event-complete event-incomplete"}>{completed ? "Completed" : "Incomplete"}</div>}
                            </div>
                            <div className="event-date">{localdate(date)}</div>
                            <div className="event-description">{description}</div> 
                        </div>
                    </div>                                      
                </>
            </>}
        </>
    )
}