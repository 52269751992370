import { useContext, useEffect, useState, useRef } from "react"
import { Link } from "react-router-dom"
import { FormContext } from "./Context/FormContext"
import ScoreBar from './ScoreBar'
import SectionButton from './SectionButton'
import SectionDetails from './SectionDetails'



export default function DemoResults(){
    const {setDemoResults, demoResults, setDemoPage} = useContext(FormContext) //add needed contexts
    // const [score, setScore] = useState(0)
    const [section, setSection] = useState("")
    const scrollToObjectRef = useRef(null);
    const circleWidth = 200
    const radius = 80
    const fontSize = 45
    const strokeWidth = 10

    const scrollTo = () => {
        // Scroll to the object smoothly
        scrollToObjectRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    function rescan() {
        setDemoPage(0)
        setDemoResults(null)
    }

    return (
        <div className="results-form">
            <div className="results-container">
                <div className="results-summary">
                    <div id="results-top-bar">
                        <a onClick={() => rescan()} id="results-rescan">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-3 -3 30 30" strokeWidth="1.5" stroke="#767676" className="results-rescan-icon">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
                            </svg>
                            <div id="rescan-text">Rescan</div>
                        </a>
                    </div>
                    <div id="results-main">
                        <div id="results-left-bar">
                            <div id="your-resume-score-title">Your Resume Score</div>
                            <ScoreBar percentage={demoResults["overallScore"]} 
                                circleWidth={circleWidth} radius={radius} fontSize={fontSize} strokeWidth={strokeWidth}/>
                            <div id="result-comment">It’s a start! Aim for a score of <b>70</b> before applying.</div>
                            <button id="scan-to-create-disabled">Edit Resume</button>
                        </div>
                        <div id="results-right-bar">
                            <SectionButton 
                                title={"Hard Skills"}
                                score={demoResults["hardSkillScore"]}
                                section={section}
                                setSection={setSection}
                                scrollTo={scrollTo}
                            />
                            <SectionButton 
                                title={"Soft Skills"}
                                score={demoResults["softSkillScore"]}
                                section={section}
                                setSection={setSection}
                                scrollTo={scrollTo}
                            />
                            <SectionButton 
                                title={"Credentials"}
                                score={demoResults["credentialsScore"]}
                                section={section}
                                setSection={setSection}
                                scrollTo={scrollTo}
                            />
                            <SectionButton 
                                title={"Format & Style"}
                                score={demoResults["formatScore"]}
                                section={section}
                                setSection={setSection}
                                scrollTo={scrollTo}
                            />
                        </div>
                    </div>
                </div>
                <div id="results-section-detail-title" ref={scrollToObjectRef}>Section Details</div>
                <SectionDetails section={section} results={demoResults} jobTitle={"Software Engineering Intern"}/>
            </div>
        </div>
    )
}