import {useState, useEffect} from 'react'

export default function IndexPageScoreBar({percentage, circleWidth, radius, strokeWidth}){
    // const circleWidth = 140
    // const radius = 60
    const dashArray = radius * Math.PI * 2
    const [dashOffset, setDashOffset] = useState(0)
    const [progressValue, setProgressValue] = useState(0)
    const scoreBarGradient = "score-bar-gradient"
    // var dashOffset = dashArray - (dashArray * progressValue) / 100
    useEffect(() => {
        if (progressValue < percentage){
            setTimeout(() => {
                setProgressValue(progressValue + 1)
                setDashOffset(dashArray - (dashArray * progressValue) / 100)
            }, 5)
        }
        if (progressValue > percentage){
            setTimeout(() => {
                setProgressValue(progressValue - 1)
                setDashOffset(dashArray - (dashArray * progressValue) / 100)
            }, 5)
        }

    }, [progressValue, percentage])

    return(
        <div 
            className='score-container-index-page'
            // style={`width: ${radius * 3}px; height: ${radius * 3}px`}
        >
            <svg
                className='svg-circle'
                width={circleWidth}
                height={circleWidth}
                viewBox={`0 0 ${circleWidth} ${circleWidth}`}
            >
                <defs>
                    <linearGradient id={scoreBarGradient} gradientTransform="rotate(80)">
                    <stop offset="0%" stopColor="#f698ff" />
                    <stop offset="75%" stopColor="#aca4ff" />
                    </linearGradient>
                </defs>
                <circle
                    cx={circleWidth / 2}
                    cy={circleWidth / 2}
                    strokeWidth={strokeWidth}
                    r={radius}
                    className="circle-background-index-page"
                />

                <circle
                    cx={circleWidth / 2}
                    cy={circleWidth / 2}
                    strokeWidth={strokeWidth}
                    r={radius}
                    className="circle-progress-index-page"
                    style={{
                        strokeDasharray: dashArray,
                        strokeDashoffset: dashOffset,
                        stroke: `url(#${scoreBarGradient})`
                    }}
                    transform={`rotate(-90 ${circleWidth / 2} ${circleWidth / 2})`}
                />
            </svg>
            <div x="50%" y="50%" dy="0.3em" textAnchor='middle' 
                className='index-gradient-font-reverse circle-text-index-page'>{progressValue}</div>
        </div>
    )

}