import { useState, createContext} from "react"

export const ApplicationContext = createContext({})

export function ApplicationContextProvider({children}) {
    const [expArray, setExpArray] = useState([])
    const [eduArray, setEduArray] = useState([])
    const [loading, setLoading] = useState(false)
    

    //actual info entered by applicant
    const [newFirstName, setNewFirstName] = useState('')
    const [newLastName, setNewLastName] = useState('')
    const [newPhone, setNewPhone] = useState('')
    const [newEmail, setNewEmail] = useState('')
    const [newLocation, setNewLocation] = useState('')
    const [newExperience, setNewExperience] = useState('')
    const [newEducation, setNewEducation] = useState('')
    const [newResume, setNewResume] = useState('')

    //from api
    const [title, setTitle] = useState('')
    const [questions, setQuestions] = useState([])
    const [questionData, setQuestionData]= useState({})
    const [color, setColor] = useState("")

    const [answers, setAnswers] = useState({})
    const [errors, setErrors]= useState({})

    //whether required or not
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState('')
    const [experience, setExperience] = useState('')
    const [education, setEducation] = useState('')
    const [resume, setResume] = useState('')

    return (
        <ApplicationContext.Provider value={{expArray, setExpArray, eduArray, setEduArray, 
            color, setColor, loading, setLoading, newPhone, newEmail, setNewEmail, 
            setNewPhone, newLocation, setNewLocation, answers, setAnswers,
            newExperience, setNewExperience, newEducation, setNewEducation, newResume, setNewResume,
            newFirstName, setNewFirstName, newLastName, setNewLastName, questions, setQuestions, title, setTitle,
            phone, setPhone, address, setAddress, experience, setExperience, education, setEducation,
            resume, setResume, questionData, setQuestionData, errors, setErrors}}>
            {children}
        </ApplicationContext.Provider>
    )
}
