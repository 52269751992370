import { useState } from "react";
// import { DialogContext } from "./Context/DialogContext";
import ConfirmImg from '../images/confirm.svg';
import Modal from './Modal'
import './Modal.css'
import DelayButton from '../Button/DelayButton'

// bottomError is shown on the bottom, it is the return from server
export default function Input({inputMsg, setInputMsg, customClick, customClose, buttonText, placeholder, value, setValue, loading, setLoading, CheckError, bottomError, showBottomError, setShowBottomError}){
    const [errorMsg, setErrorMsg] = useState(false)
    const [showError, setShowError] = useState(false)

    function handleClose() {
        if (!loading){
            customClose && customClose()
            setValue("")
            setInputMsg(null)
            // setErrorMsg("")
            setShowError(false)
            setShowBottomError(false)
        }

    }

    function handleClick(){
        setLoading(true)
        setShowError(false)
        setShowBottomError(false)

        let errMsg = ""

        if (CheckError){
            errMsg = CheckError(value)
        }

        if (errMsg){
            setErrorMsg(errMsg)
            setShowError(true)
            const timeout = setTimeout(() => {
                setLoading(false)
            }, 400);
        
            return () => {
                clearTimeout(timeout);
            };
        }

        customClick && customClick()
        // setValue("")
        // setInputMsg(null)        
    }

    function handleText(value){
        setShowError(false)
        setValue(value)
    }

    return (
        <Modal open={inputMsg} onClose={() => handleClose()}>
            {inputMsg &&
                <div className="input-modal-inner-wrapper">
                    <div className="input-modal-title">{inputMsg.title}</div>
                    {inputMsg.desc && <div className="input-modal-text">{inputMsg.desc}</div>}
                    <div className="input-modal-main-area">
                        <div className="input-modal-section">
                            <div className="input-modal-label">{placeholder}</div>
                            <input 
                                className={showError ? "input-modal-input-last input-modal-input-box-error" : "input-modal-input-last"}
                                type="text" 
                                // placeholder={placeholder}
                                value={value} 
                                onChange={ev => handleText(ev.target.value)}/>
                            <div className={ showError ? "input-modal-error input-modal-error-expanded" : "input-modal-error"}>{errorMsg}</div>
                        </div>
                    </div>
                    <div className={(!showBottomError) ? "input-error-msg input-error-msg-hidden" : "input-error-msg"}>{bottomError}</div>
                    <div className="input-modal-button-container">
                        <button className="input-modal-cancel" onClick={() => handleClose()}>Cancel</button>
                        {/* <button className="input-modal-button" onClick={() => handleClick()}>{buttonText}</button> */}
                        <DelayButton buttonClassName="input-modal-button" buttonClassNameLoading="input-modal-button-loading" isLoading={loading} onClick={() => handleClick()}>{buttonText}</DelayButton>
                    </div>
                </div>
            }
        </Modal>
    )
}