import { useContext, useState, forwardRef, useEffect } from "react"
import DatePicker from 'react-datepicker'
import Checkbox from './Checkbox'
import {ApplicationContext} from "./Context/ApplicationContext"
import Select from 'react-select'
import SelectStyleGeneric from './SelectStyleGeneric'

export default function Education({index, deleteEducation, newObj}){
    const {eduArray, setEduArray} = useContext(ApplicationContext)
    const [school, setSchool] = useState("")
    const [degree, setDegree] = useState("")
    const [major, setMajor] = useState("")
    const [gpa, setGpa] = useState("")
    const [from, setFrom] = useState(undefined)
    const [to, setTo] = useState(undefined)
    const [current, setCurrent] = useState(false)
    const [expanded, setExpanded] = useState(true)
    const [dropdown, setDropdown] = useState(false)

    const degreeOptions = [
        {value: "high school", label: "High School Diploma / GED"},
        {value: "associate", label: "Associate's Degree"},
        {value: "bachelor", label: "Bachelor's Degree"},
        {value: "master", label: "Master's Degree"},
        {value: "doctor", label: "Doctoral degree"}
    ]

    useEffect(() => {
        eduArray[index].newObj && document.getElementById("education" + index).scrollIntoView({
            behavior: 'smooth'
        });

        const newExp = {
            uuid: eduArray[index].uuid,
            school: school,
            degree: degree,
            major: major,
            gpa: gpa,
            from: from,
            to: to,
            current: current,
            newObj: false,
        }

        const newArray = [...eduArray]
        newArray[index] = newExp
        setEduArray(newArray)
    }, [])

    const majorChange = (e) => {
        setMajor(e.target.value)
    }

    const schoolChange = (e) => {
        setSchool(e.target.value)
    }

    // const degreeChange = (e) => {
    //     setDegree(e.target.value)
    // }

    const fromChange = (value) => {
        setFrom(value)
    }

    const toChange = (value) => {
        setTo(value)
    }

    const gpaChange = (e) => {
        setGpa(e.target.value)
    }

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <input className='input-modal-input' readOnly={true} onClick={onClick} value={value} ref={ref}></input>
    ))

    const handleSave = () => {
        const newExp = {
            uuid: eduArray[index].uuid,
            school: school,
            degree: degree,
            major: major,
            gpa: gpa,
            from: from,
            to: to,
            current: current,
        }

        // console.log(gpa)

        const newArray = [...eduArray]
        newArray[index] = newExp
        setEduArray(newArray)

        setExpanded(false)
    }

    const handleCancel = () => {

        if (!eduArray[index].major){
            deleteEducation(index)
        }

        setMajor(eduArray[index].major)
        setSchool(eduArray[index].school)
        setDegree(eduArray[index].degree)
        setFrom(eduArray[index].from)
        setTo(eduArray[index].to)
        setGpa(eduArray[index].gpa)
        setCurrent(eduArray[index].current)

        setExpanded(false)
    }

    const formatDate = () => {
        const fromDate = new Date(from);
        const formattedFrom = fromDate.toLocaleString("en-US", { month: "long", year: "numeric" });
        const toDate = new Date(to);
        const formattedTo = toDate.toLocaleString("en-US", { month: "long", year: "numeric" });

        return formattedFrom + " - " + formattedTo
    }

    const editClick = () => {
        setDropdown(false)
        setExpanded(true)
    }

    const deleteClick = () => {
        deleteEducation(index)
    }

    return(
        <div id={"education" + index}>
        {expanded ?
            <div className="apply-page-application-experience-box">
                <div className="application-question application-question">
                    <div className="application-question-title">
                        School <span className='required'>*</span>
                    </div>
                    <input className='input-modal-input blue-border' onChange={schoolChange} value={school}></input>
                </div>
                <div className="application-experience-title-company-wrapper">
                    <div className="application-question application-question-left">
                        <div className="application-question-title">
                            Degree <span className='required'>*</span>
                        </div>
                        <Select options={degreeOptions} 
                            // menuIsOpen={true}
                            placeholder=""
                            value={degree}
                            onChange={setDegree}
                            styles={SelectStyleGeneric({error: false})}
                            maxMenuHeight="150px"
                        />
                    </div>
                    <div className="application-question application-question-right">
                        <div className="application-question-title">
                            Major <span className='required'>*</span>
                        </div>
                        <input className='input-modal-input blue-border' onChange={majorChange} value={major}></input>
                    </div>
                </div>
                <div className="application-experience-title-company-wrapper">
                    <div className="application-question application-question-left">
                        <div className="application-question-title">
                            From <span className='required'>*</span>
                        </div>
                        <DatePicker
                            // className={showDateError && "input-modal-input-box-error"}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            selected={from}
                            onChange={(date) => fromChange(date)}
                            // customInput={<ExampleCustomInput />}
                        />
                    </div>
                    <div className="application-question application-question-right">
                        <div className="application-question-title">
                            To <span className='required'>*</span>
                        </div>
                        <DatePicker
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            // className={showDateError && "input-modal-input-box-error"}
                            selected={to}
                            onChange={(date) => toChange(date)}
                            disabled={current ? true : false}
                            // customInput={<ExampleCustomInput />}
                        />
                    </div>
                </div>
                <div className="application-question">
                    <div style={{fontSize: "0.8em", fontWeight: "500"}}>
                        <Checkbox checked={current} setChecked={setCurrent} text="Current education"/>
                    </div>
                </div>
                <div className="application-question">
                    <div className="application-question-title">
                        GPA <span className='application-question-optional'>{"(Optional)"}</span>
                    </div>
                    <input type="number" className='input-modal-input blue-border' onChange={gpaChange} value={gpa}></input>
                </div>
                <div className="application-experience-buttons">
                    <button className="input-modal-cancel" onClick={() => handleCancel()}>Cancel</button>
                    <button className="input-modal-button" onClick={() => handleSave()}>Save</button>
                </div>

            </div>
                
            :<div className="apply-page-application-experience-box">
                <div className="apply-page-application-experience-box-top">
                    <div className="apply-page-application-experience-box-top-left">
                        <div className="application-experience-title">{major}</div>
                        <div className="application-experience-company">{school}</div>
                        <div className="application-experience-date">{formatDate()}</div>
                        <div className="application-experience-date">{degree.string}</div>
                    </div>
                    <div className="apply-page-application-experience-box-top-right">
                    <div className="apply-page-application-experience-dropdown">
                        <div className="template-dropdown" tabIndex={0} onBlur={() => {setDropdown(false)}}>
                            <div className={dropdown ? "template-dropdown-menu selected-dropdown" : "template-dropdown-menu"}>
                                    <div className="experience-dropdown-item" onClick={() => {editClick()}}>Edit</div>
                                    <div className="experience-dropdown-item" onClick={() => {deleteClick()}}>Delete</div>
                                </div>
                                <div className="templates-page-more-icon-wrapper" onClick={() => {setDropdown(true)}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="templates-page-more-icon">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                                    </svg>
                                </div>
                            </div>                          
                        </div>
                    </div>
                </div>
                <div className="application-experience-desc">{gpa.trim().replace(/\n$/, "")}</div>
            </div>}
        </div>
    ) 
}