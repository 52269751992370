import { useContext } from 'react'
import { FormContext } from "./Context/FormContext";

export default function FormSecond(){
    const {jobTitle, setJobTitle, jobDesc, setJobDesc, jobId} = useContext(FormContext)

    return(
        <div className="second-form-container">            
            <input 
                className={jobId ? "second-form-job-title disabled"  : "second-form-job-title" }
                placeholder={'Job Title'} 
                value={jobTitle} 
                onChange={ev => setJobTitle(ev.target.value)}
                readOnly={jobId}/>
            <textarea
                name="" 
                className={jobId ? "job-desc disabled" : "job-desc"}
                cols="30" 
                rows="10" 
                value={jobDesc} 
                placeholder={'Job Description'} 
                onChange={ev => setJobDesc(ev.target.value)}
                readOnly={jobId}>
            </textarea>

        </div>
    ) 
}