import {useState, useEffect} from 'react'

export default function ScoreBar({percentage, circleWidth, radius, fontSize, strokeWidth}){
    // const circleWidth = 140
    // const radius = 60
    const dashArray = radius * Math.PI * 2
    const [dashOffset, setDashOffset] = useState(0)
    const [progressValue, setProgressValue] = useState(0)
    // var dashOffset = dashArray - (dashArray * progressValue) / 100
    useEffect(() => {
        if (progressValue < percentage){
            setTimeout(() => {
                setProgressValue(progressValue + 1)
                setDashOffset(dashArray - (dashArray * progressValue) / 100)
            }, 5)
        }
        if (progressValue > percentage){
            setTimeout(() => {
                setProgressValue(progressValue - 1)
                setDashOffset(dashArray - (dashArray * progressValue) / 100)
            }, 5)
        }

    }, [progressValue, percentage])

    return(
        <div 
            className='score-container'
            // style={`width: ${radius * 3}px; height: ${radius * 3}px`}
        >
            <svg
                className='svg-circle'
                width={circleWidth}
                height={circleWidth}
                viewBox={`0 0 ${circleWidth} ${circleWidth}`}
            >
                <circle
                    cx={circleWidth / 2}
                    cy={circleWidth / 2}
                    strokeWidth={strokeWidth}
                    r={radius}
                    className="circle-background"
                />

                <circle
                    cx={circleWidth / 2}
                    cy={circleWidth / 2}
                    strokeWidth={strokeWidth}
                    r={radius}
                    className="circle-progress"
                    style={{
                        strokeDasharray: dashArray,
                        strokeDashoffset: dashOffset,
                    }}
                    transform={`rotate(-90 ${circleWidth / 2} ${circleWidth / 2})`}
                />
                <text x="50%" y="50%" dy="0.3em" textAnchor='middle' fontSize={`${fontSize}px`} className='circle-text'>{progressValue}</text>
            </svg>
        </div>
    )

}