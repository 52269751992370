import { useContext } from 'react'
import { FormContext } from "./Context/FormContext"
import HardSkills from "./HardSkills"
import SoftSkills from "./SoftSkills"
import FormatAndStyle from "./FormatAndStyle"
import Credentials from "./Credentials"

export default function SectionDetails({results, section, jobTitle}){
    // const {section} = useContext(FormContext)

    const sectionDisplay = () => {
        if (section === "Hard Skills"){
            return <HardSkills results={results}/>
        } 
        else if (section === "Soft Skills"){
            return <SoftSkills results={results}/>
        }        
        else if (section === "Credentials"){
            return <Credentials results={results} jobTitle={jobTitle}/>
        } 
        else if (section === "Format & Style"){
            return <FormatAndStyle results={results}/>
        } else {
            return <div className='no-section-detail'>Select one of the sections to see details</div>
        }
    }

    return(
        <div>{sectionDisplay()}</div>
    )
}