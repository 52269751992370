import { useContext } from "react";
import {UserContext} from "./Context/UserContext";
import "./Offer.css"
import Modal from './modals/Modal'
import Error from './modals/Error'
import Success from './modals/Success'
import {useState} from "react";

export default function Offer({_id, title, short_desc, points, immediate, link, logo, background, notes, steps, getPoints, fetchOffer, processing}){
    // const { setDialog } = useContext(DialogContext)
    const [offerOpen, setOfferOpen] = useState(false)
    const [errorMsg, setErrorMsg] = useState(false)
    const [successMsg, setSuccessMsg] = useState(false)
    const { status, setStatus, userInfo, setUserInfo} = useContext(UserContext);

    async function goToOffer() {

        // if there is a link open it
        link && window.open(link, '_blank')
        setOfferOpen(false)
        const response = await fetch(process.env.REACT_APP_API_URL + '/earn', {
            method: 'POST',
            body: JSON.stringify({ "offer_id": _id }),
            headers: {'Content-Type': 'application/json'},
            credentials: 'include' // cookies will be considered credentials
        });
        if (response.ok) {
            //json is async
            const message = await response.json()
            if (message.status === "Confirmed"){
                setSuccessMsg({title: "Success", desc: `You earned ${message.change} points.`})
                getPoints()
            }
        } else {
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                setErrorMsg({title: "Oops", desc: message})
            }
        }
    }

    return (
        <>
            {/* reward details modal */}
            <Success successMsg={successMsg} setSuccessMsg={setSuccessMsg} customClick={fetchOffer}/>
            <Error errorMsg={errorMsg} setErrorMsg={setErrorMsg} />
            <Modal open={offerOpen} onClose={() => setOfferOpen(false)}>
                <div className="offer-modal-inner-wrapper">
                    <div className="offer-modal-top" style={{background: background}}>
                        <div className="left">
                        <img src={`${process.env.REACT_APP_API_URL}${logo}`} alt="Logo" className="offer-logo-modal"/>
                            <div className="main">
                                <div className="title">{title}</div>
                                <div className="short-desc">{short_desc}</div>
                            </div>
                        </div>
                        <div className="right">
                            <div className="points-number-wrapper">
                                <svg width="40" height="66" viewBox="0 0 40 66" fill="none" xmlns="http://www.w3.org/2000/svg" className="offer-modal-star">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M20.0143 0.0212326C20.3893 13.4342 28.1322 25.3282 39.9998 33C28.1321 40.6718 20.3891 52.5658 20.0141 65.9788L19.9977 66L19.9859 65.9847C19.613 52.5693 11.8695 40.6729 0 33H0.000399714C11.8698 25.3271 19.6132 13.4306 19.9861 0.0152588L19.9979 0L20.0143 0.0212326Z" fill="white"/>
                                </svg> {points}
                            </div>
                            <div className="points-text">Points</div>                        
                        </div>
                    </div>
                    <div className="offer-modal-bottom">
                        <div className="steps">
                            {steps.length > 0 && steps.map((step, index) => (
                                <>  
                                    <div className="step">
                                        <div className="number">{index + 1}</div>
                                        <div>{step}</div>
                                        <div className="new-line"></div>
                                    </div>
                                    {index !== steps.length - 1 && 
                                        <div className="step-connect">
                                            <div className="line-wrapper">
                                                <div className="line"></div>
                                            </div>
                                        </div>
                                    }
                                </>
                            ))}
                        </div>
                        { notes && <div className="notes">{notes}</div>}
                        <div className="button" style={{background: background}} onClick={() => goToOffer()}>Earn 
                            <svg width="40" height="66" viewBox="0 0 40 66" fill="none" xmlns="http://www.w3.org/2000/svg" className="offer-modal-star-button">
                                <path fillRule="evenodd" clipRule="evenodd" d="M20.0143 0.0212326C20.3893 13.4342 28.1322 25.3282 39.9998 33C28.1321 40.6718 20.3891 52.5658 20.0141 65.9788L19.9977 66L19.9859 65.9847C19.613 52.5693 11.8695 40.6729 0 33H0.000399714C11.8698 25.3271 19.6132 13.4306 19.9861 0.0152588L19.9979 0L20.0143 0.0212326Z" fill="white"/>
                            </svg> {points}                        
                        </div>
                        <button className="cancel" onClick={() => setOfferOpen(false)}>Cancel</button>
                    </div>
                </div>
            </Modal>
            <div className="offer" onClick={() => link ? setOfferOpen(true) : goToOffer()}>

                <div className="offer-image" style={{background: background}}>
                    <img src={`${process.env.REACT_APP_API_URL}${logo}`} alt="Logo" className="offer-logo"/>
                </div>
                <div className="offer-texts">
                    <div className="offer-top">
                        <div className="offer-title">{title}</div>
                        <div className="offer-desc">{short_desc}</div>
                    </div>
                    <div className="offer-bottom">
                        <div className="offer-bottom-wrapper">
                            <div className="offer-bottom-title">Reward</div>
                            <div className="offer-bottom-value">
                                <svg className="offer-bottom-star" width="60" height="66" viewBox="0 0 60 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M20.0143 0.0212326C20.3893 13.4342 28.1322 25.3282 39.9998 33C28.1321 40.6718 20.3891 52.5658 20.0141 65.9788L19.9977 66L19.9859 65.9847C19.613 52.5693 11.8695 40.6729 0 33H0.000399714C11.8698 25.3271 19.6132 13.4306 19.9861 0.0152588L19.9979 0L20.0143 0.0212326Z" fill="currentcolor"/>
                                </svg>{points}
                            </div>
                        </div>
                        <div className="offer-bottom-wrapper">
                            <div className="offer-bottom-title">Processing</div>
                            <div className="offer-bottom-value">{immediate ? "Instant" : processing}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}