import { useState } from "react";
// import { DialogContext } from "./Context/DialogContext";
import ConfirmImg from '../images/confirm.svg';
import Modal from './Modal'
import './Modal.css'
import DelayButton from '../Button/DelayButton'
import Select from 'react-select'
import 'react-datepicker/dist/react-datepicker.css';
import SelectStyle from '../SelectStyle'

export default function JobInput({inputMsg, setInputMsg, customClick, customClose, buttonText, loading, setLoading,
    newTitle, setNewTitle, newCompany, setNewCompany, newLocation, setNewLocation, newStatus, setNewStatus, newNotes, 
    setNewNotes, newLink, setNewLink, newJobDescription, setNewJobDescription, errorMsg, setErrorMsg, setConfirmMsg, isCreate = false}){
    
    const [titleError, setTitleError] = useState("")
    const [showTitleError, setShowTitleError] = useState("")

    const [companyError, setCompanyError] = useState("")
    const [showCompanyError, setShowCompanyError] = useState("")

    const [locationError, setLocationError] = useState("")
    const [showLocationError, setShowLocationError] = useState("")

    const [linkError, setLinkError] = useState("")
    const [showLinkError, setShowLinkError] = useState("")

    const [descError, setDescError] = useState("")
    const [showDescError, setShowDescError] = useState("")

    const [notesError, setNotesError] = useState("")
    const [showNotesError, setShowNotesError] = useState("")

    const jobTitleLimit = 200
    const jobLocationLimit = 200
    const jobCompanyLimit = 200
    const jobLinkLimit = 5000
    const jobDescLimit = 10000
    const jobNotesLimit = 10000

    const statusOptions = [
        { value: 'saved', label: 'Saved' },
        { value: 'applied', label: 'Applied' },
        { value: 'interviewing', label: 'Interviewing' },
        { value: 'offer', label: 'Offer' },
        { value: 'rejected', label: 'Rejected' }
    ]

    function handleClose() {
        if (!loading){
            customClose && customClose()
            setInputMsg("")
        }

        // setShowBottomError(false)
        setShowTitleError(false)
        setShowCompanyError(false)
        setShowLocationError(false)
        setShowLinkError(false)
        setShowDescError(false)
        setShowNotesError(false)

    }

    function handleDelete(){
        if (!loading){
            setInputMsg("")

            // setShowBottomError(false)
            setShowTitleError(false)
            setShowCompanyError(false)
            setShowLocationError(false)
            setShowLinkError(false)
            setShowDescError(false)
            setShowNotesError(false)

            setConfirmMsg({title: "Reminder", desc: "This action cannot be reversed, are you sure?"})
        }
    }

    function CheckTitleError(value){
        if ( !isNotAllSpaces(value) ){
            return "Title cannot be empty."
        }

        if ( value.length > jobTitleLimit){
            return `Input exceeds maximum character limit of ${jobTitleLimit}.`
        }

        return ""
    }

    function CheckCompanyError(value){
        if ( !isNotAllSpaces(value) ){
            return "Company cannot be empty."
        }

        if ( value.length > jobCompanyLimit){
            return `Input exceeds maximum character limit of ${jobCompanyLimit}.`
        }

        return ""
    }

    function CheckLocationError(value){

        if ( value && value.length > jobLocationLimit){
            return `Input exceeds maximum character limit of ${jobLocationLimit}.`
        }

        return ""
    }

    function CheckLinkError(value){

        if ( value && value.length > jobLinkLimit){
            return `Input exceeds maximum character limit of ${jobLinkLimit}.`
        }

        if ( value && !isValidURL(value)){
            return "Invalid link."
        }

        return ""
    }

    function isValidURL(str) {
        const pattern = /^(https?:\/\/)?([a-zA-Z0-9.-]+)(\.[a-zA-Z]{2,})(\/[^\s]*)?$/;
        return pattern.test(str);
    }

    function CheckDescError(value){

        if ( isCreate && !isNotAllSpaces(value) ){
            return "Description cannot be empty."
        }

        if ( value && value.length > jobDescLimit){
            return `Input exceeds maximum character limit of ${jobDescLimit}.`
        }

        return ""
    }

    function CheckNotesError(value){

        if ( value && value.length > jobNotesLimit){
            return `Input exceeds maximum character limit of ${jobNotesLimit}.`
        }

        return ""
    }

    function handleClick(){
        setLoading(true)

        setShowTitleError(false)
        setShowCompanyError(false)
        setShowLocationError(false)
        setShowLinkError(false)
        setShowDescError(false)
        setShowNotesError(false)

        // setShowBottomError(false)

        const titleErrMsg = CheckTitleError(newTitle)
        const companyErrMsg = CheckCompanyError(newCompany)
        const locationErrMsg = CheckLocationError(newLocation)
        const linkErrMsg = CheckLinkError(newLink)
        const descErrMsg = CheckDescError(newJobDescription)
        const notesErrMsg = CheckNotesError(newNotes)

        if (titleErrMsg || companyErrMsg || locationErrMsg || linkErrMsg || linkErrMsg || descErrMsg || notesErrMsg){
            
            setErrorMsg("Please fix the highlighted errors.")

            if (titleErrMsg){
                setTitleError(titleErrMsg)
                setShowTitleError(true)
            }

            if (companyErrMsg){
                setCompanyError(companyErrMsg)
                setShowCompanyError(true)
            }

            if (locationErrMsg){
                setLocationError(locationErrMsg)
                setShowLocationError(true)
            }

            if (linkErrMsg){
                setLinkError(linkErrMsg)
                setShowLinkError(true)
            }

            if (descErrMsg){
                setDescError(descErrMsg)
                setShowDescError(true)
            }

            if (notesErrMsg){
                setNotesError(notesErrMsg)
                setShowNotesError(true)
            }

            const timeout = setTimeout(() => {
                setLoading(false)
                // customClick && customClick()
            }, 400);
        
            return () => {
                clearTimeout(timeout);
            };
        }

        customClick && customClick()
        // setValue("")
        // setInputMsg(null)        
    }

    const handleStatusChange = (selectedOption) => {
        setNewStatus(selectedOption)
    }

    function isNotAllSpaces(inputString) {
        // Use a regular expression to check if the string contains at least one non-space character
        return /\S/.test(inputString);
    }

    function handleTitleText(value){
        setShowTitleError(false)
        setNewTitle(value)
    }

    function handleCompanyText(value){
        setShowCompanyError(false)
        setNewCompany(value)
    }

    function handleLocationText(value){
        setShowLocationError(false)
        setNewLocation(value)
    }

    function handleLinkText(value){
        setShowLinkError(false)
        setNewLink(value)
    }

    function handleDescText(value){
        setShowDescError(false)
        setNewJobDescription(value)
    }

    function handleNotesText(value){
        setShowNotesError(false)
        setNewNotes(value)
    }

    return (
        <Modal open={inputMsg} onClose={() => handleClose()}>
            {inputMsg &&
                <div className="input-modal-inner-wrapper">
                    <div className="input-modal-title">{inputMsg.title}
                        <div className="input-modal-desc">Required fields are marked with <span className='required'>*</span></div>
                    </div>
                    
                    <div className="input-modal-main-area">
                        {inputMsg.desc && <div className="input-modal-text">{inputMsg.desc}</div>}
                        <div className="input-modal-label">Job status <span className='required'>*</span></div>
                        <Select options={statusOptions} 
                            // menuIsOpen={true}
                            value={newStatus}
                            onChange={handleStatusChange}
                            styles={SelectStyle({error: false})}
                        />
                        <div className="input-modal-spacing"></div>
                        <div className="input-modal-section">
                            <div className="input-modal-label">Job title <span className='required'>*</span></div>
                            <input 
                                className={showTitleError ? "input-modal-input input-modal-input-box-error" : "input-modal-input"}
                                type="text" 
                                placeholder={"Job title"}
                                value={newTitle} 
                                onChange={ev => handleTitleText(ev.target.value)}/>
                            <div className={ showTitleError ? "input-modal-error input-modal-error-expanded" : "input-modal-error"}>{titleError}</div>
                        </div>
                        <div className="input-modal-section">
                            <div className="input-modal-label">Company <span className='required'>*</span></div>
                            <input 
                                className={showCompanyError ? "input-modal-input input-modal-input-box-error" : "input-modal-input"}
                                type="text" 
                                placeholder={"Company"}
                                value={newCompany} 
                                onChange={ev => handleCompanyText(ev.target.value)}/>
                            <div className={ showCompanyError ? "input-modal-error input-modal-error-expanded" : "input-modal-error"}>{companyError}</div>
                        </div>
                        <div className="input-modal-section">
                            <div className="input-modal-label">Location</div>
                            <input 
                                className={showLocationError ? "input-modal-input input-modal-input-box-error" : "input-modal-input"}
                                type="text"
                                placeholder={"Location"}
                                value={newLocation} 
                                onChange={ev => handleLocationText(ev.target.value)}/>
                            <div className={ showLocationError ? "input-modal-error input-modal-error-expanded" : "input-modal-error"}>{locationError}</div>
                        </div>
                        <div className="input-modal-section">
                            <div className="input-modal-label">Job post link</div>
                            <input 
                                className={showLinkError ? "input-modal-input input-modal-input-box-error" : "input-modal-input"}
                                type="text" 
                                placeholder={"https://company.com/job_post"}
                                value={newLink} 
                                onChange={ev => handleLinkText(ev.target.value)}/>
                            <div className={ showLinkError ? "input-modal-error input-modal-error-expanded" : "input-modal-error"}>{linkError}</div>
                        </div>
                        {isCreate && 
                        <>
                            <div className="input-modal-section">
                                <div className="input-modal-label">Job description <span className='required'>*</span></div>
                                <textarea 
                                    className={showDescError ? "input-modal-input input-modal-input-box-error" : "input-modal-input"}
                                    rows="5" 
                                    placeholder={"Job description"}
                                    value={newJobDescription} 
                                    onChange={ev => handleDescText(ev.target.value)}>
                                </textarea>
                                <div className={ showDescError ? "input-modal-error input-modal-error-expanded" : "input-modal-error"}>{descError}</div>
                            </div>
                        </>}
                        <div className="input-modal-section">
                            <div className="input-modal-label">Notes</div>
                            <textarea 
                                className={showNotesError ? "input-modal-input input-modal-input-box-error" : "input-modal-input"}
                                rows="5" 
                                placeholder={"Notes"}
                                value={newNotes} 
                                onChange={ev => handleNotesText(ev.target.value)}>
                            </textarea>
                            <div className={ showNotesError ? "input-modal-error input-modal-error-expanded" : "input-modal-error"}>{notesError}</div>
                        </div>
                        {!isCreate && <div className={loading ? "delete-event-button delete-event-button-disabled" : "delete-event-button"} disabled={loading} onClick={() => handleDelete()}>Delete job</div>}
                    </div>
                    <div className={(loading || !errorMsg) ? "input-error-msg input-error-msg-hidden" : "input-error-msg"}>{errorMsg}</div>
                    <div className="input-modal-button-container">
                        <button className={loading ? "input-modal-cancel input-modal-cancel-disabled" : "input-modal-cancel"} onClick={() => handleClose()}>Cancel</button>
                        {/* <button className="input-modal-button" onClick={() => handleClick()}>{buttonText}</button> */}
                        <DelayButton buttonClassName="input-modal-button" buttonClassNameLoading="input-modal-button-loading" isLoading={loading} onClick={() => handleClick()}>{buttonText}</DelayButton>
                        {/* <DelayButton buttonClassName="input-modal-button" buttonClassNameLoading="input-modal-button-loading" isLoading={false} onClick={() => handleClick()}>{buttonText}</DelayButton> */}
                    </div>
                </div>
            }
        </Modal>
    )
}