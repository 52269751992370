import Modal from './Modal'
import './Modal.css'
import { Link } from "react-router-dom"

export default function JobDesc({inputMsg, setInputMsg}){

    function handleClose() {
        setInputMsg(null)
    }

    function handleClick(){
        setInputMsg(null)
    }

    return (
        <Modal open={inputMsg} onClose={() => handleClose()}>
            {inputMsg &&
                <div className="input-modal-inner-wrapper">
                    <div className="input-modal-title">
                        <>{inputMsg.jobTitle}</>
                        {inputMsg.jobStatus !== "deleted" && <div> <Link className='jobdesc-modal-details' to={`/jobs?jobId=${inputMsg.jobId}`}>View job</Link></div>}
                    </div>
                    
                    <div className="input-modal-main-area">
                        <div className='jobdesc-modal-text'>{inputMsg.jobDesc}</div>
                    </div>
                    <div className="input-modal-button-container">
                        <button className="input-modal-button" onClick={() => handleClick()}>Done</button>
                    </div>
                </div>
            }
        </Modal>
    )
}