import { useContext, useState, useRef } from "react"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {ApplicationContext} from "./Context/ApplicationContext"
import DatePicker from 'react-datepicker'
import LocationDropdown from "./LocationDropdownOneLine.js"
import Select from 'react-select'
import SelectStyleGeneric from './SelectStyleGeneric'
import Checkbox from './Checkbox'
import DragAndDrop from "./DragAndDrop.js"

export default function ApplicationQuestion({type, state, question, options, validation, half, answer, setAnswer, maxAnswers}){
    const {color} = useContext(ApplicationContext)
    // const [answer, setAnswer] = useState("")
    const handleChange = (e) => {
        setAnswer(e.target.value)
    }

    const handleCheck = (selected, value) => {
        const newSet = new Set(answer)
        if (selected){
            if (maxAnswers === 1){
                const oneSelectionSet = new Set([value])
                setAnswer(oneSelectionSet)
                return
            } 
            newSet.add(value)
        } else {
            newSet.delete(value)
        }
        setAnswer(newSet)
    }

    // Convert array into object
    const optionsObject = options && options.map(value => ({ value: value, label: value }));
    
    return(
        <div className={half ? (`application-question application-question-${half}`) : "application-question"}>
            <div className="application-question-title">
                {question + " "}
                {state == "Required" ? <span className='required'>*</span>
                : <span className='application-question-optional'>{"(Optional)"}</span>}
            </div>
            {type === "text" && (validation === "none" || !validation) && <input className='input-modal-input blue-border' onChange={handleChange} value={answer}></input>}
            {type === "text" && validation === "phone" && <PhoneInput
                country={'us'}
                value={answer}
                onChange={setAnswer}
                containerClass="application-question-phone-container"
                inputClass="application-question-phone-input"
                dropdownClass="application-question-phone-dropdown"
                buttonClass="application-question-phone-button"
            />}
            {type === "text" && validation === "date" && <DatePicker
                dateFormat="MM/dd/yyyy"
                selected={answer}
                onChange={setAnswer}
            />}
            {type === "text" && validation === "location" && <LocationDropdown 
                state={"Required"} 
                selectedLocation={answer} 
                setSelectedLocation={setAnswer}/>}
            {type === "text" && (validation === "number" || validation === "positive_num") && <input 
                className='input-modal-input blue-border' 
                onChange={handleChange} 
                value={answer}
                type="number">
            </input>}
            {type === "textarea" && <textarea 
                className="input-modal-input blue-border"
                rows="5" 
                value={answer} 
                onChange={handleChange}>
            </textarea>}
            {type === "dropdown" && <Select options={optionsObject} 
                // menuIsOpen={true}
                // placeholder=""
                value={answer}
                onChange={setAnswer}
                styles={SelectStyleGeneric({error: false})}
                maxMenuHeight="150px"
            />}
            {type === "checkbox" && options.map((option) => {
                return <div className="application-question-checkbox">
                        <Checkbox checked={answer && answer.has(option)} 
                            setChecked={(selected) => handleCheck(selected, option)} 
                            text={option}
                            disabled={answer && maxAnswers > 1 && maxAnswers === answer.size && !answer.has(option)}/>
                    </div>
            })} 
            {type === "upload" && <DragAndDrop files={answer} setFiles={setAnswer} types={["pdf", "doc", "docx"]} dndStyle={"drag-style"} dndOnly={true}/>}    
        </div>
    ) 
}