import { useContext, useState, useEffect} from 'react'
import { EditScanContext } from "./Context/EditScanContext"

export default function SectionButton({title, score}){
    const {section, setSection} = useContext(EditScanContext)
    const [progressValue, setProgressValue] = useState(0)
    const [desc, setDesc] = useState("")

    useEffect(() => {
        if (progressValue < score){
            setTimeout(() => {
                setProgressValue(progressValue + 1)
            }, 3)
        } else if (progressValue > score){
            setTimeout(() => {
                setProgressValue(progressValue - 1)
            }, 3)
        }
    }, [progressValue, score])

    useEffect(() => {
        if (score === 100){
            setDesc("Perfect score!")
        } else if (score >= 70){
            setDesc("Looking good!")
        } else if (score >= 50){
            setDesc("Almost there!")
        } else {
            setDesc("It's a start!")
        }
    }, [score])

    return (
        <button className={ title === section? "section-button-small-selected" : "section-button-small" }  onClick={() => { title === section ? setSection("") : setSection(title)}}>
            <div className="section-small-score-circle">
                <div className="section-small-score">{score}</div>
            </div>
            <div className="section-small-middle">
                <div className="section-small-text-title">{title}</div>
                <div className="section-small-text-desc">{desc}</div>
                <div className="small-score-bar">
                    <div style={{ width: `${progressValue}%` }}></div>
                </div>
            </div>
        </button>
    )
}