import { useState, createContext } from "react";

export const LeftBarContext = createContext({});

export function LeftBarContextProvider({children}) {
    const [expand, setExpand] = useState(false)
    const [lock, setLock] = useState(false)
    const [selected, setSelected] = useState("dashboard")

    return (
        <LeftBarContext.Provider value={{expand, setExpand, lock, setLock, selected, setSelected}}>
            {children}
        </LeftBarContext.Provider>
    )
}
