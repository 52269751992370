import { useContext } from 'react'
import { FormContext } from "./Context/FormContext";

export default function FormSecond(){
    const jobDesc = 
`Our company seeks extraordinary students to join us for software engineering roles. As a software intern, you will work closely with your mentor and other employees who will help you apply your knowledge and grow your skills on projects that have a significant impact. You’ll also get to have some fun and network with other interns and employees through optional social and professional events. If you’ve demonstrated a commitment to academic success and motivation to apply your knowledge outside of the classroom, you are a great candidate! Aerospace experience is not required to be successful here - rather we look for smart, motivated, collaborative engineers who love solving problems and want to make an impact on a super inspiring mission.

BASIC QUALIFICATIONS:

• Must be enrolled in a bachelor’s degree or graduate program
• 3+ months of software programming or development experience
• Software coding experience in one or more of the following: C, C++, C#, Java, JavaScript, Python
`

    return(
        <div className="second-form-container">            
            <input className="second-form-job-title" 
                value={'Software Engineering Intern'}
                readOnly
            />
            <textarea
                name="" 
                className="job-desc" 
                cols="30" 
                rows="10" 
                value={jobDesc}
                readOnly>
            </textarea>

        </div>
    ) 
}