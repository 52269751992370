import "./JobPage.css"
import Job from "../Job"
import Event from "../Event"
import {useEffect, useState, useContext, useRef} from "react";
import { Navigate, Link, useLocation, useNavigate} from "react-router-dom";
import {UserContext} from "../Context/UserContext";
import {LeftBarContext} from "../Context/LeftBarContext"
import { v4 as uuidv4 } from 'uuid';
import Loading from "../Loading"
import SkillglanceIcon from '../images/skillglance_icon_grey.svg'
import Error from '../modals/Error'
import EventInput from '../modals/EventInput'
import JobInput from '../modals/JobInput'
import LoadConfirm from '../modals/LoadConfirm'

export default function JobPage() {
    const [jobs,setJobs] = useState([]);
    const [filteredJobs,setFilteredJobs] = useState([]);
    const { status, setStatus, userInfo, setUserInfo} = useContext(UserContext); //re-renders when logout
    const {setLock, setSelected} = useContext(LeftBarContext)
    const [errorMsg, setErrorMsg] = useState(false)
    const [redirect, setRedirect] = useState("")

    const [jobLoading, setJobLoading] = useState(false)
    const [selectedStatus, setSelectedStatus] = useState("all")
    const [selectedJobId, setSelectedJobId] = useState(null)
    const [selectedJob, setSelectedJob] = useState(null)
    const [logoUrl, setLogoUrl] = useState(SkillglanceIcon)

    const [search, setSearch] = useState("")
    const [expand, setExpand] = useState(false)

    const [createEventLoading, setCreateEventLoading] = useState(false)
    const [createEventInputMsg, setCreateEventInputMsg] = useState(false)
    const [createEventInputErrMsg, setCreateEventInputErrMsg] = useState(false)
    // const [showCreateEventInputErrMsg, setShowCreateEventInputErrMsg] = useState(false)
    //values to be edited
    const [newTitle, setNewTitle] = useState("")
    const [newCompany, setNewCompany] = useState("")
    const [newLocation, setNewLocation] = useState("")
    const [newStatus, setNewStatus] = useState("")
    const [newNotes, setNewNotes] = useState("")
    const [newLink, setNewLink] = useState("")
    //only for new jobs
    const [newJobDescription, setNewJobDescription] = useState("")
    const eventRequestId = useRef(null)

    //for edit job
    const [editJobLoading, setEditJobLoading] = useState(false)
    const [editJobInputMsg, setEditJobInputMsg] = useState(false)
    const [editJobInputErrMsg, setEditJobInputErrMsg] = useState(false)
    // const [showEditJobInputErrMsg, setShowEditJobInputErrMsg] = useState(false)
    const [deleteJobConfirmMsg, setDeleteJobConfirmMsg] = useState(false)
    const [deleteJobLoading, setDeleteJobLoading] = useState(false)
    //values to be edited
    const [newDate, setNewDate] = useState("")
    const [newTime, setNewTime] = useState("")
    const [newType, setNewType] = useState("")
    const [newDescription, setNewDescription] = useState("")
    const [newCompleted, setNewCompleted] = useState(null)


    const [createJobInputMsg, setCreateJobInputMsg] = useState(false)
    const [createJobLoading, setCreateJobLoading] = useState(false)
    const [createJobInputErrMsg, setCreateJobInputErrMsg] = useState(false)
    // const [showCreateJobInputErrMsg, setShowCreateJobInputErrMsg] = useState(false)
    const jobRequestId = useRef(null)

    // Use the useLocation hook to get the location object
    const location = useLocation();
    const navigate = useNavigate();

    // Parse the query parameters from the search string
    const searchParams = new URLSearchParams(location.search);
    
    // Access the jobId query parameter
    const jobId = searchParams.get('jobId');

    //if has parameter, expand the job page
    useEffect(() => {
        if (jobId){
            setExpand(true)
        }
    },[])

    useEffect(() => {
        //setSelectedJobId is ONLY called when jobId changes
        setSelectedJobId(jobId)
    },[jobId])

    useEffect(() => {
        setLock(false)
        setSelected("jobs")
        // fetch and json is async
        tempHistory()
        fetchJobs()
    }, []);

    useEffect(() => {
        if (selectedJobId){
            fetchJob()
        } else {
            setSelectedJob(null)
            fetchJobs()
        }
    }, [selectedJobId])

    useEffect(() => {
        //make sure skeloton not mistaken as real job
        if (filteredJobs.length >= 1 && jobs[0].title && !selectedJobId ){
            // setSelectedJobId(filteredJobs[0]._id)
            navigate(`/jobs?jobId=${filteredJobs[0]._id}`)
        } 
    }, [filteredJobs])

    useEffect(() => {
        //make sure skeloton not mistaken as real job
        fiterJobs(selectedStatus)
    }, [jobs, search])

    const fetchJobs = async () =>{
        // setJobLoading(true)
        tempHistory()
        const response = await fetch(process.env.REACT_APP_API_URL + '/jobs', {credentials: 'include',})
        if (response.ok) {
            response.json().then(jobs => {
                setJobs(jobs)
                // setFilteredJobs(jobs)
            })
        } else {
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            }
        }
    }

    const fetchJob = async () =>{
        setJobLoading(true)
        const response = await fetch(process.env.REACT_APP_API_URL + '/job/' + selectedJobId, {credentials: 'include',})
        if (response.ok) {
            response.json().then(job => {

                if (selectedJobId === job._id){
                    if (!job.logo){
                        setLogoUrl(SkillglanceIcon)
                    } else {
                        fetch(job.logo).then( async response => {
                            if (response.ok) {
                                const blob = await response.blob();
                                const objectUrl = URL.createObjectURL(blob);
                                setLogoUrl(objectUrl);
                            }else{
                                setLogoUrl(SkillglanceIcon)
                            }
                        })
                    }
                    setSelectedJob(job)
                    setJobLoading(false)
                } else {
                    console.log("expired request")
                }
            })
        } else {
            const message = await response.json()
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                setErrorMsg({title: "Oops", desc: message})
                setJobLoading(false)
            }
        }
    }

    //improve this 
    const changeStatus = async (newStatus) => {
        const response = await fetch(process.env.REACT_APP_API_URL + '/job-status', {
            method: 'PUT',
            body: JSON.stringify({jobId: selectedJob._id, newStatus: newStatus}),
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })
        if (response.ok) {
            response.json().then(job => {

                if (selectedJob._id === job.updatedJob._id){
                    setSelectedJob(job.updatedJob)

                    const updatedArray = [...jobs];
                    const indexToReplace = updatedArray.findIndex(item => item._id === selectedJob._id);
                    if (indexToReplace !== -1) {
                        // Replace the item with the new item
                        updatedArray[indexToReplace].status = newStatus
                        updatedArray[indexToReplace].updatedAt = job.updatedJob.updatedAt
                        updatedArray.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
                        setJobs(updatedArray)
                    }
                } else {
                    console.log("expired request")
                }
            })
        } else {
            const message = await response.json()
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                setErrorMsg({title: "Oops", desc: message})
            }
        }
    }
    
    async function handleEventDelete(_id, setDeleteLoading, setConfirmMsg, setErrorMsg) {
        setDeleteLoading(true)
        const response = await fetch(process.env.REACT_APP_API_URL + '/delete-event', {
            method: 'POST',
            body: JSON.stringify({ "eventId": _id }),
            headers: {'Content-Type': 'application/json'},
            credentials: 'include' // cookies will be considered credentials
        });
        if (response.ok) {
            const message = await response.json();
            if (message.eventId === _id){
                setDeleteLoading(false)
                setConfirmMsg(null)
            }

            //not required to check because user cannot move when loading
            if (message.jobDoc._id === selectedJob._id){
                const updatedArray = [...selectedJob.events];
                const indexToDelete = updatedArray.findIndex(item => item._id === _id);
                if (indexToDelete !== -1) {
                    // Replace the item with the new item
                    updatedArray.splice(indexToDelete, 1)
                    const copyOfSelectedJob = { ...selectedJob }
                    copyOfSelectedJob.events = updatedArray
                    copyOfSelectedJob.updatedAt = message.jobDoc.updatedAt
                    setSelectedJob(copyOfSelectedJob)
                }

                const copyOfJobs = [...jobs]
                const jobIndexToReplace = copyOfJobs.findIndex(item => item._id === message.jobDoc._id);
                if (jobIndexToReplace !== -1) {
                    // Replace the item with the new item
                    copyOfJobs[jobIndexToReplace].updatedAt = message.jobDoc.updatedAt
                    copyOfJobs.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
                    setJobs(copyOfJobs)
                }
            }
        } else {
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                setErrorMsg({title: "Oops", desc: message})
                setDeleteLoading(false)
                setConfirmMsg(null)
            }
        }
    }

    async function handleJobDelete(){
        setDeleteJobLoading(true)
        // setJobLoading(true)
        const response = await fetch(process.env.REACT_APP_API_URL + '/delete-job', {
            method: 'POST',
            body: JSON.stringify({ "jobId": selectedJob._id }),
            headers: {'Content-Type': 'application/json'},
            credentials: 'include' // cookies will be considered credentials
        });
        if (response.ok) {
            const message = await response.json()
            // const updatedArray = [...jobs];
            // const indexToDelete = updatedArray.findIndex(item => item._id === message._id);
            // if (indexToDelete !== -1) {
            //     updatedArray.splice(indexToDelete, 1)

            //     //sort by newest first
            //     updatedArray.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
            //     setJobs(updatedArray)
            // }

            if (selectedJob._id === message._id){
                // setJobLoading(false) not needed because 
                // setjobs will trigger a chain reaction that fetches a new job
                setDeleteJobLoading(false)
                setDeleteJobConfirmMsg(null)
                setExpand(false)
            }

            // setSelectedJobId(null)
            navigate(`/jobs`)
            // fetchJobs()

        } else {
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                setErrorMsg({title: "Oops", desc: message})
                setDeleteJobLoading(false)
                setDeleteJobConfirmMsg(null)
            }
        }
    }

    async function tempHistory() {
        const newJobs = [];
        for (let i = 0; i < 2; i++) {
            // Not having unique id will cause react to not remove some components
            const skelotonHistory = {_id: uuidv4(), title: null, job: null, results: null, createdAt: null, updatedAt: null};
            newJobs.push(skelotonHistory);
        }
        setJobs(newJobs)
        // console.log(jobs)
    }

    async function fiterJobs(status){
        setSelectedStatus(status)

        //no jobs
        if (jobs.length === 0){
            setFilteredJobs([])
            // setJobLoading(false)
            return
        }
        
        //returned jobs
        if (jobs[0].title){
            var statusFilteredJobs = []

            if (status === "all"){
                statusFilteredJobs = jobs
            } else {
                statusFilteredJobs = jobs.filter(job => job.status === status)
            }
    
            const keywordFilteredJobs = statusFilteredJobs.filter((job) => {
                const titleMatch = job.title.toLowerCase().includes(search.toLowerCase());
                const companyMatch = job.company.toLowerCase().includes(search.toLowerCase());
                return titleMatch || companyMatch
            })
    
            setFilteredJobs(keywordFilteredJobs)
        } else {
            //skeleton only
            setFilteredJobs(jobs)
        }

    }

    function countJobs(status){
        //no jobs
        if (jobs.length === 0){
            return <span className="job-page-job-type-count">&nbsp;{"(0)"}</span>
        }
        
        //returned jobs
        if (jobs[0].title){

            if (status === "all"){
                return <span className="job-page-job-type-count">&nbsp;{`(${jobs.length})`}</span>
            } else {
                return <span className="job-page-job-type-count">&nbsp;{`(${jobs.filter(job => job.status === status).length})`}</span>
            }
    
        } else {
            return null
        }
    }

    function DaysAgo(date) {
        const createdAtDate = new Date(date)
        const currentDate = new Date()

        // Calculate the time difference in milliseconds
        const timeDifference = currentDate - createdAtDate;
      
        // Calculate the number of days
        const daysAgo = Math.max(0, Math.floor(timeDifference / (1000 * 60 * 60 * 24)))

        if (daysAgo === 1){
            return "1 day ago"
        } else if (daysAgo < 100){
            return daysAgo + " days ago"
        } else {
            return "99+ days ago"
        }
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    async function createEvent(){
        setCreateEventLoading(true)
        // setCreateEventInputErrMsg("")

        var year
        var month
        var day
        try{
            const isoDate = new Date(newDate);
            year = isoDate.getFullYear();
            month = isoDate.getMonth(); // Months are 0-based (0 = January, 11 = December)
            day = isoDate.getDate();

        } catch(e){}

        var isoDateTimeString
        if (newDate && newTime){
            isoDateTimeString = "invalid date"
        }
        try{
            const [hours, minutes] = newTime.split(':')
            if (hours >= 0 && hours < 24 && minutes >= 0 && minutes < 60){
                const combinedDate = new Date(year, month, day, hours, minutes, 0);
                isoDateTimeString = combinedDate.toISOString();
            }
        } catch(e){}

        //generate unique request id
        const requestId = uuidv4()
        eventRequestId.current = requestId

        const response = await fetch(process.env.REACT_APP_API_URL + '/event', {
            method: 'POST',
            body: JSON.stringify({jobId: selectedJob._id, date: isoDateTimeString,
                type: newType, description: newDescription, completed: newCompleted, requestId: eventRequestId.current}),
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })
        if (response.ok) {
            const jsonRes = await response.json()

            const eventInfo = jsonRes.eventInfo

            //not required to check
            if (eventInfo.job === selectedJob._id){
                const updatedArray = [...selectedJob.events, eventInfo]
                const copyOfSelectedJob = { ...selectedJob }
                
                //sort by newest first
                updatedArray.sort((a, b) => new Date(b.date) - new Date(a.date));

                copyOfSelectedJob.events = updatedArray
                copyOfSelectedJob.updatedAt = jsonRes.jobDoc.updatedAt
                setSelectedJob(copyOfSelectedJob)

                const copyOfJobs = [...jobs]
                const jobIndexToReplace = copyOfJobs.findIndex(item => item._id === jsonRes.jobDoc._id);
                if (jobIndexToReplace !== -1) {
                    // Replace the item with the new item
                    copyOfJobs[jobIndexToReplace].updatedAt = jsonRes.jobDoc.updatedAt
                    copyOfJobs.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
                    setJobs(copyOfJobs)
                }
            }
            //to deal work accidentally submitting twice etc
            if (eventRequestId.current === jsonRes.requestId){
                // setCreateEventInputErrMsg("")
                setCreateEventLoading(false)
                setCreateEventInputMsg(null)
            } else {
                console.log(`${eventRequestId.current} vs ${jsonRes.requestId} result expired`)
            }
        }else{
            const jsonRes = await response.json()
            const message = jsonRes.message
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                if (eventRequestId.current === jsonRes.requestId){
                    setCreateEventLoading(false)
                    // show error in form not new modal
                    setCreateEventInputErrMsg(message)
                } else {
                    console.log(`${eventRequestId.current} vs ${jsonRes.requestId} result expired`)
                }
            }
        }
    }

    async function createJob(){
        setCreateJobLoading(true)
        // setShowCreateJobInputErrMsg(false)
        // setCreateJobInputErrMsg("")

        //generate unique request id
        const requestId = uuidv4()
        jobRequestId.current = requestId

        const response = await fetch(process.env.REACT_APP_API_URL + '/job', {
            method: 'POST',
            body: JSON.stringify({status: newStatus, 
                title: newTitle, company: newCompany, location: newLocation, 
                link: newLink, notes: newNotes, desc: newJobDescription, requestId: jobRequestId.current}),
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })
        if (response.ok) {
            const jsonRes = await response.json()

            const jobInfo = jsonRes.jobInfo
            const updatedArray = [...jobs, jobInfo]
            
            //sort by newest first
            updatedArray.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

            setJobs(updatedArray)

            //to deal work accidentally submitting twice etc
            if (jobRequestId.current === jsonRes.requestId){
                //only last created job is selected
                // setSelectedJobId(jobInfo._id)

                // setCreateJobInputErrMsg("")
                setCreateJobLoading(false)
                setCreateJobInputMsg(null)
                navigate(`/jobs?jobId=${jobInfo._id}`)
            } else {
                console.log(`${jobRequestId.current} vs ${jsonRes.requestId} result expired`)
            }
        }else{
            const jsonRes = await response.json()
            const message = jsonRes.message
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                if (jobRequestId.current === jsonRes.requestId){
                    setCreateJobLoading(false)
                    // show error in form not new modal
                    setCreateJobInputErrMsg(message)
                    // setShowCreateJobInputErrMsg(true)
                } else {
                    console.log(`${jobRequestId.current} vs ${jsonRes.requestId} result expired`)
                }
            }
        }
    }

    async function handleCreateEvent(){   
        setNewDate("") 
        setNewTime("00:00")
        setNewType({value: "interview", label: "Interview"})
        setNewDescription("")
        setNewCompleted(null)
        setCreateEventInputMsg({title: "Create Event", desc: ""})
        setCreateEventInputErrMsg("")
    }

    async function handleCreateJob(){  
        setNewTitle("") 
        setNewCompany("")
        setNewLocation("")
        setNewStatus({value: "applied", label: "Applied"})
        setNewNotes("")
        setNewLink("")
        setNewJobDescription("")
        setCreateJobInputMsg({title: "Create Job", desc: ""})
        setCreateJobInputErrMsg("")
        // setShowCreateJobInputErrMsg(false)
    }

    async function editJob(){
        // setShowEditJobInputErrMsg(false)
        setEditJobLoading(true)
        setJobLoading(true)
        const response = await fetch(process.env.REACT_APP_API_URL + '/job', {
            method: 'PUT',
            body: JSON.stringify({jobId: selectedJob._id, status: newStatus, 
                title: newTitle, company: newCompany, location: newLocation, 
                link: newLink, notes: newNotes}),
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })
        if (response.ok) {

            const jobInfo = await response.json()
            const updatedArray = [...jobs];
            const indexToReplace = updatedArray.findIndex(item => item._id === jobInfo._id);
            if (indexToReplace !== -1) {

                // Replace the item with the new item
                updatedArray[indexToReplace].title = jobInfo.title
                updatedArray[indexToReplace].company = jobInfo.company
                updatedArray[indexToReplace].logo = jobInfo.logo
                updatedArray[indexToReplace].location = jobInfo.location
                updatedArray[indexToReplace].status = jobInfo.status
                updatedArray[indexToReplace].updatedAt = jobInfo.updatedAt

                //sort by newest first
                updatedArray.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
                setJobs(updatedArray)
            }

            if (selectedJob._id === jobInfo._id){
                if (!jobInfo.logo){
                    setLogoUrl(SkillglanceIcon)
                } else {
                    fetch(jobInfo.logo).then( async response => {
                        if (response.ok) {
                            const blob = await response.blob();
                            const objectUrl = URL.createObjectURL(blob);
                            setLogoUrl(objectUrl);
                        }else{
                            setLogoUrl(SkillglanceIcon)
                        }
                    })
                }

                // setEditJobInputErrMsg("")
                setSelectedJob(jobInfo)
                setJobLoading(false)
                setEditJobLoading(false)
                setEditJobInputMsg(null)
            }
        }else{
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                setJobLoading(false)
                setEditJobLoading(false)
                // show error in form not new modal
                setEditJobInputErrMsg(message)
                // setShowEditJobInputErrMsg(true)
            }
        }
    }

    async function openEditJobModal(){
        //NewStatus is in react select format but backend is normal 
        setNewStatus({value: selectedJob.status, label: capitalizeFirstLetter(selectedJob.status)})
        setNewCompany(selectedJob.company)
        setNewTitle(selectedJob.title)
        setNewLocation(selectedJob.location)
        setNewLink(selectedJob.link)
        setNewNotes(selectedJob.notes)
        setEditJobInputMsg({title: "Edit Job", desc: ""})
        setEditJobInputErrMsg("")
    }

    async function handleApplyClick(){
        selectedJob && window.open(selectedJob.link, '_blank')
    }

    async function handleJobDeleteClick(){
        selectedJob && setDeleteJobConfirmMsg({title: "Reminder", desc: "This action cannot be reversed, are you sure?"})
    }

    async function handleScanClick(){
        if (selectedJob && selectedJob.scan){
            setRedirect(`/scan/${selectedJob.scan}`)
        } else {
            setRedirect(`/scan?jobId=${selectedJob._id}`)
        }
    }

    if (redirect){
        return <Navigate to={redirect} />
    }

    return (
        <>
            <Error errorMsg={errorMsg} setErrorMsg={setErrorMsg} />
            <LoadConfirm confirmMsg={deleteJobConfirmMsg} setConfirmMsg={setDeleteJobConfirmMsg} customClick={() => {handleJobDelete()}} loading={deleteJobLoading} buttonText="Confirm"/>
            <EventInput inputMsg={createEventInputMsg} setInputMsg={setCreateEventInputMsg} buttonText="Confirm" 
                customClick={createEvent} loading={createEventLoading} setLoading={setCreateEventLoading}
                newDate={newDate} setNewDate={setNewDate} 
                newTime={newTime} setNewTime={setNewTime} 
                newType={newType} setNewType={setNewType}
                newDescription={newDescription} setNewDescription={setNewDescription}
                newCompleted={newCompleted} setNewCompleted={setNewCompleted}
                errorMsg={createEventInputErrMsg} setErrorMsg={setCreateEventInputErrMsg}
                isCreate={true}
            />
            <JobInput inputMsg={editJobInputMsg} setInputMsg={setEditJobInputMsg} buttonText="Confirm" 
                customClick={editJob} loading={editJobLoading} setLoading={setEditJobLoading}
                newStatus={newStatus} setNewStatus={setNewStatus} 
                newTitle={newTitle} setNewTitle={setNewTitle} 
                newCompany={newCompany} setNewCompany={setNewCompany} 
                newLocation={newLocation} setNewLocation={setNewLocation}
                newNotes={newNotes} setNewNotes={setNewNotes}
                newLink={newLink} setNewLink={setNewLink}
                errorMsg={editJobInputErrMsg} setErrorMsg={setEditJobInputErrMsg}
                // setShowBottomError={setShowEditJobInputErrMsg}
                setConfirmMsg={setDeleteJobConfirmMsg}
                isCreate={false}
            />
            <JobInput inputMsg={createJobInputMsg} setInputMsg={setCreateJobInputMsg} buttonText="Confirm" 
                customClick={createJob} loading={createJobLoading} setLoading={setCreateJobLoading}
                newStatus={newStatus} setNewStatus={setNewStatus} 
                newTitle={newTitle} setNewTitle={setNewTitle} 
                newCompany={newCompany} setNewCompany={setNewCompany} 
                newLocation={newLocation} setNewLocation={setNewLocation}
                newNotes={newNotes} setNewNotes={setNewNotes}
                newLink={newLink} setNewLink={setNewLink}
                errorMsg={createJobInputErrMsg} setErrorMsg={setCreateJobInputErrMsg}
                // setShowBottomError={setShowCreateJobInputErrMsg}
                newJobDescription={newJobDescription} setNewJobDescription={setNewJobDescription}
                isCreate={true}
            />
            <div className="job-page-wrapper">
            <div className={expand ? "job-page-top-bar hide" : "job-page-top-bar"}>     
                    <div className="top-bar-top">
                        <div className="top-bar-top-left">
                            <div className="top-bar-search">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="top-bar-search-icon">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                </svg>
                                <input 
                                    className="top-bar-search-input"
                                    type="text" 
                                    placeholder={"Search job"}
                                    value={search} 
                                    maxLength="100"
                                    onChange={ev => setSearch(ev.target.value)}/>  
                            </div>
                        </div>
                        <div className="top-bar-top-right">
                            <div className="top-bar-new-job" onClick={() => {handleCreateJob()}} >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="add-event-plus">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                </svg>
                                New job
                            </div> 
                            <Link className='job-page-account-circle' to={"/membership"}>
                                <div className='job-page-account-text'>{userInfo.username[0].toUpperCase()}</div>
                            </Link>
                        </div>                     
                    </div>
                    <div className="top-bar-status-bar">
                        <div className="top-bar-status-bar-inner-wrapper">
                            <div onClick={() => {fiterJobs("all")}} className={selectedStatus === "all" ? "top-bar-status-button top-bar-status-button-selected top-bar-status-button-first": "top-bar-status-button top-bar-status-button-first"}>
                                <div className="job-status-button-text">All{countJobs("all")}</div>
                            </div>
                            <div onClick={() => {fiterJobs("saved")}} className={selectedStatus === "saved" ? "top-bar-status-button top-bar-status-button-selected": "top-bar-status-button"}>
                                <div className={'job-status-button-dot job-saved'}>&#9679;</div>
                                <div className="job-status-button-text">Saved{countJobs("saved")}</div>
                            </div>
                            <div onClick={() => {fiterJobs("applied")}} className={selectedStatus === "applied" ? "top-bar-status-button top-bar-status-button-selected": "top-bar-status-button"}>
                                <div className={'job-status-button-dot job-applied'}>&#9679;</div>
                                <div className="job-status-button-text">Applied{countJobs("applied")}</div>
                            </div>
                            <div onClick={() => {fiterJobs("interviewing")}} className={selectedStatus === "interviewing" ? "top-bar-status-button top-bar-status-button-selected": "top-bar-status-button"}>
                                <div className={'job-status-button-dot job-interviewing'}>&#9679;</div>
                                <div className="job-status-button-text">Interviewing{countJobs("interviewing")}</div>
                            </div>
                            <div onClick={() => {fiterJobs("offer")}} className={selectedStatus === "offer" ? "top-bar-status-button top-bar-status-button-selected": "top-bar-status-button"}>
                                <div className={'job-status-button-dot job-offer'}>&#9679;</div>
                                <div className="job-status-button-text">Offer{countJobs("offer")}</div>
                            </div>
                            <div onClick={() => {fiterJobs("rejected")}} className={selectedStatus === "rejected" ? "top-bar-status-button top-bar-status-button-selected": "top-bar-status-button"}>
                                <div className={'job-status-button-dot job-rejected'}>&#9679;</div>
                                <div className="job-status-button-text">Rejected{countJobs("rejected")}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="job-page-main">
                    <div className={expand ? "job-page-left hide" : "job-page-left expand-job-page-left"}>              
                        {filteredJobs.length > 0 ? filteredJobs.map(job => (
                            <Job {...job} key={job._id} selectedJobId={selectedJob && selectedJob._id} setRedirect={setRedirect} setExpand={setExpand} setJobLoading={setJobLoading}/>
                        )) : 
                        <div className="empty">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="empty-icon">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                            </svg>
                            No jobs
                        </div> 
                        }
                    </div>
                    <div className={expand ? "job-page-right-container expand-job-page-right-container" : "job-page-right-container hide"}>
                        {!jobLoading ? selectedJob && <div className={expand ? "job-page-right expand-job-page-right" : "job-page-right"}>
                            <div className="job-page-right-mobile-top">
                                <div className="job-page-right-mobile-top-status">
                                    <div className={'job-page-right-top-status-dot job-' + selectedJob.status}>&#9679;</div>
                                    <div className="job-page-right-top-status-text">{selectedJob.status && capitalizeFirstLetter(selectedJob.status)}</div>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="job-page-close-job-desc" onClick={() => {setExpand(false)}}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>
                            <div className="job-page-right-top">
                                <div className="job-page-right-top-main">
                                    <div className="job-page-right-top-status">
                                        <div className={'job-page-right-top-status-dot job-' + selectedJob.status}>&#9679;</div>
                                        <div className="job-page-right-top-status-text">{selectedJob.status && capitalizeFirstLetter(selectedJob.status)}</div>
                                    </div>
                                    <div className="job-page-title-container">
                                        <div className="job-page-right-top-title">{selectedJob.title}</div>
                                    </div>
                                    <div className="job-logo-section">
                                        <div id="job-logo-wrapper">
                                            <img src={logoUrl} alt="Company Logo" id="job-company-logo"/>
                                        </div>  
                                        <div className="job-logo-section-text">
                                            <div className="job-page-company">{selectedJob.company}</div>
                                            <div className="job-page-company">{selectedJob.location ? selectedJob.location : "Unknown Location"}</div>
                                        </div> 
                                    </div>
                                    <div className="icons-bar">
                                        <div className="job-page-icon-wrapper">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="job-icon">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                            <div id="history-updated">
                                                {selectedJob.updatedAt ? `updated ${DaysAgo(selectedJob.updatedAt)}`
                                                : <div className="skeleton skeleton-text"></div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="status-select-wrapper">
                                        <div className="status-select">
                                            <div onClick={() => {changeStatus("saved")}} className={selectedJob.status === "saved" ? "status-select-button status-select-button-selected": "status-select-button"}>
                                                <div className={'job-status-button-dot job-saved'}>&#9679;</div>
                                                <div className="job-status-button-text">{`Saved`}</div>
                                            </div>
                                            <div onClick={() => {changeStatus("applied")}} className={selectedJob.status === "applied" ? "status-select-button status-select-button-selected": "status-select-button"}>
                                                <div className={'job-status-button-dot job-applied'}>&#9679;</div>
                                                <div className="job-status-button-text">{`Applied`}</div>
                                            </div>
                                            <div onClick={() => {changeStatus("interviewing")}} className={selectedJob.status === "interviewing" ? "status-select-button status-select-button-selected": "status-select-button"}>
                                                <div className={'job-status-button-dot job-interviewing'}>&#9679;</div>
                                                <div className="job-status-button-text">{`Interviewing`}</div>
                                            </div>
                                            <div onClick={() => {changeStatus("offer")}} className={selectedJob.status === "offer" ? "status-select-button status-select-button-selected": "status-select-button"}>
                                                <div className={'job-status-button-dot job-offer'}>&#9679;</div>
                                                <div className="job-status-button-text">{`Offer`}</div>
                                            </div>
                                            <div onClick={() => {changeStatus("rejected")}} className={selectedJob.status === "rejected" ? "status-select-button status-select-button-selected": "status-select-button"}>
                                                <div className={'job-status-button-dot job-rejected'}>&#9679;</div>
                                                <div className="job-status-button-text">{`Rejected`}</div>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                                <div className="job-page-right-top-buttons">
                                    <div className="job-action-button-wrapper">
                                        <div className="job-action-button" onClick={() => {handleScanClick()}}>Scan</div>
                                        <div className="job-action-button" onClick={() => {selectedJob.link ? handleApplyClick() 
                                            : setErrorMsg({title: "Oops", desc: "Please add a job link first."})}}>Apply</div>
                                        <div className="job-action-button" onClick={() => {openEditJobModal()}}>Edit</div>
                                        <div className="job-action-button red" onClick={() => {handleJobDeleteClick()}}>Delete</div>
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="job-page-more-icon">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                                        </svg> */}
                                    </div>
                                </div>                    
                            </div>
                            <div className="job-page-right-main">
                                <div className="job-page-right-left">
                                    <div className="job-page-right-title">Job Description</div> 
                                    <div className="job-page-right-desc">{selectedJob.description}</div> 
                                    <div className="job-page-right-title-notes">
                                        Notes
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" 
                                            className="edit-scan-header-company-edit" onClick={() => {openEditJobModal()}}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                        </svg>
                                    </div> 
                                    {selectedJob.notes ? <div className="job-page-right-desc">{selectedJob.notes}</div>:
                                        <div className="empty-message">No notes</div>
                                    }
                                </div>
                                <div className="job-page-right-timeline">
                                    <div className="job-page-right-title-timeline">Timeline
                                        <div className="job-page-right-add-event" onClick={() => handleCreateEvent()}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="add-event-plus">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                            </svg>
                                            Add
                                        </div>
                                    </div>
                                    <div className="events">
                                        {selectedJob && selectedJob.events.length > 0 ? selectedJob.events.map((event, index) => (
                                            <Event {...event} key={event._id} handleDelete={handleEventDelete} 
                                                isLast={index === selectedJob.events.length - 1} selectedJob={selectedJob} 
                                                setSelectedJob={setSelectedJob} jobs={jobs} setJobs={setJobs}/>
                                        )) : <div className="empty-message">No events</div>}
                                    </div> 
                                </div>
                            </div>

                        </div>: <Loading text="Loading job"/>}
                    </div>
                </div>
            </div>
        </>
    )

}