import { useState, useRef } from 'react'

export default function DragAndDrop({files, setFiles, types, dndStyle, state, question, dndOnly}){
    const [dragActive, setDragActive] = useState(false);
    const inputRef = useRef(null);
    const acceptString = '.' + types.join(',.');

    // handle drag events
    const handleDrag = function(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        if (ev.type === "dragenter" || ev.type === "dragover") {
            setDragActive(true);
        } else if (ev.type === "dragleave") {
            setDragActive(false);
        }
    }

    // triggers when file is dropped
    // display error message when wrong file type
    const handleDrop = function(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        setDragActive(false);
        if (ev.dataTransfer.files && ev.dataTransfer.files[0]) {
            let acceptedType = false
            for (let i = 0; i < types.length; i++){
                if (ev.dataTransfer.files[0].name.split('.').pop() === types[i]){
                    acceptedType = true
                }
            }
            if (acceptedType){
                setFiles(ev.dataTransfer.files)
            } else {
                alert("fuck you")
            }
        }
    }

    const onButtonClick = () => {
        inputRef.current.click();
    };

    const handleClickUpload = (ev) => {
        if (ev.target.files[0]){
            setFiles(ev.target.files)
            // console.log(files)
        }
    }

    const deleteFile = (ev) => {
        ev.stopPropagation();
        setFiles('')
        // console.log(files)
    }

    const preventPopup = (ev) => {
        ev.stopPropagation();
    }

    return(
        <div>
            {!dndOnly && <div className="application-question-title">
                {question + " "}
                {state == "Required" ? <span className='required'>*</span>
                : <span className='application-question-optional'>{"(Optional)"}</span>}
            </div>}
            <div className={dragActive ? `${dndStyle} application-drag-active` : dndStyle } onDragEnter={handleDrag} 
                onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop} onClick={() => onButtonClick()}>
                <div id="application-drag-inner-container">
                    <input id="input-file-upload" ref={inputRef} type="file" accept={acceptString} onChange={(ev) => handleClickUpload(ev)}/> 
                    {  files && files[0] ?
                        <div className="drag-file-selected-wrapper" onClick={preventPopup}>              
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="drag-file-paperclip">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13" />
                            </svg>
                            <div className="application-drag-filename-text">{files[0].name.length > 40 ? files[0].name.slice(0, 20).concat("...", files[0].name.slice(-17)) : files[0].name}</div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="drag-file-x" onClick={(ev) => deleteFile(ev)}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>

                        </div>:
                        <svg id="application-cloud-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path stroke="#EFF0F6" strokeLinecap="round" strokeLinejoin="round" d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
                        </svg>
                    }
                    <div className="drag-box-text"><span className="drag-upload-button">Select file</span> or drop your file here</div>
                    
                </div>
                { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
            </div>
        </div>
    ) 
}