import { Outlet } from "react-router-dom"
import { useState} from "react"
import Header from "./Header"


export default function Layout(){
    // const [noSroll, setNoScroll] = useState(false);

    return (
        <main>
            {/* <Header setNoScroll={setNoScroll}/> */}
            <Header/>
            <div id="header-outlet">
                <Outlet classname="outlet"/>
            </div>
        </main>
    )
}