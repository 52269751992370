import { useState, createContext} from "react"

export const FormContext = createContext({})

export function FormContextProvider({children}) {
    const [files, setFiles] = useState('')
    const [jobTitle, setJobTitle] = useState('')
    const [jobDesc, setJobDesc] = useState('')
    const [page, setPage] = useState(0)
    const [demoPage, setDemoPage] = useState(0)
    const [submitted, setSubmitted] = useState(false)
    const [results, setResults] = useState(null)
    const [demoResults, setDemoResults] = useState(null)

    //using existing job
    const [jobId, setJobId] = useState(null)

    //using existing resume
    const [templateId, setTemplateId] = useState(null)
    const [templateTitle, setTemplateTitle] = useState(null)

    const canSubmit = page === 1 && (files || templateId) && ((jobTitle && jobDesc) || jobId)


    return (
        <FormContext.Provider value={{files, setFiles, jobTitle, setJobTitle, jobDesc, setJobDesc, page, setPage, demoPage, setDemoPage, canSubmit, results, setResults, demoResults, setDemoResults, jobId, setJobId, templateId, setTemplateId, templateTitle, setTemplateTitle}}>
            {children}
        </FormContext.Provider>
    )
}
