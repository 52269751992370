import { useRef, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom"
import PremiumLogo from './images/premium_logo_white.svg';
import VanillaTilt from 'vanilla-tilt';
import "./Reward.css"
import Error from './modals/Error'
import Success from './modals/Success'
import LoadConfirm from './modals/LoadConfirm'
import {UserContext} from "./Context/UserContext";

export default function Reward({_id, title, points, background, getPoints, fetchReward, getPremiumExp, terms}){
    const [errorMsg, setErrorMsg] = useState(false)
    const [successMsg, setSuccessMsg] = useState(false)
    const [confirmMsg, setConfirmMsg] = useState(false)
    const [loading, setLoading] = useState(false)
    const tilt = useRef(null)
    const {setStatus, setUserInfo} = useContext(UserContext)
    const navigate = useNavigate()
    const options = {
        scale: 1.1,
        speed: 3000,
        max: 20,
        glare: true,
        "max-glare": 0.8,
    }
  
    useEffect(() => {
      VanillaTilt.init(tilt.current, options);
    }, [options]);

    async function handleRewardClick() {
        setLoading(true)
        const response = await fetch(process.env.REACT_APP_API_URL + '/redeem', {
            method: 'POST',
            body: JSON.stringify({ "reward_id": _id }),
            headers: {'Content-Type': 'application/json'},
            credentials: 'include' // cookies will be considered credentials
        });
        if (response.ok) {
            const message = await response.json()
            const utcTime = new Date(message.expires);
            const localTime = utcTime.toLocaleString();
            setLoading(false)
            setConfirmMsg(null)

            setSuccessMsg({title: "Success", desc: message.months + (message.months === 1 ? " month" : " months")
                + ` premium added to account. Expires ${localTime}`})
            getPoints() // Get points again to check of changes
            fetchReward()
            getPremiumExp()
        } else {
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                setLoading(false)
                setConfirmMsg(null)
                setErrorMsg({title: "Oops", desc: message})
            }
        }
    }

    function toLogin() {        
        navigate("/login")
    }

    return (
        <>
            <Success successMsg={successMsg} setSuccessMsg={setSuccessMsg} customClose={fetchReward} buttonText="Okay"/>
            <Error errorMsg={errorMsg} setErrorMsg={setErrorMsg} />
            <LoadConfirm confirmMsg={confirmMsg} setConfirmMsg={setConfirmMsg} customClick={handleRewardClick} buttonText="Confirm" loading={loading}/>
            <div ref={tilt} style={{background: background}} className="reward" 
                onClick={() => setConfirmMsg({title: "Reminder", desc: terms ? terms : "This reward is non-refundable, are you sure?"})}>
                <div className="reward-top">
                    <div className="reward-title">{title}</div>
                    <div className="reward-points">
                        <svg width="40" height="66" viewBox="0 0 40 66" fill="none" xmlns="http://www.w3.org/2000/svg" className="reward-star">
                            <path fillRule="evenodd" clipRule="evenodd" d="M20.0143 0.0212326C20.3893 13.4342 28.1322 25.3282 39.9998 33C28.1321 40.6718 20.3891 52.5658 20.0141 65.9788L19.9977 66L19.9859 65.9847C19.613 52.5693 11.8695 40.6729 0 33H0.000399714C11.8698 25.3271 19.6132 13.4306 19.9861 0.0152588L19.9979 0L20.0143 0.0212326Z" fill="white"/>
                        </svg>
                        {points} {points === 1? "point":"points"}
                    </div>
                </div>
                <div className="reward-bottom">
                    <img src={PremiumLogo} alt="Company Logo" className="reward-logo"/>
                </div>
            </div>
        </>

    )
}