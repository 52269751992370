import {useEffect, useState} from "react";

export default function AdminPage(){
    const [messages,setMessages] = useState([])
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [toAll, setToAll] = useState(false);
    const [receiver, setReceiver] = useState('');

    const [codes,setCodes] = useState([])
    const [code, setCode] = useState('');
    const [premiumMonths, setPremiumMonths] = useState(0);
    const [points, setPoints] = useState(0);
    const [expiresIn, setExpiresIn] = useState('');
    const [type, setType] = useState('');

    const [transactions,setTransactions] = useState([])

    const [subject, setSubject] = useState('');
    const [email, setEmail] = useState('');
    const [content, setContent] = useState('');

    const [scans,setScans] = useState([])

    useEffect(() => {
        getMessages()
        getCodes()
        getProcessing()
        getScans()
    }, []);

    // fetch and json is async
    const getMessages = async () =>{
        const response = await fetch(process.env.REACT_APP_API_URL + '/admin-get-messages', {credentials: 'include',})
        if (response.ok) {
            response.json().then(msg => {
                setMessages(msg)
            })
        } else{
            console.log("not allowed")
        }
    }

    // fetch and json is async
    const getCodes = async () =>{
        const response = await fetch(process.env.REACT_APP_API_URL + '/admin-get-codes', {credentials: 'include',})
        if (response.ok) {
            response.json().then(code => {
                setCodes(code)
            })
        } else{
            console.log("not allowed")
        }
    }

    const sendMessage = async (ev) =>{
        ev.preventDefault() //no freshing
        const response = await fetch(process.env.REACT_APP_API_URL + '/admin-send-message', {
            method: 'POST',
            body: JSON.stringify({title, description, toAll: toAll === "true", receiver}),
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
        })
        if (response.ok) {
            getMessages()
            console.log("confirmed")
        } else{
            getMessages()
            console.log("error")
        }
    }

    const createCode = async (ev) =>{
        ev.preventDefault() //no freshing
        const response = await fetch(process.env.REACT_APP_API_URL + '/admin-create-code', {
            method: 'POST',
            body: JSON.stringify({code, premiumMonths, points, expiresIn, type}),
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
        })
        if (response.ok) {
            getCodes()
            console.log("confirmed")
        } else{
            getCodes()
            console.log("error")
        }
    }

    const deleteMessage = async (messageId) =>{
        const response = await fetch(process.env.REACT_APP_API_URL + '/admin-delete-message', {
            method: 'POST',
            body: JSON.stringify({messageId}),
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
        })
        if (response.ok) {
            getMessages()
            console.log("confirmed")
        } else{
            getMessages()
            console.log("error")
        }
    }

    const deleteCode = async (codeId) =>{
        const response = await fetch(process.env.REACT_APP_API_URL + '/admin-delete-code', {
            method: 'POST',
            body: JSON.stringify({codeId}),
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
        })
        if (response.ok) {
            getCodes()
            console.log("confirmed")
        } else{
            getCodes()
            console.log("error")
        }
    }

    // fetch and json is async
    const getProcessing = async () =>{
        const response = await fetch(process.env.REACT_APP_API_URL + '/admin-get-processing-offers', {credentials: 'include',})
        if (response.ok) {
            response.json().then(trans => {
                setTransactions(trans)
            })
        } else{
            console.log("not logged in")
        }
    }

    const confirm = async (transId) =>{
        const response = await fetch(process.env.REACT_APP_API_URL + '/admin-confirm-offer', {
            method: 'POST',
            body: JSON.stringify({transId}),
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
        })
        if (response.ok) {
            getProcessing()
            console.log("confirmed")
        } else{
            console.log("not logged in")
        }
    }

    const sendEmail = async (ev) =>{
        ev.preventDefault()
        const response = await fetch(process.env.REACT_APP_API_URL + '/admin-send-email', {
            method: 'POST',
            body: JSON.stringify({subject, email, content}),
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
        })
        if (response.ok) {
            alert("sent")
        } else{
            alert("error")
        }
    }

    // fetch and json is async
    const getScans = async () =>{
        const response = await fetch(process.env.REACT_APP_API_URL + '/admin-get-scans', {credentials: 'include',})
        if (response.ok) {
            response.json().then(res => {
                setScans(res)
            })
        } else{
            console.log("not allowed")
        }
    }


    return (
        <div style={{ padding: "100px 100px"}}>
            <h2>messages</h2>
            <form onSubmit={sendMessage}>
                title
                <input type="text" 
                    value={title}
                    onChange={ev => setTitle(ev.target.value)}/>
                description
                <input type="text" 
                    value={description}
                    onChange={ev => setDescription(ev.target.value)}/>
                toAll
                <input type="text" 
                    value={toAll}
                    onChange={ev => setToAll(ev.target.value)}/>
                receiver
                <input type="text" 
                    value={receiver}
                    onChange={ev => setReceiver(ev.target.value)}/>
                <button>send message</button>
            </form>
            {messages.length > 0 && messages.map(messages => (
                <div key={messages._id}>  
                    id:
                    <div>{messages._id}</div>
                    title:
                    <div>{messages.title}</div>
                    description:
                    <div>{messages.description}</div>
                    toAll:
                    <div>{messages.toAll ? "true" : "false"}</div>
                    receiver:
                    <div>{messages.receiver && messages.receiver.username}</div>
                    <button onClick={() => {deleteMessage(messages._id)}}>delete</button>
                </div>
            ))}
            <h2>codes</h2>
            <form onSubmit={createCode}>
                code
                <input type="text" 
                    value={code}
                    onChange={ev => setCode(ev.target.value)}/>
                premiumMonths
                <input type="number" 
                    value={premiumMonths}
                    onChange={ev => setPremiumMonths(parseInt(ev.target.value, 10))}/>
                points
                <input type="number" 
                    value={points}
                    onChange={ev => setPoints(parseInt(ev.target.value, 10))}/>
                expires in days
                <input type="number" 
                    value={expiresIn}
                    onChange={ev => setExpiresIn(parseInt(ev.target.value, 10))}/>
                type
                <input type="text" 
                    value={type}
                    onChange={ev => setType(ev.target.value)}/>
                <button>create code</button>
            </form>
            {codes.length > 0 && codes.map(code => (
                <div key={code._id}>  
                    id:
                    <div>{code._id}</div>
                    code:
                    <div>{code.code}</div>
                    premiumMonths:
                    <div>{code.premium_months}</div>
                    points:
                    <div>{code.points}</div>
                    expires:
                    <div>{code.expires}</div>
                    type:
                    <div>{code.type}</div>
                    <button onClick={() => {deleteCode(code._id)}}>delete</button>
                </div>
            ))}
            <h2>pending offers</h2>
            <div style={{ paddingTop: "100px"}}>
                {transactions.length > 0 && transactions.map(transaction => (
                    <div key={transaction._id}>  
                        id:
                        <div>{transaction._id}</div>
                        offer:
                        <div>{transaction.offer.title}</div>
                        <div>{transaction.offer.shortDesc}</div>
                        <div>{transaction.offer.points}</div>
                        status:
                        <div>{transaction.status}</div>
                        user:
                        <div>{transaction.user && transaction.user.username}</div>
                        <div>{transaction.user && transaction.user.email}</div>
                        createAt:
                        <div>{transaction.createdAt}</div>
                        updated:
                        <div>{transaction.updatedAt}</div>
                        <button onClick={() => {confirm(transaction._id)}}>confirm</button>
                        ===============
                    </div>
                ))}
            </div>
            <h2>email</h2>
            <form onSubmit={sendEmail}>
                subject
                <input type="text" 
                    value={subject}
                    onChange={ev => setSubject(ev.target.value)}/>
                email
                <input type="text" 
                    value={email}
                    onChange={ev => setEmail(ev.target.value)}/>
                content
                <textarea type="text" 
                    value={content}
                    onChange={ev => setContent(ev.target.value)}/>
                <button>send email</button>
            </form>
            <h2>scans</h2>
            <div style={{ paddingTop: "100px"}}>
                {scans.length > 0 && scans.map(scan => (
                    <div key={scan._id}>  
                        id:
                        <div>{scan._id}</div>
                        user:
                        <div>{scan.user.username}</div>
                        <div>{scan.user.email}</div>
                        ===============
                    </div>
                ))}
            </div>
        </div>
    )
}