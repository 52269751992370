import { useContext } from 'react'
import { FormContext } from "./Context/FormContext"

export default function SectionDetailsOverview({results, section}){

    const desc = (score) => {
        if (score === 100){
            return `You have the perfect ${section} for the job!`
        } else if (score >= 70){
            return `Your ${section} are looking good!`
        } else if (score >= 50){
            return `Your ${section} are almost there.`
        } else {
            return `Your ${section} could use some work.`
        }
    }

    const score = (section) => {
        if (section === "Hard Skills"){
            return results["hardSkillScore"]
        } else if (section === "Soft Skills"){
            return results["softSkillScore"]
        } else if (section === "Credentials"){
            return results["credentialsScore"]
        } else if (section === "Format & Style"){
            return results["formatScore"]
        }
    }

    const explain = (section) => {
        if (section === "Hard Skills"){
            return "Your hard skill score is determined by the percentage of hard skill keywords included in the job description that’s also found on your resume. It is a common way used by ATS systems to determine your technical fit."
        } else if (section === "Soft Skills"){
            return "Your soft skill score is determined by the percentage of soft skill keywords included in the job description that’s also found on your resume. It is a common way used by ATS systems to determine your cultural fit."
        } else if (section === "Credentials"){
            return "Your credentials score is determined by how qualified you are for the job based on the job description. ATS systems automatically check if you match the requirements/qualifications."
        } else if (section === "Format & Style"){
            return "Your format & style score is determined by how well-formatted and well-written your resume is in the eyes of the ATS system. Making your resume machine-scanning friendly is important."
        }
    }

    const suggestion = (section, score) => {

        var suggestionString = null

        if (score === 100){
            suggestionString = "Incredible work! This section of your resume ready to go."
        }

        if (section === "Hard Skills"){
            if (!suggestionString){
                suggestionString = "Consider adding some missing keywords below to strengthen you resume."
            }
            return suggestionString + " Remember to only include skills that you possess."
        } else if (section === "Soft Skills"){
            if (!suggestionString){
                suggestionString = "You can add some missing keywords below to strengthen you resume."
            }
            return suggestionString + " Make a strong case by proving the skills through actual work or project experience."
        } else if (section === "Credentials"){
            if (!suggestionString){
                suggestionString = "Consider adding some missing credentials below to strengthen you resume."
            }
            return suggestionString + " Be sure to only include credentials you have."
        } else if (section === "Format & Style"){
            if (!suggestionString){
                suggestionString = "Consider incorporating some suggestions below."
            }
            return suggestionString + " A well-formatted resume is more likely to receive a high rating from the ATS system."
        }
    }

    const getStatName = (section) => {
        if (section === "Hard Skills"){
            return "hardSkillPercentile"
        } else if (section === "Soft Skills"){
            return "softSkillPercentile"
        } else if (section === "Credentials"){
            return "credentialsPercentile"
        } else if (section === "Format & Style"){
            return "formatPercentile"
        }        
    }

    return(
        <div className="section-detail-overview">
            <div className="section-detail-leftbar">
                <div className="section-detail-leftbar-title">{section}</div>
                <div className="section-detail-score-circle">
                    <div className="section-detail-score">{ score(section) }</div>
                </div>
                <div className="section-detail-leftbar-text">Higher than <span style={{color: "#8075FF", fontWeight: "700"}}>{`${results.stats[getStatName(section)]}%`}</span> of your peers!</div>
            </div>
            <div className="section-detail-rightbar">
                <div className="section-detail-rightbar-title">{ desc(score(section)) }</div>
                <div className="section-detail-rightbar-text">{ suggestion(section, score(section)) }</div>
                <div className="text-spacing"></div>
                <div className="section-detail-rightbar-title">What is a {section} score?</div>
                <div className="section-detail-rightbar-text">{ explain(section) }</div>
            </div>
        </div>     
    )
}