import { useState, createContext} from "react"

export const EditJobPostContext = createContext({})

export function EditJobPostContextProvider({children}) {
    const [questionData, setQuestionData] = useState({}) //custom questions


    return (
        <EditJobPostContext.Provider value={{questionData, setQuestionData}}>
            {children}
        </EditJobPostContext.Provider>
    )
}
