import { useContext } from 'react'
import { EditScanContext } from "./Context/EditScanContext"
import EditScanSectionDetailsOverview from "./EditScanSectionDetailsOverview"
import { v4 as uuidv4 } from 'uuid'

export default function EditScanCredentials(){
    const { results, section, jobTitle } = useContext(EditScanContext)
    const levelToDegree = ["No Degree", "High School Diploma", "Associate Degree", "Bachelor's Degree", "Master's Degree", "Doctorate Degree"]

    const suggestionTitle = (suggestion) => {
        if ( suggestion["type"] === "Measurable Results" ){
            if ( suggestion["success"] ){
                return `${suggestion["measurableResults"]}...`
            }else{
                return  "No Measurable Results Found"
            }
        } else if ( suggestion["type"] === "Job Title" ){
            if ( suggestion["success"] ){
                if (suggestion["matchedTitle"]){
                    return suggestion["matchedTitle"]
                } else {
                    return "Part of Job Title Found"
                }
            } else {
                return  "Job Title Not Found in Resume"
            }
        } else if ( suggestion["type"] === "Degree" ){
            if ( suggestion["success"] ){
                return levelToDegree[suggestion["eduLevel"]] + ( suggestion["eduLevel"] ? "" : " Required")
            } else {
                return levelToDegree[suggestion["jobLevel"]]
            }
        }
    }

    const suggestionDesc = (suggestion) => {
        if ( suggestion["type"] === "Measurable Results" ){
            if ( suggestion["success"] ){
                // const separator = ', ';
                // const withQuotes = suggestion["measurableResults"].map( (result) => `"${result}"` )
                // const combinedResults = withQuotes.join(separator);
                return  <div>Good job! We found the following measurable result: "{suggestion["measurableResults"]}".</div>
            } else {
                return <div>We didn't find any measurable results. Adding measurable results in your resume can make your accomplishments more credible.</div>
            }
        } else if ( suggestion["type"] === "Job Title" ){
            if (suggestion["matchedTitle"]){
                return  <div>Good job! We found a likely match of the job title: <b>{suggestion["matchedTitle"]}</b> in your resume.</div>
            } else if ( suggestion["partialMatched"] ){
                const separator = ', ';
                const withQuotes = suggestion["partialMatched"].map( (result) => `${result}` )
                const combinedResults = withQuotes.join(separator);
                return <div>We only found the following partial match(es): <b>{combinedResults}</b> in your resume for the job title. If you have held the position before, consider changing the wording to match the job title your are applying to.</div>
            }else{
                return <div>We didn't find a good match of the job title <b>{jobTitle}</b> in your resume, if you have held the position before, consider changing the wording to match the job title your are applying to.</div>
            }
        } else if ( suggestion["type"] === "Degree" ){
            if ( suggestion["success"] && !suggestion["jobLevel"]){
                return  <div>This job doesn't seem to have a preferred degree, you're good to go!</div>
            } else if ( suggestion["success"]){
                return <div>This job prefers a(n) <b>{levelToDegree[suggestion["jobLevel"]]}</b> which you satisfy with your <b>{levelToDegree[suggestion["eduLevel"]]}</b>.</div>
            } else if ( suggestion["eduLevel"]){
                return <div>This job prefers a(n) <b>{levelToDegree[suggestion["jobLevel"]]}</b> but you only have a(n) <b>{levelToDegree[suggestion["eduLevel"]]}</b>.</div>
            } else {
                return <div>This job prefers a(n) <b>{levelToDegree[suggestion["jobLevel"]]}</b> but you do not have an degree.</div>
            }
        }
    }


    return(
        <div>    
            <EditScanSectionDetailsOverview/>
            {/* <div>{results["formatSuggestions"][0].email}</div> */}
            <div className="suggestions-container">
                {results["credentialsSuggestions"].length > 0 && results["credentialsSuggestions"].map(suggestion => (
                    <div className="suggestion-small" key={uuidv4()}>
                        <div className="suggestion-type-small">{suggestion["type"]}</div>
                        <div className="suggestion-title-container-small">
                            <div className= {suggestion["success"]? "suggestion-icon-success":"suggestion-icon-fail"}>{
                                suggestion["success"]?
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="20px" height="20px" strokeWidth="2" stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                </svg>:
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="20px" height="20px" strokeWidth="2" stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            }</div>
                            <div className={suggestion["remove"] ? "suggestion-title-remove-small" : "suggestion-title-small"}>{suggestionTitle(suggestion)}</div>
                        </div>
                        <div className="suggestion-desc-small">{suggestionDesc(suggestion)}</div>
                    </div>
                ))}
            </div>  
        </div>       
    )
}