import { useContext, useState, useRef } from "react"
import FormFirst from "./FormFirst"
import FormSecond from "./FormSecond"
import FormThird from "./FormThird"
import { FormContext } from "./Context/FormContext"
import { v4 as uuidv4 } from 'uuid'
import {UserContext} from "./Context/UserContext";

export default function Form({setErrorMsg, requestId}){
    const {page, setPage, canSubmit, jobTitle, jobDesc, files, setResults, jobId, templateId} = useContext(FormContext) //add needed contexts
    const formTitles = ["Upload Your Resume", "Upload Job Description", "Results"]
    const formDesc = [
        ["Share what have at the moment and", " we’ll go from there!"], 
        ["Copy and paste the job title and", "description you’re applying."], 
        ["We're analyzing your documents,","this may take a couple seconds."]
    ]
    const {setStatus, setUserInfo} = useContext(UserContext)

    async function scanResume() {

        if (files[0] && files[0].size > 1024 * 1024){
            setErrorMsg({title: "Oops", desc: "File size exceeds 1MB limit."})
            return
        }

        //WARNING: FORMDATA CANNOT HANDLE NULL
        //it will convert to "null" string instead
        const data = new FormData() //we send text and files together
        data.set('jobTitle', jobTitle)
        data.set('jobDesc', jobDesc)
        data.set('file', files[0])
        if (!jobId){
            data.set('jobId', "")
        } else {
            data.set('jobId', jobId)
        }

        if (!templateId){
            data.set('templateId', "")
        } else {
            data.set('templateId', templateId)
        }

        //generate unique request id
        const uniqueId = uuidv4()
        requestId.current = uniqueId

        data.set('requestId', uniqueId)

        const response = await fetch(process.env.REACT_APP_API_URL + '/scan', {
            method: 'POST',
            body: data,
            credentials: 'include'
        })
        
        if (response.ok) {
            //parse json data into javascript object
            const jsonRes = await response.json()

            if (requestId.current === jsonRes.requestId){
                setResults(jsonRes)
            } else {
                console.log(`${requestId.current} vs ${jsonRes.requestId} result expired`)
            }
            
        } else {
            const jsonRes = await response.json();
            if (jsonRes === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                if (requestId.current === jsonRes.requestId){
                    setErrorMsg({title: "Oops", desc: jsonRes.message})
                } else {
                    console.log(`${requestId.current} vs ${jsonRes.requestId} result expired`)
                }
            }
        }

    }

    async function handlePrev() {
        setPage((currentPage) => currentPage - 1)
        //cancel request will not allow to goto results
        requestId.current = null
    }

    async function handleNext() {
        setPage((currentPage) => currentPage + 1)
    }

    const pageDisplay = () => {
        if (page === 0){
            return <FormFirst />
        } else if (page === 1){
            return <FormSecond />
        } else {
            return <FormThird />
        }
    }

    function handleSubmit(ev){
        ev.preventDefault()
        setResults(null)
        setPage(2)
        scanResume()
    }

    return(
        <div className="upload-form-container">
            <form className="upload_form" onSubmit={handleSubmit}>
                <div className="form-container">
                    <div className="form-inner-container">
                        <div className="progress-bar">
                            <div style={{width: page === 0? "33.3%" : page === 1? "66.6%" : "100%"}}></div>
                        </div>
                        <div className="header">
                            <h1 id="form-title">{formTitles[page]}</h1>
                            <div className="form-desc-container">
                                <div className="form-desc">{formDesc[page][0]}</div>
                                <div className="form-desc">{formDesc[page][1]}</div>
                            </div>
                        </div>
                        <div className={ page === 2 ? "form-body form-body-justify-center" : "form-body"}>{pageDisplay()}</div>
                        <div className="form-footer">
                            <button
                                type="button"
                                className={ page === 1 ? "next-previous-button right-space" : "next-previous-button"}
                                style={{display: page === 0 ? "none" : "block"}}
                                onClick={() => handlePrev()}>&#60; Prev
                            </button>
                            <button 
                                type="button"
                                className="next-previous-button"
                                disabled={page === 0 && !files && !templateId }
                                style={{display: page === 0 ? "block" : "none"}}
                                onClick={() => handleNext()}>Next &#62;
                            </button>
                            <button 
                                type="submit"
                                className={ page === 1 ? "submit-button left-space" : "submit-button"}
                                disabled={!canSubmit}
                                style={{display: page === 1? "block" : "none"}}>Submit
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>

    ) 
}