import "./LocationDropdown.css"
import Select from 'react-select'
import 'react-datepicker/dist/react-datepicker.css';
import SelectStyleGeneric from './SelectStyleGeneric'
import { useState, useRef } from "react";
import { Country, State, City }  from 'country-state-city';
import { v4 as uuidv4 } from 'uuid';
import LoadingGeneric from "./LoadingGeneric";

export default function LocationDropdownOneLine({selectedLocation, setSelectedLocation, state}){
    const [options, setOptions] = useState([])
    const [showOptions, setShowOptions] = useState([])
    const [location, setLocation] = useState("") //temp input only
    const [loading, setLoading] = useState(false)
    // const [selectedLocation, setSelectedLocation] = useState("")

    // last requested location
    const recentLocation = useRef("")
    const lastRequestTime = useRef("")

    const handleInputChange = async (e) => {
        setLocation(e.target.value)
        recentLocation.current = e.target.value

        setShowOptions(true)
        setOptions([])

        if (e.target.value === ""){
            setSelectedLocation("")
            return
        }

        setLoading(true)

        const now = new Date()
        if ( !lastRequestTime.current || now - lastRequestTime.current > 600){
            lastRequestTime.current = now
            const timeout = setTimeout(() => {
                sendRequest()
            }, 600)
        }
    }

    const sendRequest = async () => {
        const response = await fetch(process.env.REACT_APP_API_URL + '/location/', {
            method: 'POST',
            body: JSON.stringify({location: recentLocation.current}),
            headers: {'Content-Type': 'application/json'}
        })
        if (response.ok) {
            response.json().then(data => {
                if (data.location === recentLocation.current){
                    setOptions(data.output)
                    setLoading(false)
                }
            })
        } else {
            setOptions([])
            setLoading(false)
        }
    }

    const handleFocus = () => {
        if (!recentLocation.current){
            return
        }

        setOptions([])
        setLoading(true)
        sendRequest()
        setShowOptions(true);
    }

    const handleBlur = () => {
        setLocation(selectedLocation ? selectedLocation.string : "")
        recentLocation.current = selectedLocation ? selectedLocation.string : ""
        setShowOptions(false)
    };

    const handleSelect = (suggestion) => {
        setSelectedLocation(suggestion)
        setLocation(suggestion.string)
        recentLocation.current = suggestion.string 
        setShowOptions(false)
    };

    return(
        <div className="location-dropdown">    
            <div className="application-question">
                <div className="application-question-title">
                    {"Location "}
                    {state == "Required" ? <span className='required'>*</span>
                    : <span className='application-question-optional'>{"(Optional)"}</span>}
                </div>
                <input 
                    className='input-modal-input blue-border' 
                    onChange={handleInputChange} 
                    value={location}
                    onFocus={handleFocus}
                    onBlur={handleBlur}></input>
                {!loading && showOptions && options.length > 0 && 
                    <ul className="dropdown-menu">
                    {options.map((option) => (
                        <li key={uuidv4()} 
                        onClick={() => {handleSelect(option)}}
                        onMouseDown={(e) => e.preventDefault()}>{option.string}</li>
                    ))}
                    </ul>}
                {loading && showOptions &&
                    <div className="dropdown-menu-loading">
                        <LoadingGeneric />
                    </div>}
            </div> 
        </div>       
    )
}