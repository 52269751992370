import { useState, createContext, useRef } from "react"

export const EditScanContext = createContext({})

export function EditScanContextProvider({children}) {
    const [jobId, setJobId] = useState('')
    const [jobTitle, setJobTitle] = useState('')
    const [company, setCompany] = useState('')
    const [jobDesc, setJobDesc] = useState('')
    const [jobStatus, setJobStatus] = useState('')
    const [logo, setLogo] = useState('')
    const resume = useRef('') //always stores most recent resume across re-renders
    // const resumeDelta = useRef('') //always stores most recent resume across re-renders
    const [results, setResults] = useState(null)
    const [section, setSection] = useState('')
 
    // useRef is sync, immediately available and doesn't cause re-render
    const lastUpdateTime = useRef(null) //last time and edit was made
    const [isUpdateLoading, setIsUpdateLoading] = useState(false)

    return (
        <EditScanContext.Provider value={{jobId, setJobId, jobTitle, setJobTitle, company, setCompany, logo, setLogo, resume, results, setResults, lastUpdateTime, jobDesc, setJobDesc, isUpdateLoading, setIsUpdateLoading, section, setSection, jobStatus, setJobStatus}}>
            {children}
        </EditScanContext.Provider>
    )
}
