import { useContext, useState, useRef } from 'react'
import { FormContext } from "./Context/FormContext";
import FileSelect from './modals/FileSelect'
import Loading from "./Loading"
import DelayButton from './Button/DelayButton'

export default function FormFirst(){
    const {files, setFiles, setPage, templateId, setTemplateId, templateTitle, setTemplateTitle} = useContext(FormContext)
    const [dragActive, setDragActive] = useState(false);
    const inputRef = useRef(null);

    const [fileSelectInputMsg, setFileSelectInputMsg] = useState("")

    // handle drag events
    const handleDrag = function(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        if (ev.type === "dragenter" || ev.type === "dragover") {
            setDragActive(true);
        } else if (ev.type === "dragleave") {
            setDragActive(false);
        }
    }

    // triggers when file is dropped
    const handleDrop = function(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        setDragActive(false);
        if (ev.dataTransfer.files && ev.dataTransfer.files[0]) {
            setFiles(ev.dataTransfer.files)
            setTemplateId(null)
            setTemplateTitle(null)
        }
    }

    async function OpenSelectTemplates(){
        setFileSelectInputMsg({title: "File Upload"})
    }

    return(
        <>
            <FileSelect inputMsg={fileSelectInputMsg} setInputMsg={setFileSelectInputMsg} templateId={templateId} setTemplateId={setTemplateId} files={files} setFiles={setFiles} setTemplateTitle={setTemplateTitle}/>
            <div className="first-form-container">
                {/* restrict upload type in backend */}
                <div className={dragActive ? "drag-container drag-active" : "drag-container" } onDragEnter={handleDrag} 
                    onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}>
                    <div id="drag-inner-container">
                        <input id="input-file-upload" ref={inputRef} type="file"/> 
                        { files || templateTitle ?
                            <div id="icon_text_wrapper">              
                                <svg id="check_icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <div className="file-uploded-text">File selected</div>
                                {files && <div className="filename-text">{files[0].name.length > 40 ? files[0].name.slice(0, 20).concat("...", files[0].name.slice(-17)) : files[0].name}</div>}
                                {templateTitle && <div className="filename-text">{templateTitle > 40 ? templateTitle.slice(0, 20).concat("...", templateTitle.slice(-17)) : templateTitle}</div>}
                            </div>:
                            <div id="icon_text_wrapper">
                                <svg id="cloud_icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path stroke="#EFF0F6" strokeLinecap="round" strokeLinejoin="round" d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
                                </svg>
                                <div className="drag-box-text">Drop your PDF file here or</div>
                            </div>
                        }
                        {/* <button type="button" className="upload-button" onClick={() => onButtonClick()}>Select file</button> */}
                        <button type="button" className="upload-button" onClick={() => OpenSelectTemplates()}>Select file</button>
                    </div>
                    { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
                </div>
            </div>
        </>
    ) 
}