import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import {formatISO9075, roundToNearestMinutes} from "date-fns"
import {Link} from "react-router-dom" 
import SkillglanceIcon from './images/skillglance_icon_grey.svg'
import LoadConfirm from './modals/LoadConfirm'

export default function Job({_id, title, company, logo, location, status, scan, updatedAt, selectedJobId, setRedirect, setExpand, setJobLoading}){
    
    // Logo now provided by "https://www.vectorlogo.zone/logos/index.html"
    // const [logo, setLogo] = useState(job ? job.logo : null)
    const [logoUrl, setLogoUrl] = useState(SkillglanceIcon)
    const navigate = useNavigate()

    useEffect(() => {
        if (!logo){
            setLogoUrl(SkillglanceIcon)
        } else {
            fetch(logo).then( async response => {
                if (response.ok) {
                    const blob = await response.blob();
                    const objectUrl = URL.createObjectURL(blob);
                    setLogoUrl(objectUrl);
                }else{
                    setLogoUrl(SkillglanceIcon)
                }
            })
        }
    }, [company])

    function DaysAgo(date) {
        const createdAtDate = new Date(date)
        const currentDate = new Date()

        // Calculate the time difference in milliseconds
        const timeDifference = currentDate - createdAtDate;
      
        // Calculate the number of days
        const daysAgo = Math.max(0, Math.floor(timeDifference / (1000 * 60 * 60 * 24)))

        if (daysAgo === 1){
            return "1 day ago"
        } else if (daysAgo < 100){
            return daysAgo + " days ago"
        } else {
            return "99+ days ago"
        }
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function expandJobDesc(){
        setExpand(true)
    }

    function goToJob(){
        if (_id === selectedJobId){
            setExpand(true)
        } else {
            navigate(`/jobs?jobId=${_id}`)

            //force it to show loading and cover previous job 
            //this is to avoid delay which is obvious on mobile
            setJobLoading(true)
            setExpand(true)
        }
    }

    return (
        <>
            <div className="job" id={_id === selectedJobId ? "selected-job" : null} onClick={() => {title && goToJob()}}>
                <div className="job-inner-wrapper">
                    <div className="job-status-bar">
                        <div className={'job-status-dot job-' + status}>&#9679;</div>
                        <div className="job-status-text">{status && capitalizeFirstLetter(status)}</div>
                    </div>
                    <div className="job-main">
                        <div id="history-logo-and-score">
                            <div id="history-logo-wrapper">                                    
                                <img src={logoUrl} alt="Company Logo" id="history-company-logo"/>
                            </div>
                        </div>
                        <div id="history-text">
                            <div className="job-text-top">
                                <div id="history-text-top-left">
                                    <div className="job-title">
                                        {title ? title : <div className="skeleton skeleton-text last-line"></div>}
                                    </div>
                                    <div className="job-company">
                                        {company ? company : <div className="skeleton skeleton-text" id="job-company-skeleton"></div>}
                                    </div>
                                    <div className="job-location">
                                        {title ? (location ? location : "Unknown Location") : <div className="skeleton skeleton-text" id="job-location-skeleton"></div>}
                                    </div>
                                    <div className="icons-bar">
                                        <div className="job-page-icon-wrapper">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="job-icon">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                            <div id="history-updated">
                                                {updatedAt ? DaysAgo(updatedAt)
                                                : <div className="skeleton skeleton-text"></div>}
                                            </div>
                                        </div>
                                        {scan &&
                                            <div className="job-page-icon-wrapper">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="job-icon">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                                <div className='application-ready'>Scan complete</div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>                                      
        </>
    )
}