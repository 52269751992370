// import { useContext } from "react";
// import { DialogContext } from "./Context/DialogContext";
import ConfirmImg from '../images/confirm.svg';
import Modal from './Modal'
import './Modal.css'
import DelayButton from '../Button/DelayButton'


// if stop load is present use load button
export default function Confirm({confirmMsg, setConfirmMsg, customClick, customClose, buttonText, loading}){

    function handleClose() {
        if (!loading){
            // will call fetch offer and remove this modal immediately
            customClose && customClose()
            setConfirmMsg(null)
        }
    }

    function handleClick(){
        customClick && customClick()
    }

    return (
        <Modal open={confirmMsg} onClose={() => handleClose()}>
            {confirmMsg &&
                <div className="success-confirm-modal-inner-wrapper">
                    <img src={ConfirmImg} alt="confirm image" className="error-modal-image"/>
                    <div className="error-modal-title">{confirmMsg.title}</div>
                    <div className="error-modal-text">{confirmMsg.desc}</div>
                    <DelayButton buttonClassName="confirm-button" buttonClassNameLoading="confirm-button-loading" isLoading={loading} onClick={() => handleClick()}>{buttonText}</DelayButton>
                    <button className={loading ? "cancel deactivated" : "cancel" } onClick={() => handleClose()}>Cancel</button>
                </div>
            }
        </Modal>
    )
}