import { useState, useEffect} from 'react'

export default function IndexPageInterviewBar({score, month}){
    const [progressValue, setProgressValue] = useState(0)

    useEffect(() => {
        if (progressValue < score){
            setTimeout(() => {
                setProgressValue(progressValue + 1)
            }, 4)
        }
    }, [progressValue, score])

    return (
        <div className="index-banner-box-tracker-bar">
            <div className="index-banner-box-tracker-score">
                <div style={{ height: `${progressValue}%` }}></div>
            </div>
            <div className="index-banner-box-tracker-month">{month}</div>
        </div>
    )
}