import {useEffect, useState, useContext, useRef} from "react";
import {Link} from "react-router-dom"
import {UserContext} from "../Context/UserContext";
import {LeftBarContext} from "../Context/LeftBarContext"
import "./BenefitsPage.css"
import Loading from "../Loading"

export default function BenefitsPage() {
    const { status, setStatus, userInfo, setUserInfo} = useContext(UserContext);
    const {setLock, setSelected} = useContext(LeftBarContext)
    const [membership, setMembership] = useState("")

    useEffect(() => {
        setLock(false)
        setSelected("membership")
        getPremiumExp()
    }, []);

    const getPremiumExp = async () =>{
        const response = await fetch(process.env.REACT_APP_API_URL + '/user', {credentials: 'include',})
        if (response.ok) {
            const jsonResponse = await response.json()

            if (new Date(jsonResponse.premium_expires) >= new Date()) {
                setMembership("premium")
                
            } else {
                setMembership("standard")
            }

        } else{
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            }
        }        
    }

    return (
        <>{membership ?
            <div className="benefits-page-wrapper">
                <div className="benefits-page-title">Our Membership Plans</div>
                <div className="benefits-page-desc">All our plans are free, no payment required. We only require points to encourage regular use of the platform.</div>
                <div className="benefits-page-plans-container">
                    <div className="benefits-page-plan-wrapper">
                        <div className="benefits-page-plan-header placeholder"></div>
                        <div className="benefits-page-plan">
                            <div className="benefits-page-plan-title">Standard</div>
                            <div className="benefits-page-plan-price">
                                <div className="benefits-page-plan-price-big">Free</div>
                            </div>
                            <div className="benefits-page-plan-desc">Our basic plan to help you get started for your job search journey</div>
                            <div className="benefits-page-plan-benefit">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="22px" height="22px" strokeWidth="2" stroke="currentColor" className="benefits-page-plan-benefit-icon">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                </svg>
                                <div className="benefits-page-plan-benefit-text">5 new scans per week</div>
                            </div>
                            <div className="benefits-page-plan-benefit">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="22px" height="22px" strokeWidth="2" stroke="currentColor" className="benefits-page-plan-benefit-icon">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                </svg>
                                <div className="benefits-page-plan-benefit-text">Unlimited rescans in editor</div>
                            </div>
                            <div className="benefits-page-plan-benefit">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="22px" height="22px" strokeWidth="2" stroke="currentColor" className="benefits-page-plan-benefit-icon">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                </svg>
                                <div className="benefits-page-plan-benefit-text">20 scan histories</div>
                            </div>
                            <div className="benefits-page-plan-benefit">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="22px" height="22px" strokeWidth="2" stroke="currentColor" className="benefits-page-plan-benefit-icon">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                </svg>
                                <div className="benefits-page-plan-benefit-text">5 resume storage space</div>
                            </div>
                            <div className="benefits-page-plan-benefit">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="22px" height="22px" strokeWidth="2" stroke="currentColor" className="benefits-page-plan-benefit-icon">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                </svg>
                                <div className="benefits-page-plan-benefit-text">100 job applications</div>
                            </div>
                            {membership === "standard" ? 
                                <div className="benefits-page-select-button current-plan">
                                    Current plan
                                </div>:
                                <div className="benefits-page-select-button disabled">
                                    Select
                                </div>
                            }

                        </div>
                    </div>
                    <div className="benefits-page-plan-wrapper">
                        <div className="benefits-page-plan-header">Recommended</div>
                        <div className="benefits-page-plan premium-border">
                            <div className="benefits-page-plan-title">Premium</div>
                            <div className="benefits-page-plan-price">
                                <div className="benefits-page-plan-price-big">Free</div>
                                <div className="benefits-page-plan-price-small">with points</div>
                            </div>
                            <div className="benefits-page-plan-desc">For active job seekers looking to stand out from the competition </div>
                            <div className="benefits-page-plan-benefit">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="22px" height="22px" strokeWidth="2" stroke="currentColor" className="benefits-page-plan-benefit-icon">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                </svg>
                                <div className="benefits-page-plan-benefit-text">100 new scans per week</div>
                            </div>
                            <div className="benefits-page-plan-benefit">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="22px" height="22px" strokeWidth="2" stroke="currentColor" className="benefits-page-plan-benefit-icon">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                </svg>
                                <div className="benefits-page-plan-benefit-text">Unlimited rescans in editor</div>
                            </div>
                            <div className="benefits-page-plan-benefit">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="22px" height="22px" strokeWidth="2" stroke="currentColor" className="benefits-page-plan-benefit-icon">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                </svg>
                                <div className="benefits-page-plan-benefit-text">500 scan histories</div>
                            </div>
                            <div className="benefits-page-plan-benefit">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="22px" height="22px" strokeWidth="2" stroke="currentColor" className="benefits-page-plan-benefit-icon">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                </svg>
                                <div className="benefits-page-plan-benefit-text">200 resume storage space</div>
                            </div>
                            <div className="benefits-page-plan-benefit">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="22px" height="22px" strokeWidth="2" stroke="currentColor" className="benefits-page-plan-benefit-icon">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                </svg>
                                <div className="benefits-page-plan-benefit-text">2000 job applications</div>
                            </div>
                            {membership === "premium" ? 
                                <div className="benefits-page-select-button current-plan">
                                    Current plan
                                </div>:
                                <Link className="benefits-page-select-button" to="/membership">
                                    Select
                                </Link>
                            }
                        </div>
                    </div>
                </div>
            </div>: <Loading text="Loading membership plans"/>
        }</>
    )
    
}