import { useContext, useEffect } from "react"
import {UserContext} from "../Context/UserContext"
import DemoForm from "../DemoForm.js"
import DemoResults from "../DemoResults.js"
// import { FormContextProvider } from '../Context/FormContext'
import { FormContext } from "../Context/FormContext";

export default function DemoPage() {
    const {demoResults} = useContext(FormContext)
    const {setIndexPage} = useContext(UserContext)

    useEffect(() => { 
        setIndexPage(false)
    },[])

    return (
        <div id="demo-scanner-page-container">
            {demoResults? <DemoResults /> : <DemoForm />}
        </div>
    )
}