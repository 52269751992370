import {useEffect, useRef, useContext, useState} from 'react'
import {Link, useParams, useNavigate} from "react-router-dom"
import {UserContext} from "../Context/UserContext"
import {EditScanContext} from "../Context/EditScanContext"
import {LeftBarContext} from "../Context/LeftBarContext"
import ScanResumeBar from "../ScanResumeBar"
import EditScanSectionDetails from "../EditScanSectionDetails"
import EditScanToolbar, { formats } from "../EditScanToolbar"
import Quill from 'quill'
import { v4 as uuidv4 } from 'uuid';
import "./EditScanPage.css"
import "./EditTemplate.css"

import Error from '../modals/Error'
import Success from '../modals/Success'
import Input from '../modals/Input'
import Loading from "../Loading"
import DelayButton from '../Button/DelayButton'
import UploadStatus from "../UploadStatus"

export default function EditTemplate(){
    const {id} = useParams() //access the parameters of the current URL
    const {status, userInfo, setStatus, setUserInfo} = useContext(UserContext)
    const {setLock, setSelected} = useContext(LeftBarContext)
    
    const [initialResume, setInitialResume] = useState(null)
    const resume = useRef(null)
    const editorRef = useRef(null);
    const toolbarRef = useRef(null);
    const [title, setTitle] = useState('')
    const lastUpdateTime = useRef(null) //last time and edit was made
    const [isUpdateLoading, setIsUpdateLoading] = useState(false)

    const [errorMsg, setErrorMsg] = useState(false)
    const [successMsg, setSuccessMsg] = useState(false)
    const [inputMsg, setInputMsg] = useState(false)
    const [newTitle, setNewTitle] = useState("")
    const [editTitleLoading, setEditTitleLoading] = useState(false)
    const [editTitleError, setEditTitleError] = useState("")
    const [showEditTitleError, setShowEditTitleError] = useState("")

    const resumeTitleLimit = 200 //double check limit with backend


    useEffect(() => {
        setLock(false)
        setSelected("templates")
        const fetchTemplate = async () =>{
            const response = await fetch(process.env.REACT_APP_API_URL + `/resume/${id}`, {
            credentials: 'include',})
            if (response.ok) {
                response.json().then(templateData => {
                    setTitle(templateData.title)
                    setInitialResume(templateData.content)
                })
            } else{
                console.log("failed to get template")
            }
        }
        fetchTemplate() 
    }, [])

    // runs on initial render and value initialResume change
    // reference: https://quilljs.com/docs/modules/toolbar/
    useEffect(() => {
        // Check if the div we are referencing is in the DOM
        if (initialResume && editorRef.current && toolbarRef.current) {
            const editor = new Quill(editorRef.current, {
                modules: {
                    toolbar: {
                      container: toolbarRef.current,
                      handlers: {
                        undo: undoChange,
                        redo: redoChange
                      }
                    },
                    history: {
                      delay: 500,
                      maxStack: 100,
                      userOnly: true
                    }
                },
                formats: formats,
                theme: 'snow',
                style: '.ql-toolbar { background-color: #eee; }',
                // prevent scrolling on paste
                scrollingContainer: '#editorContainer'
            });

            console.log("ready")

            // prevent scroll to top on style change
            toolbarRef.current.addEventListener("mousedown", function(event) {

                event.preventDefault()
                event.stopPropagation()
            })

            const delta = editor.clipboard.convert(initialResume)
            editor.setContents(delta)
            
            editor.on('text-change', () => {
                // useRef always get the most recent resume unlike useState 
                // which will rerender and the old render will have the old value even after 3 seconds
                resume.current = editor.root.innerHTML

                // resumeDelta.current = editor.getContents()
                setIsUpdateLoading(true)

                const now = new Date()
                // wait 2 seconds before sending request, next request can be queued
                // once the previous one is sent
                if ( !lastUpdateTime.current || now - lastUpdateTime.current > 2000){
                    console.log("start queuing")
                    lastUpdateTime.current = now
                    console.log(now)
                    console.log(lastUpdateTime.current)
                    const timeout = setTimeout(() => {
                        //send request
                        console.log("send request")
                        editResume()
                    }, 2000)
                }
            })
        }
    }, [initialResume])

    // send request time and get back request time
    // useState is async but does NOT return promise so await won't work
    // also useState will cause re-render and in the new render it will have new values 
    // but the current one won't, potentially causing issues
    // will use mostRecentResume not resume, as resume will still be 
    // value from 3 seconds ago because it is from a previous render
    async function editResume() {
        const response = await fetch(process.env.REACT_APP_API_URL + "/resume", {
            method: 'PUT',
            body: JSON.stringify({id, content: resume.current, lastUpdateTime: lastUpdateTime.current}),
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })
        if (response.ok) {
            const templateInfo = await response.json()
            const serverResponseDate = new Date(templateInfo.lastUpdateTime)
            // must use .getTime() or will compare if it is the same OBJECT
            if (serverResponseDate.getTime() === lastUpdateTime.current.getTime()){
                setIsUpdateLoading(false)
                console.log("up to date")
            }
        } else {
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            }
        }
    }

    // Undo and redo functions for Custom Toolbar
    function undoChange() {
        // "this" is caller, aka toolbar
        this.quill.history.undo();
    }
    function redoChange() {
        this.quill.history.redo();
    }

    async function downloadResume() {    
        // const quillToWordConfig = {
        //     exportAs: 'blob'
        // };
        // const docAsBlob = await quillToWord.generateWord(resumeDelta.current, quillToWordConfig);
        // saveAs(docAsBlob, 'word-export.docx');

        window.print();
    }


    async function editTitle() {
        setEditTitleLoading(true)
        setShowEditTitleError(false)
        const response = await fetch(process.env.REACT_APP_API_URL + '/resume-title', {
            method: 'PUT',
            body: JSON.stringify({templateId: id, title: newTitle}),
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })
        if (response.ok) {
            const templateInfo = await response.json()
            setTitle(templateInfo.title)
            setEditTitleLoading(false)
            setNewTitle("")
            setInputMsg(null)
        }else{
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                setEditTitleLoading(false)
                setEditTitleError(message)
                setShowEditTitleError(true)                
                // setNewTitle("")
                // setInputMsg(null)
                // setErrorMsg({title: "Oops", desc: message})
            }
        }
    }

    function CheckTitleError(value){

        if ( !isNotAllSpaces(value) ){
            return "Title cannot be empty."
        }

        if ( value.length > resumeTitleLimit){
            return `Input exceeds maximum character limit of ${resumeTitleLimit}.`
        }
    }

    function isNotAllSpaces(inputString) {
        // Use a regular expression to check if the string contains at least one non-space character
        return /\S/.test(inputString);
    }

    return (
        initialResume && title ? <>
            <Success successMsg={successMsg} setSuccessMsg={setSuccessMsg} buttonText="Okay"/>
            <Error errorMsg={errorMsg} setErrorMsg={setErrorMsg} />
            <Input inputMsg={inputMsg} setInputMsg={setInputMsg} 
                buttonText="Confirm" value={newTitle} 
                setValue={setNewTitle} placeholder="Resume Title" 
                customClick={editTitle} loading={editTitleLoading} 
                setLoading={setEditTitleLoading} CheckError={CheckTitleError} 
                bottomError={editTitleError} showBottomError={showEditTitleError} 
                setShowBottomError={setShowEditTitleError} />
            <div className='edit-template-container'>
                <div className='edit-scan-header'>
                    <div className='edit-template-header-left'>
                        <Link to="/resumes" className='edit-scan-header-back'>
                            <svg id="edit-scan-header-arrow" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                            </svg>
                        </Link>
                        <div className='edit-template-header-left-main'>
                            Resume
                            <div className='edit-template-header-company-container'>
                                <div className='edit-template-header-title'>{title}</div>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" 
                                    className="edit-scan-header-company-edit"
                                    onClick={() => {setInputMsg({title: "Edit Resume Title", desc: ""})}}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className='edit-scan-header-right'>
                        <UploadStatus
                            isLoading={isUpdateLoading}>
                            {/* onClick={handleScanClick} */}
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="upload-updated-icon">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            Synced
                        </UploadStatus>
                        <button className='edit-scan-header-download' onClick={() => downloadResume()}>Download</button>
                        <Link to="/membership" className='edit-scan-account-circle'>
                            <div className='edit-scan-account-text'>{userInfo.username[0].toUpperCase()}</div>
                        </Link>
                    </div>
                </div>
                <div className='edit-template-main'>
                    <div className='edit-template-main-left'>
                        <div className='edit-scan-main-left-toolbar'>
                            <div ref={toolbarRef} ><EditScanToolbar /></div>
                        </div>
                        <div className='edit-template-main-left-content'>
                            <div className='edit-template-editor-wrapper' id="editorContainer">
                                <div className='edit-template-editor-container'>
                                    <div ref={editorRef}
                                        style={{height:"100%", border:"none", color:"#333", backgroundColor:"white"}} >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </> : <Loading text="Loading your resume"/>
    )
}