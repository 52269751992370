// import { useContext } from "react";
// import { DialogContext } from "./Context/DialogContext";
import ErrorImg from '../images/error.svg';
import Modal from './Modal'
import './Modal.css'

export default function Error({errorMsg, setErrorMsg, customClick = null}){

    function handleClose() {
        // will call fetch offer and remove this modal immediately
        customClick && customClick()
        setErrorMsg(null)
    }

    return (
        <Modal open={errorMsg} onClose={() => handleClose()}>
            {errorMsg &&
                <div className="error-modal-inner-wrapper">
                    <img src={ErrorImg} alt="error image" className="error-modal-image"/>
                    <div className="error-modal-title">{errorMsg.title}</div>
                    <div className="error-modal-text">{errorMsg.desc}</div>
                    <button className="error-modal-button" onClick={() => handleClose()}>Okay</button>
                </div>
            }
        </Modal>
    )
}