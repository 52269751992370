import { useContext, useEffect } from 'react'
import { EditScanContext } from "./Context/EditScanContext"
import EditScanHardSkills from "./EditScanHardSkills"
import EditScanSoftSkills from "./EditScanSoftSkills"
import EditScanCredentials from "./EditScanCredentials"
import EditScanFormatAndStyle from "./EditScanFormatAndStyle"

export default function SectionDetails(){
    // re-renders when section changes
    const {section} = useContext(EditScanContext)

    const sectionDisplay = () => {
        if (section === "Hard Skills"){
            return <EditScanHardSkills />
        } 
        else if (section === "Soft Skills"){
            return <EditScanSoftSkills />
        }        
        else if (section === "Credentials"){
            return <EditScanCredentials />
        } 
        else if (section === "Format & Style"){
            return <EditScanFormatAndStyle />
        } 
    }

    return(
        <div className={ section ? "edit-scan-section-detail-container" : "edit-scan-section-detail-container hidden"}>{sectionDisplay()}</div>
    )
}