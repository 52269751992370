import { useContext, useState, useRef } from 'react'
import { FormContext } from "./Context/FormContext";

export default function DemoFormFirst(props){
    const {setDemoPage} = useContext(FormContext)
    
    // triggers the input when the button is clicked
    const onButtonClick = () => {
        props.setDemoFile("my_resume.pdf")
    };


    return(
        <div className="first-form-container clickable" onClick={() => onButtonClick()}>
            {/* restrict upload type in backend */}
            <div className={"drag-container" }>
                <div id="drag-inner-container">
                    <div id="input-file-upload"/> 
                        { props.demoFile ?
                            <div id="icon_text_wrapper">              
                                <svg id="check_icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <div className="file-uploded-text">File selected</div>
                                <div className="filename-text">{props.demoFile}</div>
                            </div>:
                            <div id="icon_text_wrapper">
                                <svg id="cloud_icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path stroke="#EFF0F6" strokeLinecap="round" strokeLinejoin="round" d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
                                </svg>
                                <div className="drag-box-text">Click to add demo file</div>
                            </div>
                        }
                    <button type="button" className="upload-button">Select file</button>
                </div>
            </div>
        </div>
    ) 
}