import "./JobPostPage.css"
import { useState, useEffect, useContext } from 'react'
import {UserContext} from "../Context/UserContext"
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Question from "../Question"
import CustomQuestion from "../CustomQuestion"
import {EditJobPostContext} from "../Context/EditJobPostContext"
import IndexPage from "./IndexPage"
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer, toast, Slide } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export default function JobPostPage() {
    const {userInfo, setStatus, setUserInfo} = useContext(UserContext)

    const {questionData, setQuestionData} = useContext(EditJobPostContext) //map of question id to data
    const [questions, setQuestions] = useState([]) //only store the question order

    // const [middleName, setMiddleName] = useState("Disabled")
    const [phone, setPhone] = useState("Optional")
    const [address, setAddress] = useState("Optional")
    const [education, setEducation] = useState("Optional")
    const [experience, setExperience] = useState("Optional")
    const [resume, setResume] = useState("Optional")
    // const [gpa, setGpa] = useState("Optional")

    const [createQuestionLoading, setCreateQuestionLoading] = useState(false)
    const [addNewQuestionDropdown, setAddNewQuestionDropdown] = useState(false)

    // //how can users confrigue each input
    // const rules = {
    //     "First name": ["Required"],
    //     "Middle name": ["Optional", "Disabled"],
    //     "Last name": ["Required"],
    //     "Start date": ["Required"],
    //     "End date": ["Required"],
    //     "GPA": ["Optional", "Disabled"],
    // }

    function handleOnDragEnd(result) {
      if (!result.destination) return
  
      const items = Array.from(questions)
      const [reorderedItem] = items.splice(result.source.index, 1)
      items.splice(result.destination.index, 0, reorderedItem)
  
      setQuestions(items)
    }

    async function createQuestion (type) {

        setAddNewQuestionDropdown(false)

        // toast.error('!djnewndkew nejdnkwendjkwe', {
        //     position: "top-center",
        //     autoClose: 5000,
        //     hideProgressBar: true,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: false,
        //     progress: undefined,
        //     theme: "colored",
        //     transition: Slide,
        // });

        const newQuestion = {
            _id: "",
            uuid: uuidv4(),
            type: type,
            state: "Required",
            question: "",
            options: []
        }

        // add to question data map
        const updatedQuestions = {...questionData}
        updatedQuestions[newQuestion.uuid] = newQuestion
        setQuestionData(updatedQuestions)

        // add to question array 
        if (questions){
            setQuestions([...questions, newQuestion.uuid])
        } else {
            setQuestions([newQuestion.uuid])
        }

        // setCreateQuestionLoading(true)
        // const response = await fetch(process.env.REACT_APP_API_URL + '/question', {
        //     method: 'POST',
        //     // body: JSON.stringify({}),
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     credentials: 'include'
        // })
        // if (response.ok) {
        //     const questionInfo = await response.json()

        //     // add to question data map
        //     const updatedQuestions = {...questionData}
        //     updatedQuestions[questionInfo._id] = questionInfo
        //     setQuestionData(updatedQuestions)

        //     // console.log(updatedQuestions)

        //     // add to question array 
        //     if (questions){
        //         setQuestions([...questions, questionInfo._id])
        //     } else {
        //         setQuestions([questionInfo._id])
        //     }

        //     setCreateQuestionLoading(false)

        // }else{
        //     const message = await response.json();
        //     if (message === "not logged in"){
        //         setStatus("not logged in")
        //         setUserInfo(null)
        //     } else {
        //         setCreateQuestionLoading(false)
        //         console.log("error")
        //     }
        // }
    }

    async function deleteQuestion (index) {

        const uuid = questions[index]

        // remove question from data map
        const updatedQuestions = {...questionData}
        delete updatedQuestions[uuid]
        setQuestionData(updatedQuestions)

        // remove question from array
        const updatedQuestionsArray = [...questions]
        updatedQuestionsArray.splice(index, 1);
        setQuestions(updatedQuestionsArray)
    }

    function toForm(id){
        return (
            <div key={id + "-demo"}>
                <div>{(questionData[id].type !== "section" && questionData[id].type !== "description") ? questionData[id].question : questionData[id].title}</div>
                {questionData[id].type === "description" && <div>{questionData[id].description}</div>}
                {questionData[id].options && questionData[id].options.map((desc, index) => {
                    return (<div key={id + "-demo-option" + index.toString() }>{desc}</div>)
                })}                
            </div>)
    }
  
    return (
        <div className="job-post-page">
            <ToastContainer 
                className="toast-custom-style"
                limit={3}/>
            <div className="job-post-page-top"></div>
            <div className="job-post-page-main">
                <div className="job-post-page-main-inner">
                    {/* <div className="job-post-page-main-header">Application</div> */}
                    <div className="job-post-page-main-main">
                        <div className="job-post-page-left-wrapper">
                            <div className="job-post-page-left">
                                <div className="job-post-page-fixed-section">Basic information</div>
                                <Question title="Name" />
                                <Question title="Email" />
                                <Question title="Phone" sectionState={phone} sectionSetFunction={setPhone} sectionOptions={["Required", "Optional", "Disabled"]}/>
                                <Question title="Address" sectionState={address} sectionSetFunction={setAddress} sectionOptions={["Required", "Optional", "Disabled"]} last={true}/>
                                <div className="job-post-page-fixed-section">Profile</div>
                                <Question title="Education" 
                                    sectionState={education} sectionSetFunction={setEducation} sectionOptions={["Optional", "Disabled"]}/>
                                <Question title="Experience" 
                                    sectionState={experience} sectionSetFunction={setExperience} sectionOptions={["Optional", "Disabled"]}/>       
                                <Question title="Resume" 
                                    sectionState={resume} sectionSetFunction={setResume} sectionOptions={["Required", "Optional", "Disabled"]}/>    
                                <div>
                                    <div>More</div>
                                    <div className="template-dropdown" tabIndex={0} onBlur={() => {setAddNewQuestionDropdown(false)}}>
                                        <div 
                                            className={addNewQuestionDropdown ? "template-dropdown-menu selected-dropdown" : "template-dropdown-menu"}
                                        >
                                            <div className="template-dropdown-item" onClick={() => {createQuestion("text")}}>Question</div>
                                            <div className="template-dropdown-item" onClick={() => {createQuestion("section")}}>Section</div>
                                            <div className="template-dropdown-item" onClick={() => {createQuestion("description")}}>Description</div>
                                        </div>
                                        <div className="templates-page-more-icon-wrapper" onClick={() => {setAddNewQuestionDropdown(true)}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="templates-page-more-icon">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                                            </svg>
                                        </div>
                                    </div>  
                                    <div>More</div>
                                    <div>More</div>
                                    <div>More</div>
                                </div>                           
                                <DragDropContext onDragEnd={handleOnDragEnd}>
                                    <Droppable droppableId="questions">
                                        {(provided) => (
                                        <ul className="dnd-area" {...provided.droppableProps} ref={provided.innerRef}>
                                            {/* using uuid here */}
                                            {questions && questions.map((id, index) => {
                                            return (
                                                <Draggable key={id} draggableId={id} index={index}>
                                                {(provided) => (
                                                    <li ref={provided.innerRef} {...provided.draggableProps}>
                                                        <div className={questionData[id].type === "section" ? "drag-inner section" : "drag-inner"}>
                                                            <div {...provided.dragHandleProps} className="drag-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 9h16.5m-16.5 6.75h16.5" />
                                                                </svg>
                                                            </div>
                                                            <CustomQuestion uuid={id} index={index} deleteQuestion={deleteQuestion}/>
                                                        </div>
                                                    </li>
                                                )}
                                                </Draggable>
                                            );
                                            })}
                                            {provided.placeholder}
                                        </ul>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                                {/* <div onClick={() => createQuestion("text")}>add</div>
                                <div onClick={() => createQuestion("section")}>add section</div>
                                <div onClick={() => createQuestion("description")}>add desc</div> */}
                            </div>
                        </div>
                        <div className="job-post-page-right">
                            <div>First Name<span>*</span></div>
                            <input></input>
                            {/* {middleName !== "Disabled" && 
                                <div>
                                    <div>
                                        <span>Middle Name</span>
                                        {middleName === "Required" ? <span>*</span> : <span>Optional</span>}</div>
                                    <input></input>
                                </div>} */}
                            
                            <div>Last Name<span>*</span></div>
                            <input></input>
                            <div>Email<span>*</span></div>
                            <input></input>
                            {phone !== "Disabled" && 
                                <div>
                                    <div>
                                        <span>Phone</span>
                                        {phone === "Required" ? <span>*</span> : <span>Optional</span>}</div>
                                    <input></input>
                                </div>}
                            {address !== "Disabled" && 
                                <div>
                                    <div>
                                        <span>Address</span>
                                        {address === "Required" ? <span>*</span> : <span>Optional</span>}</div>
                                    <input></input>
                                </div>}
                            {education !== "Disabled" && 
                                <div>
                                    <div>
                                        <span>School Name</span>
                                    </div>
                                    <input></input>
                                    <div>
                                        <span>Start date</span>
                                    </div>
                                    <input></input>
                                    <div>
                                        <span>End date</span>
                                    </div>
                                    <input></input>
                                    <div>
                                        <span>GPA</span>
                                        <span>Optional</span>
                                    </div>
                                    <input></input>
                                </div>}
                            <div className="job-post-custom-demo">
                                {questions && questions.map((id, index) => {
                                    return toForm(id, index)
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}