import {useState, useContext, useEffect} from 'react'
import ScoreBar from './ScoreBar'
import {EditScanContext} from "./Context/EditScanContext"
import {useParams} from "react-router-dom"
import ScanButton from "./Button/ScanButton"
import EditScanSectionButton from './EditScanSectionButton'

export default function ScanResumeBar(){
    const {resume, results, setResults, lastReqTime, setLastReqTime, lastUpdateTime, isUpdateLoading, setIsUpdateLoading} = useContext(EditScanContext)
    const circleWidth = 120
    const radius = 53
    const fontSize = 30
    const strokeWidth = 7
    const {id} = useParams() //access the parameters of the current URL

    // async function handleScanClick() {
    //     setIsUpdateLoading(true)
    //     // await scanResume()
    // }

    function resultTitle(score){
        if (score === 100){
            return "Perfect score!"
        } else if (score >= 70){
            return "Looking good!"
        } else if (score >= 50){
            return "Almost there!"
        } else {
            return "It's a start!"
        }
    }

    return (
        <div className='scan-right-bar-container'>
            <div className='scan-right-bar'>
                <div className="scan-right-bar-title">Scan Results</div>
                <ScoreBar percentage={results && results["overallScore"]}
                    circleWidth={circleWidth} radius={radius} fontSize={fontSize} strokeWidth={strokeWidth}/>
                <div className="scan-right-bar-result-title">{results && resultTitle(results["overallScore"])}</div>
                <div className="scan-right-bar-comment">You scored higher than <span style={{color: "#8075FF", fontWeight: "700"}}>{`${results.stats.overallPercentile}%`}</span> of your peers.</div>
                <ScanButton
                    isLoading={isUpdateLoading}>
                    {/* onClick={handleScanClick} */}
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6" id="scan-right-bar-button-icon">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    Updated
                </ScanButton>
                <div className="scan-right-bar-section-container">
                    <EditScanSectionButton 
                        title={"Hard Skills"}
                        score={results && results["hardSkillScore"]}/>
                    <EditScanSectionButton 
                        title={"Soft Skills"}
                        score={results && results["softSkillScore"]}/>
                    <EditScanSectionButton 
                        title={"Credentials"}
                        score={results && results["credentialsScore"]}/>
                    <EditScanSectionButton 
                        title={"Format & Style"}
                        score={results && results["formatScore"]}/>
                </div>
            </div>
        </div>
    )
}