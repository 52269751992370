import {useEffect, useState, useContext, useRef} from "react";
import { Link } from "react-router-dom";
import {UserContext} from "../Context/UserContext";
import {LeftBarContext} from "../Context/LeftBarContext"
import "./SettingsPage.css"
import Loading from "../Loading"
import DelayButton from '../Button/DelayButton'
import { ToastContainer, toast, Slide } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import LoadConfirm from '../modals/LoadConfirm'

export default function SettingsPage() {
    const [newUsername, setNewUsername] = useState("")
    const [usernameExpand, setUsernameExpand] = useState(false)
    const [usernameLoading, setUsernameLoading] = useState(false)
    const [usernameError, setUsernameError] = useState("")

    const [newEmail, setNewEmail] = useState("")
    const [emailExpand, setEmailExpand] = useState(false)
    const [emailLoading, setEmailLoading] = useState(false)
    const [emailError, setEmailError] = useState("")
    const [hold, setHold] = useState("")

    const [newPassword, setNewPassword] = useState("")
    const [passwordExpand, setPasswordExpand] = useState(false)
    const [passwordLoading, setPasswordLoading] = useState(false)
    const [passwordError, setPasswordError] = useState("")
    const [passwordShow, setPasswordShow] = useState(false)

    const [page,setPage] = useState("Rewards");
    const { status, setStatus, userInfo, setUserInfo} = useContext(UserContext);
    const {setLock, setSelected} = useContext(LeftBarContext)

    const [deleteAccountConfirmMsg, setDeleteAccountConfirmMsg] = useState(false)
    const [deleteAccountLoading, setDeleteAccountLoading] = useState(false)

    
    useEffect(() => {
        setLock(false)
        setSelected("settings")
        getHold()
    }, []);

    const getHold = async () =>{
        const response = await fetch(process.env.REACT_APP_API_URL + '/user', {credentials: 'include',})
        if (response.ok) {
            const jsonResponse = await response.json()

            if (jsonResponse.hold === "confirmation"){
                setHold("confirmation")
            } else {
                setHold("none")
            }

        } else{
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            }
        }        
    }

    async function saveUsername () {

        setUsernameLoading(true)
        setUsernameError("")
        const userRegex = /^[a-zA-Z0-9\s]{6,30}$/
        const usernameFormat = userRegex.test(newUsername) && /\S/.test(newUsername)

        if ( !usernameFormat ){
            setUsernameError("Please enter 6-30 numbers or alphabets")
            const timeout = setTimeout(() => {
                setUsernameLoading(false)
            }, 400);
        
            return () => {
                clearTimeout(timeout);
            };
        }

        const response = await fetch(process.env.REACT_APP_API_URL + '/username', {
            method: 'PUT',
            body: JSON.stringify({username: newUsername}),
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })
        if (response.ok) {
            const newUserInfo = await response.json()
            setUserInfo(newUserInfo)
            setUsernameLoading(false)
            setUsernameExpand(false)
        }else{
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                setUsernameLoading(false)
                setUsernameError(message)
                // setErrorMsg({title: "Oops", desc: message})
            }
        }



    }

    async function expandUsername () {
        setNewUsername("")
        setUsernameError("")
        setUsernameExpand(true)
    }

    async function cancelUsername () {
        setUsernameExpand(false)
    }

    async function ChangeUserName (value) {
        setUsernameError("")
        setNewUsername(value)
    }

    async function savePassword () {

        setPasswordLoading(true)
        setPasswordError("")
        const passRegex = /^[a-zA-Z0-9]{6,15}$/
        const passwordFormat = passRegex.test(newPassword)

        if ( !passwordFormat ){
            setPasswordError("Please enter 6-15 numbers or alphabets")
            const timeout = setTimeout(() => {
                setPasswordLoading(false)
            }, 400);
        
            return () => {
                clearTimeout(timeout);
            };
        }

        const response = await fetch(process.env.REACT_APP_API_URL + '/password', {
            method: 'PUT',
            body: JSON.stringify({password: newPassword}),
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })
        if (response.ok) {
            const newUserInfo = await response.json()
            setUserInfo(newUserInfo)
            setPasswordLoading(false)
            setPasswordExpand(false)
        }else{
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                setPasswordLoading(false)
                setPasswordError(message)
                // setErrorMsg({title: "Oops", desc: message})
            }
        }
    }

    async function expandPassword () {
        setNewPassword("")
        setPasswordError("")
        setPasswordExpand(true)
    }

    async function cancelPassword () {
        setPasswordExpand(false)
    }

    async function ChangePassword (value) {
        setPasswordError("")
        setNewPassword(value)
    }

    async function toddlePassword(){
        if (passwordShow){
            setPasswordShow(false)
        } else {
            setPasswordShow(true)
        }
    }

    async function saveEmail () {

        setEmailLoading(true)
        setEmailError("")
        const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        const emailFormat = emailRegex.test(newEmail) && newEmail.length <= 319

        if ( !emailFormat ){
            setEmailError("Invalid email")
            const timeout = setTimeout(() => {
                setEmailLoading(false)
            }, 400);
        
            return () => {
                clearTimeout(timeout);
            };
        }

        const response = await fetch(process.env.REACT_APP_API_URL + '/email', {
            method: 'PUT',
            body: JSON.stringify({email: newEmail}),
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })
        if (response.ok) {
            const newUserInfo = await response.json()
            setUserInfo(newUserInfo)
            setEmailLoading(false)
            setEmailExpand(false)
            setHold("confirmation")

            toast.info('Verification email sent, please check your inbox', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
                transition: Slide,
            });
        }else{
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                setEmailLoading(false)
                setEmailError(message)
                // setErrorMsg({title: "Oops", desc: message})
            }
        }
    }

    async function expandEmail () {
        setNewEmail("")
        setEmailError("")
        setEmailExpand(true)
    }

    async function cancelEmail () {
        setEmailExpand(false)
    }

    async function ChangeEmail (value) {
        setEmailError("")
        setNewEmail(value)
    }

    async function Resend () {
        const response = await fetch(process.env.REACT_APP_API_URL + '/resend', {
            method: 'POST',
            body: JSON.stringify({email: userInfo.email}),
            headers: {'Content-Type': 'application/json'}
        })

        if (response.ok) {
            toast.info('Verification email sent, please check your inbox', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
                transition: Slide,
            });
        }else{
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else if (message === "No pending verification"){
                setHold("none")
                toast.info('Email already confirmed', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                    transition: Slide,
                });
            } else {
                toast.error(message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                    transition: Slide,
                });
            }
        }
    }

    async function handleAccountDeleteClick(){
        setDeleteAccountConfirmMsg({title: "Reminder", desc: "This action cannot be reversed, are you sure?"})
    }

    async function handleAccountDelete(){
        setDeleteAccountLoading(true)
        // setJobLoading(true)
        const response = await fetch(process.env.REACT_APP_API_URL + '/delete-account', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include' // cookies will be considered credentials
        })
        if (response.ok) {
            const response = await fetch(process.env.REACT_APP_API_URL + '/logout', {
                credentials: 'include',
                method: 'POST',
            })
            if (response.ok) {
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                console.log("logout failed")
                setDeleteAccountLoading(false)
                setDeleteAccountConfirmMsg(null)
            }
        } else {
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                toast.error(message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                    transition: Slide,
                })                
                setDeleteAccountLoading(false)
                setDeleteAccountConfirmMsg(null)
            }
        }
    }

    return (
        <>{ hold ? 
            <div className="settings-wrapper">
                <LoadConfirm confirmMsg={deleteAccountConfirmMsg} setConfirmMsg={setDeleteAccountConfirmMsg} customClick={() => {handleAccountDelete()}} loading={deleteAccountLoading} buttonText="Confirm"/>
                <ToastContainer 
                    className="toast-custom-style"
                    limit={3}/>
                <div className="settings">
                    <div className="settings-title">Account Settings</div>
                    <div className="settings-desc">Change your profile and login details</div>
                    <div className="settings-box">
                        <div className="settings-title-small">Account Settings</div>
                        <div className="settings-desc-small">Change your profile and login details</div>
                        <div className="settings-section">
                            <div className="settings-section-title">Username</div>
                            <div className="settings-section-value-wrapper">
                                <div className="settings-section-value">{userInfo.username}</div>
                                <div className={!usernameExpand ? "settings-section-edit" : "settings-section-edit-disabled"} onClick={() => {!usernameExpand && expandUsername()}}>
                                    Edit
                                </div>
                            </div>

                            <div className={usernameExpand ? "settings-expansion settings-expanded" : "settings-expansion"}>
                                <div className='settings-expansion-inner'>
                                    <div className='settings-expansion-section'>
                                        <div className="settings-expansion-section-title">New username</div>
                                        <input className={ usernameError ? "settings-expansion-section-input settings-input-error" : "settings-expansion-section-input"} 
                                            value={newUsername} 
                                            onChange={(e) => ChangeUserName(e.target.value)}
                                            maxLength="30" ></input>           
                                        <div className={ usernameError ? "settings-input-error-text settings-input-error-text-expanded" : "settings-input-error-text"}>{usernameError}</div>                                     
                                    </div>
                                    <div className="settings-save">
                                        <button className={usernameLoading ? "input-modal-cancel input-modal-cancel-disabled" : "input-modal-cancel "} disabled={usernameLoading} onClick={() => cancelUsername()}>Cancel</button>
                                        {/* <button className="input-modal-button" onClick={() => handleClick()}>{buttonText}</button> */}
                                        <DelayButton buttonClassName="input-modal-button" buttonClassNameLoading="input-modal-button-loading" isLoading={usernameLoading} onClick={() => {saveUsername()}}>Save</DelayButton>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="settings-section">
                            <div className="settings-section-title">Password</div>
                            <div className="settings-section-value-wrapper">
                                <div className="settings-section-value">{"•".repeat(userInfo.password_length ? userInfo.password_length : 6)}</div>
                                <div className={!passwordExpand ? "settings-section-edit" : "settings-section-edit-disabled"} onClick={() => {!passwordExpand && expandPassword()}}>
                                    Edit
                                </div>
                            </div>

                            <div className={passwordExpand ? "settings-expansion settings-expanded" : "settings-expansion"}>
                                <div className='settings-expansion-inner'>
                                    <div className='settings-expansion-section'>
                                        <div className="settings-expansion-section-title">New password</div>
                                        <div className="settings-expansion-password-wrapper">
                                            <input className={ passwordError ? "settings-expansion-section-input settings-expansion-password-input settings-input-error" : "settings-expansion-section-input settings-expansion-password-input"} 
                                                maxLength="15"
                                                value={newPassword} 
                                                type={passwordShow ? "text" : "password"}
                                                onChange={(e) => ChangePassword(e.target.value)} ></input>
                                            <div className="settings-expansion-show-password" onClick={() => {toddlePassword()}}>
                                                {!passwordShow ?
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                                </svg> :
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                                                </svg>}
                                            </div>
                                        </div>           
                                        <div className={ passwordError ? "settings-input-error-text settings-input-error-text-expanded" : "settings-input-error-text"}>{passwordError}</div>                                     
                                    </div>
                                    <div className="settings-save">
                                        <button className={passwordLoading ? "input-modal-cancel input-modal-cancel-disabled" : "input-modal-cancel"} disabled={passwordLoading} onClick={() => cancelPassword()}>Cancel</button>
                                        {/* <button className="input-modal-button" onClick={() => handleClick()}>{buttonText}</button> */}
                                        <DelayButton buttonClassName="input-modal-button" buttonClassNameLoading="input-modal-button-loading" isLoading={passwordLoading} onClick={() => {savePassword()}}>Save</DelayButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="settings-section">
                            <div className="settings-section-title">
                                Email
                                {hold === "confirmation" && <div className="not-verified">Unverified</div>}
                            </div>
                            <div className="settings-section-value-wrapper">
                                <div className={hold === "confirmation" ? "settings-section-value settings-section-value-extra-padding" : "settings-section-value"}>{userInfo.email}</div>
                                <div className="settings-section-edit-verify">
                                    {hold === "confirmation" && <div className="settings-section-edit" onClick={() => {Resend()}}>
                                        Verify
                                    </div>}
                                    <div className={!emailExpand ? "settings-section-edit" : "settings-section-edit-disabled"} onClick={() => {!emailExpand && expandEmail()}}>
                                        Edit
                                    </div>
                                </div>
                            </div>
                            <div className={emailExpand ? "settings-expansion settings-expanded" : "settings-expansion"}>
                                <div className='settings-expansion-inner'>
                                    <div className='settings-expansion-section'>
                                        <div className="settings-expansion-section-title">New email</div>
                                        <input className={ emailError ? "settings-expansion-section-input settings-input-error" : "settings-expansion-section-input"} 
                                            value={newEmail} 
                                            onChange={(e) => ChangeEmail(e.target.value)}
                                            maxLength="319" ></input>           
                                        <div className={ emailError ? "settings-input-error-text settings-input-error-text-expanded" : "settings-input-error-text"}>{emailError}</div>                                     
                                    </div>
                                    <div className="settings-save">
                                        <button className={emailLoading ? "input-modal-cancel input-modal-cancel-disabled" : "input-modal-cancel "} disabled={emailLoading} onClick={() => cancelEmail()}>Cancel</button>
                                        {/* <button className="input-modal-button" onClick={() => handleClick()}>{buttonText}</button> */}
                                        <DelayButton buttonClassName="input-modal-button" buttonClassNameLoading="input-modal-button-loading" isLoading={emailLoading} onClick={() => {saveEmail()}}>Save</DelayButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="settings-section settings-section-last">
                            <div className="settings-section-title">
                                Delete Account
                            </div>
                            <div className="settings-delete-desc">
                                Permanently delete your account and all data associated with it, including membership status and points. This action cannot be reversed.
                            </div>
                            <div className="settings-delete-button" onClick={() => handleAccountDeleteClick()}>Delete Account</div>
                        </div>
                    </div>
                </div>
            </div> 
            : <Loading text="Loading your settings"/>
        }</>

    )

}