import { useContext, useEffect, useState, useRef } from "react"
import { Link, useNavigate} from "react-router-dom"
import { FormContext } from "./Context/FormContext"
import ScoreBar from './ScoreBar'
import SectionButton from './SectionButton'
import SectionDetails from './SectionDetails'



export default function Results(){
    const {setFiles, setJobTitle, jobTitle, setJobDesc, setPage, setResults, results, setTemplateId, setTemplateTitle, setJobId} = useContext(FormContext) //add needed contexts
    // const [score, setScore] = useState(0)
    // const [section, setSection] = useState("")
    const [section, setSection] = useState("")
    const scrollToObjectRef = useRef(null)
    const navigate = useNavigate()

    const circleWidth = 200
    const radius = 80
    const fontSize = 45
    const strokeWidth = 10

    const scrollTo = () => {
        // Scroll to the object smoothly
        scrollToObjectRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    function rescan() {
        setFiles('')
        setTemplateId('')
        setTemplateTitle('')
        setJobTitle('')
        setJobDesc('')
        setJobId('')
        setPage(0)
        setResults(null)
        setSection("")

        navigate(`/scan`)
    }

    function resultTitle(score){
        if (score === 100){
            return "Perfect score!"
        } else if (score >= 70){
            return "Looking good!"
        } else if (score >= 50){
            return "Almost there!"
        } else {
            return "It’s a start!"
        }
    }
    
    return (
        <div className="results-form">
            <div className="results-container">
                <div className="results-summary">
                    <div id="results-top-bar">
                        <a onClick={() => rescan()} id="results-rescan">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-3 -3 30 30" strokeWidth="1.5" stroke="#767676" className="results-rescan-icon">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
                            </svg>
                            <div id="rescan-text">Rescan</div>
                        </a>
                    </div>
                    <div id="results-main">
                        <div id="results-left-bar">
                            <div id="your-resume-score-title">Your Resume Score</div>
                            <ScoreBar percentage={results["overallScore"]} 
                                circleWidth={circleWidth} radius={radius} fontSize={fontSize} strokeWidth={strokeWidth}/>
                            <div id="result-comment">{resultTitle(results["overallScore"])}
                                {results["overallScore"] < 70 ? <span> Aim for a score of <b>70</b> before applying</span> 
                                : <span> You are ready to apply!</span>}</div>
                            <Link to={`/scan/${results.scan_id}`} id="scan-to-create">Edit Resume</Link>
                        </div>
                        <div id="results-right-bar">
                            <SectionButton 
                                title={"Hard Skills"}
                                score={results["hardSkillScore"]}
                                section={section}
                                setSection={setSection}
                                scrollTo={scrollTo}
                            />
                            <SectionButton 
                                title={"Soft Skills"}
                                score={results["softSkillScore"]}
                                section={section}
                                setSection={setSection}
                                scrollTo={scrollTo}
                            />
                            <SectionButton 
                                title={"Credentials"}
                                score={results["credentialsScore"]}
                                section={section}
                                setSection={setSection}
                                scrollTo={scrollTo}
                            />
                            <SectionButton 
                                title={"Format & Style"}
                                score={results["formatScore"]}
                                section={section}
                                setSection={setSection}
                                scrollTo={scrollTo}
                            />
                        </div>
                    </div>
                </div>
                <div id="results-section-detail-title" ref={scrollToObjectRef}>Section Details</div>
                <SectionDetails section={section} results={results} jobTitle={jobTitle}/>
            </div>
        </div>
    )
}