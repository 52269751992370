import { useContext, useEffect, useState, useRef } from "react"
import { useLocation } from 'react-router-dom';
import Form from "../Form.js"
import Results from "../Results.js"
// import { FormContextProvider } from '../Context/FormContext'
import { FormContext } from "../Context/FormContext";
import { UserContext } from "../Context/UserContext";
import {LeftBarContext} from "../Context/LeftBarContext"
import Error from '../modals/Error'
import Loading from "../Loading"

export default function ScannerPage() {
    const {results, setPage, setJobId, setTemplateId, setTemplateTitle, setJobTitle, setJobDesc, setResults, setFiles} = useContext(FormContext)
    const { setLock, setSelected } = useContext(LeftBarContext)
    const { setStatus, setUserInfo} = useContext(UserContext)
    const [errorMsg, setErrorMsg] = useState(false)
    const requestId = useRef(null)
    const [loading, setLoading] = useState(true)
    // const navigate = useNavigate()

    // Use the useLocation hook to get the location object
    const location = useLocation();

    // Parse the query parameters from the search string
    const searchParams = new URLSearchParams(location.search);
    
    // Access the jobId query parameter
    const jobId = searchParams.get('jobId');

    const templateId = searchParams.get('templateId');

    const fetchJob = async () =>{
        setLoading(true)
        const response = await fetch(process.env.REACT_APP_API_URL + '/job/' + jobId, {credentials: 'include',})
        if (response.ok) {
            response.json().then(job => {
                if (jobId === job._id){
                    setJobTitle(job.title)
                    setJobDesc(job.description)
                    setLoading(false)
                } else {
                    console.log("expired request")
                }
            })
        } else {
            const message = await response.json()
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                setErrorMsg({title: "Oops", desc: message})
                setLoading(false)
            }
        }
    }

    const fetchTemplate = async () =>{
        setLoading(true)
        const response = await fetch(process.env.REACT_APP_API_URL + '/resume/' + templateId, {credentials: 'include',})
        if (response.ok) {
            response.json().then(template => {
                if (templateId === template._id){
                    setTemplateId(template._id)
                    setTemplateTitle(template.title)
                    setLoading(false)
                } else {
                    console.log("expired request")
                }
            })
        } else {
            const message = await response.json()
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                setErrorMsg({title: "Oops", desc: message})
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        setLock(false)
        setSelected("scan")

        setJobId(jobId) //from params
        setTemplateId(templateId) //from params
        setFiles('')
        setJobTitle('')
        setJobDesc('')
        setPage(0)
        setResults(null)

        jobId && fetchJob()
        templateId && fetchTemplate()
        !jobId && !templateId && setLoading(false)
    }, [jobId])

    async function handlePrev() {
        setPage(1)
        //cancel request
        requestId.current = null
    }

    return (
        <>
            <Error errorMsg={errorMsg} setErrorMsg={setErrorMsg} customClick={() => {handlePrev()}} />
            {loading ? 
            <Loading text="Preparing scan"/> :
            <div id="scanner-page-container">
                {results? <Results /> : <Form setErrorMsg={setErrorMsg} requestId={requestId}/>}
            </div> }
        </>
    )
}