import { useContext } from 'react'
import { Navigate } from 'react-router-dom';
import { UserContext } from "./Context/UserContext"

// status = pending unless server responses
// display loading while pending (needed when refresh page)
export default function RequireAuth ({children}) {
    const {status} = useContext(UserContext)

    if (status === "pending"){
        return <div></div>
    } else if (status === "not logged in"){
        return <Navigate to='/login' />
    } else {
        return children
    }
}

