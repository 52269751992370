import { useContext } from 'react'
import { FormContext } from "./Context/FormContext"
import SectionDetailsOverview from "./SectionDetailsOverview"
import { v4 as uuidv4 } from 'uuid'

export default function HardSkills({results}){

    return(
        <div>    
            <SectionDetailsOverview results={results} section="Hard Skills"/>
            <div className="section-detail">
                <div className="section-detail-matched">
                    <div className="section-detail-matched-title">
                        Matched Hard Skills
                        <div className='matched-hardskill-info-small'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="section-detail-info-icon">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                            </svg>
                            <div className='tooltip'>The two numbers represent how many times a keyword appeared in your resume verses in the job description</div>
                        </div>
                    </div>
                    <div className="section-detail-matched-container">
                        {results["hardMatch"].length > 0 ? results["hardMatch"].map(result => (
                            <span className='match' key={uuidv4()}><span>{result[0]}&nbsp;<span className='not-bold'>{`(${result[1][0]}/${result[1][1]})`}</span></span></span>
                        )):
                        <div className="empty-message">
                            No matching skills
                        </div>}
                    </div>
                </div>
                <div className="section-detail-missing">
                    <div className="section-detail-missing-title">
                        Missing Hard Skills
                        <div className='missing-hardskill-info-small'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="section-detail-info-icon">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                            </svg>
                            <div className='tooltip'>The number represents how many times a keyword appeared in the job description</div>
                        </div> 
                    </div>
                    <div className="section-detail-missing-container">
                        {results["hardNotMatch"].length > 0 ? results["hardNotMatch"].map(result => (
                            <span className='not-match' key={uuidv4()}><span>{result[0]}&nbsp;<span className='not-bold'>{`(${result[1]})`}</span></span></span>
                        )):
                        <div className="empty-message">
                            No missing skills
                        </div>}
                    </div>
                </div>
            </div>
        </div>       
    )
}