import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import {formatISO9075, roundToNearestMinutes} from "date-fns"
import {Link} from "react-router-dom" 
import SkillglanceIcon from './images/skillglance_icon_grey.svg'
import LoadConfirm from './modals/LoadConfirm'
import Error from './modals/Error'

export default function History({_id, title, job, results, createdAt, updatedAt, dashboard, handleDelete}){
    const [newCompany, setNewCompany] = useState("")
    const [company, setCompany] = useState(job ? job.company : null)
    const [confirmMsg, setConfirmMsg] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState(false)
    
    // Logo now provided by "https://www.vectorlogo.zone/logos/index.html"
    // const [logo, setLogo] = useState(job ? job.logo : null)
    const [logoUrl, setLogoUrl] = useState(SkillglanceIcon)
    const navigate = useNavigate()

    useEffect(() => {
        if (job) {
            if (!job.logo){
                setLogoUrl(SkillglanceIcon)
            } else {
                fetch(job.logo).then( async response => {
                    if (response.ok) {
                        const blob = await response.blob();
                        const objectUrl = URL.createObjectURL(blob);
                        setLogoUrl(objectUrl);
                    }else{
                        setLogoUrl(SkillglanceIcon)
                    }
                })
            }
        }
    }, [])

    function DaysAgo(date) {
        const createdAtDate = new Date(date)
        const currentDate = new Date()

        // Calculate the time difference in milliseconds
        const timeDifference = currentDate - createdAtDate;
      
        // Calculate the number of days
        const daysAgo = Math.max(0, Math.floor(timeDifference / (1000 * 60 * 60 * 24)))

        if (daysAgo === 1){
            return "1 day ago"
        } else if (daysAgo < 100){
            return daysAgo + " days ago"
        } else {
            return "99+ days ago"
        }
    }

    // async function editCompany(ev) {
    //     ev.preventDefault()
    //     const jobId = job._id
    //     console.log(newCompany)
    //     const response = await fetch(process.env.REACT_APP_API_URL + '/job', {
    //         method: 'PUT',
    //         body: JSON.stringify({jobId: jobId, company: newCompany}),
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //         credentials: 'include'
    //     })
    //     if (response.ok) {
    //         const jobInfo = await response.json()
    //         setCompany(jobInfo.company)
    //         setLogo(jobInfo.logo)
    //     }
    // }

    return (
        <>
            <LoadConfirm confirmMsg={confirmMsg} setConfirmMsg={setConfirmMsg} customClick={() => {handleDelete(_id, setConfirmMsg, setDeleteLoading, setErrorMsg)}} loading={deleteLoading} buttonText="Confirm"/>
            <Error errorMsg={errorMsg} setErrorMsg={setErrorMsg} />
            <>
                {!dashboard ? <div id="history">
                    <Link className='history-invisible-button' to={job ? `/scan/${_id}` : ""}></Link>
                    <div id="historyWrapper">
                        <div id="history-logo-and-score">
                            <div id="history-logo-wrapper">
                                {/* check if link empty then if it works */}
                                {/* <img src={logo ? `${process.env.REACT_APP_API_URL}${logo.address}` 
                                    : SkillglanceIcon} 
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = SkillglanceIcon;
                                    }}
                                    alt="Company Logo" id="history-company-logo"/> */}
                                    
                                <img src={logoUrl} alt="Company Logo" id="history-company-logo"/>
                            </div>
                            <div id="history-score">
                                {
                                    results ? results.overallScore : null
                                }
                            </div>
                        </div>
                        <div id="history-text">
                            <div id="history-text-top">
                                <div id="history-text-top-left">
                                    <div id="history-title">
                                        {job ? job.title : <div className="skeleton skeleton-text last-line"></div>}
                                    </div>
                                    <div id="history-company">
                                        <div id="history-company-name">
                                            {company ? company : <div className="skeleton skeleton-text" id="history-company-skeleton"></div>}
                                        </div>
                                    </div>
                                    <div className="icons-bar">
                                        <div className="history-icon-wrapper">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="history-icon">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                            <div id="history-updated">
                                                {updatedAt ? DaysAgo(updatedAt)
                                                : <div className="skeleton skeleton-text"></div>}
                                            </div>
                                        </div>
                                        {results && results.overallScore > 70 &&
                                        <div className="history-icon-wrapper">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="history-icon">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                            <div className='application-ready'>Application ready</div>
                                        </div>}
                                    </div>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className='mobile-delete' onClick={() => job && setConfirmMsg({title: "Reminder", desc: "This action cannot be reversed, are you sure?"})}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                </svg>
                            </div>
                            <div id="history-description">
                                {job ? job.description : 
                                    <>
                                        <div className="skeleton skeleton-text"></div>
                                        <div className="skeleton skeleton-text"></div>
                                        <div className="skeleton skeleton-text"></div>
                                        <div className="skeleton skeleton-text"></div>
                                        <div className="skeleton skeleton-text"></div>
                                        <div className="skeleton skeleton-text last-line"></div>
                                    </>
                                }
                            </div>
                        </div>
                        <div className='history-buttons'>
                            <Link className='history-button edit' to={job ? `/scan/${_id}` : ""}>Edit</Link>
                            <Link className='history-button view' to={job ? `/scan/${_id}` : ""}>View</Link>
                            <div className='history-button delete' 
                                onClick={() => job && setConfirmMsg({title: "Reminder", desc: "This action cannot be reversed, are you sure?"})}>Delete</div>
                        </div>
                    </div>
                </div>        
                :
                <Link id="dashboard-history" to={job ? `/scan/${_id}` : ""}>
                    <div id="dashboard-historyWrapper">
                        <div id="history-logo-and-score">
                            <div id="dashboard-history-logo-wrapper">                               
                                <img src={logoUrl} alt="Company Logo" id="dashboard-history-company-logo"/>
                            </div>
                            <div id="dashboard-history-score">
                                {
                                    results ? Math.round((results.hardSkillScore + results.softSkillScore 
                                        + results.credentialsScore + results.formatScore) / 4) : null
                                }
                            </div>
                        </div>
                        <div id="history-text">
                            <div id="history-text-top">
                                <div id="dashboard-history-text-top-left">
                                    <div id="dashboard-history-title">
                                        {job ? job.title : <div className="skeleton skeleton-text last-line"></div>}
                                    </div>
                                    <div id="history-company">
                                        <div id="history-company-name">
                                            {company ? company : <div className="skeleton skeleton-text" id="history-company-skeleton"></div>}
                                        </div>
                                        {/* <form id="history-company-edit" onSubmit={editCompany}>
                                            <input type="text" 
                                                placeholder="new company name"
                                                value={newCompany} 
                                                onChange={ev => setNewCompany(ev.target.value)}/>
                                            <button>submit</button>
                                        </form> */}
                                    </div>
                                </div>
                                <div id="dashboard-history-updated">
                                    {updatedAt ? DaysAgo(updatedAt)
                                    : <div className="skeleton skeleton-text"></div>}
                                </div>
                            </div>
                            <div id="history-description">
                                {job ? job.description.substring(0, 150) : 
                                    <>
                                        <div className="skeleton skeleton-text"></div>
                                        <div className="skeleton skeleton-text last-line"></div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </Link>}                                 
            </>
        </>
    )
}