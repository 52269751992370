import { useContext, useState, useEffect } from "react"
import {ApplicationContext} from "./Context/ApplicationContext"
import ApplicationQuestion from "./ApplicationQuestion.js"

//input error in handled outside of this object
export default function ApplicationCustomSection({id, error}){
    const {questionData, answers, setAnswers} = useContext(ApplicationContext)
    const currentQuestionData = questionData[id]
    const [answer, setAnswer] = useState("")

    async function handleAnswerChange (newAnswer) {
        setAnswer(newAnswer)
        const answersCopy = { ...answers }
        answersCopy[id] = newAnswer
        setAnswers(answersCopy)
    }

   

    // useEffect(() => {
    //     const errorsCopy = { ...errors }
    //     if (error){
    //         errorsCopy[id] = true            
    //     } else {
    //         errorsCopy[id] = false
    //     }
    //     setErrors(errorsCopy)
    // }, [error])


    return(
        currentQuestionData && <div>
            {currentQuestionData.type === "section" 
            && <div className="apply-page-section-title">{currentQuestionData.title}</div>}
            {currentQuestionData.type === "description" 
            && <div className="apply-page-desc">
                <div className="apply-page-desc-title">{currentQuestionData.title}</div>
                <div className="apply-page-desc-desc">{currentQuestionData.description}</div>
            </div>}
            {(currentQuestionData.type !== "section" && currentQuestionData.type !== "description")
            && <ApplicationQuestion 
                type={currentQuestionData.type} 
                state={currentQuestionData.state} 
                question={currentQuestionData.title} 
                validation={currentQuestionData.validation} 
                options={currentQuestionData.options} 
                maxAnswers={currentQuestionData.maxAnswers}
                answer={answer} 
                setAnswer={handleAnswerChange}/>}
        </div>
    ) 
}