import { useContext, useEffect } from 'react'
import { UserContext } from "./Context/UserContext"

// Authenticates when whole page is reloaded
export default function Authenticate () {
    const {setUserInfo, setStatus} = useContext(UserContext);
    useEffect(() => {
        console.log("checking status...")
        fetch(process.env.REACT_APP_API_URL + '/profile', {
            credentials: 'include',
        }).then(response => {
            if (response.ok) {
                response.json().then(userInfo => {
                    console.log("Logged in.")
                    setStatus("logged in")
                    setUserInfo(userInfo)
                })
            }else{
                console.log("Not logged in.")
                setStatus("not logged in")
                setUserInfo(null)
            }
        })
    }, [])

    return ( null )
}