import { useState, useEffect} from 'react'
import { Link } from 'react-router-dom'

export default function StatBox({icon, used, limit, statDesc, linkTo, tooltip}){
    const [progressValue, setProgressValue] = useState(0)
    const [percentUsed, setPercentUsed] = useState(0)

    useEffect(() => {
        setPercentUsed(Math.min(100, Math.round(used * 100 / limit)))
    }, [])

    useEffect(() => {
        if (progressValue < percentUsed){
            setTimeout(() => {
                setProgressValue(progressValue + 1)
            }, 3)
        } else if (progressValue > percentUsed){
            setTimeout(() => {
                setProgressValue(progressValue - 1)
            }, 3)
        }
    }, [progressValue, percentUsed])

    function noClick(ev){
        ev.stopPropagation();
        ev.preventDefault();
        return
    }

    return (
        <Link className='stat-box' to={linkTo}>
            {icon === "newScans" && 
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="stat-icon">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
                </svg>}
            {icon === "histories" && 
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="stat-icon">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>}
            {icon === "resumes" && 
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="stat-icon">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                </svg>}
            {icon === "jobs" && 
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="stat-icon">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
                </svg>}

            <div className='stat-number'>
                <span className='stat-used'>{used}</span>
                <span className='stat-limit'>
                    <span>{` /${limit}`}</span>
                    {icon === "newScans" && <span className='missing-hardskill-info-small' onClick={(ev) => {noClick(ev)}}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="stat-box-info-icon">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                        </svg>
                        <div className='tooltip'>{tooltip}</div>
                    </span>}
                </span>

            </div>
            <div className='stat-desc'>{statDesc}</div>
            <div className="bar">
                <div style={{ width: `${progressValue}%` }}></div>
            </div>
        </Link>
    )
}