import Modal from './Modal'
import './Transactions.css'
import {useEffect, useState, useContext} from "react";
import LoadMoreButton from '../Button/DelayButton'
import {UserContext} from "../Context/UserContext"

export default function Transactions({transactionsOpen, setTransactionsOpen}){
    const {setStatus, setUserInfo} = useContext(UserContext)
    const [transactions,setTransactions] = useState([])
    const [headId, setHeadId] = useState(null) 
    const amount = 5 // numbers of transactions to request at a time
    const [reachEnd,setReachEnd] = useState(false)
    const [loading,setLoading] = useState(false)
    const [noTrans,setNoTrans] = useState(false)
    const [initialLoading,setInitialLoading] = useState(true)

    useEffect(()  => {
        if (!headId){
            // must make sure everything is reset before requesting again
            setReachEnd(false)
            setLoading(false)
            setInitialLoading(true)
        }
        //only fetch when opened
        transactionsOpen && fetchTransactionsDelay()

    }, [headId, transactionsOpen]);

    //only place to set noTrans
    useEffect(() => {
        //check if no files
        if (!transactions.length){
            setNoTrans(true)
        } else {
            setNoTrans(false)
        }
    }, [transactions]);

    //it's too fast, which pushes the loading down fast
    const fetchTransactionsDelay = async () => {
        setLoading(true)
        const timeout = setTimeout(() => {
            fetchTransactions()
        }, 400)
    }

    const fetchTransactions = async () =>{
        setLoading(true)
        const response = await fetch(process.env.REACT_APP_API_URL + '/get-transactions', {
            method: 'POST',
            body: JSON.stringify({headId: headId, amount}),
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
        })
        if (response.ok) {
            const jsonResponse = await response.json()
            // make sure the returned response is not empty and is the one we requested
            if (!jsonResponse.headId && !headId || (jsonResponse.headId && headId && (jsonResponse.headId === headId))){
                if (jsonResponse.transactions.length) {
                    // headId.current = jsonResponse.transactions[jsonResponse.transactions.length - 1]["_id"]
                    
                    jsonResponse.transactions.forEach(transaction => {
                        const utcTime = new Date(transaction.createdAt);
                        const localDate = utcTime.toLocaleDateString();
                        transaction.createdAt = localDate
                    })

                    if (!jsonResponse.headId) {
                        setTransactions([...jsonResponse.transactions])
                    } else {
                        setTransactions([...transactions, ...jsonResponse.transactions])
                    }

                }
                if (jsonResponse.transactions.length < amount){
                    setReachEnd(true)
                }
                // first request returns nothing means it's empty
                if (!jsonResponse.headId && !jsonResponse.transactions.length){
                    setTransactions([])
                }
                setLoading(false)
                setInitialLoading(false)
            }

        } else{
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                // will not be triggered
                // setLoading(false)
                // setInitialLoading(false)

                // //if error reload
                // setHeadId(null)
            }
        }
    }

    const loadMore = () =>{
        transactions ? setHeadId(transactions[transactions.length - 1]._id) : setHeadId(null)
    }

    function handleClose() {
        setTransactions([])
        setHeadId(null)
        setReachEnd(false)
        setLoading(false)
        setNoTrans(false)

        setTransactionsOpen(false)
    }

    function getTitle(transaction){
        if (transaction.type === "offer" ){
            return transaction.offer.title
        } else if (transaction.type === "reward"){
            return `${transaction.reward.title} Premium`
        } else if (transaction.type === "code"){
            if (transaction.code.premium_months){
                return `${transaction.code.premium_months} ${transaction.code.premium_months > 1 ? "Months" : "Month"} Premium` 
            } else {
                return transaction.code.code
            }
        } else {
            return "unkown type"
        }
    }

    function getDesc(transaction){
        if (transaction.type === "offer"){
            return transaction.offer.short_desc
        } else if (transaction.type === "code" && transaction.code.premium_months){
            return `Redeemed code ${transaction.code.code}`
        } else {
            return null
        }        
    }

    return (
        <Modal open={transactionsOpen} onClose={() => handleClose()}>
            <div className="transactions-inner-wrapper">
                <div className="title">Transaction History</div>
                <div className="main">
                    <div className="mask"></div>
                    <div className="bottom-mask"></div>
                    <div className={initialLoading ? "main-scroll no-scroll" : "main-scroll"}>
                        {initialLoading && 
                            <>
                                <div className="transaction">  
                                    <div className="transaction-type-skeleton">
                                        <div className="skeleton skeleton-text"></div>
                                    </div>
                                    <div className="transaction-main">
                                        <div className="left">
                                            <div className="transaction-title">
                                                <div className="skeleton skeleton-text"></div>
                                            </div>
                                            <div className="short-desc">
                                                <div className="skeleton skeleton-text"></div>
                                                <div className="skeleton skeleton-text last-line"></div>
                                            </div>
                                        </div>
                                        <div className="right">
                                            <div className='points-wrapper-earn' >
                                                <div className="skeleton skeleton-text last-line"></div>
                                            </div>
                                            <div className="time">
                                                <div className="skeleton skeleton-text last-line"></div>
                                            </div>      
                                            <div className="status">
                                                <div className="skeleton skeleton-text last-line"></div>
                                            </div>                                    
                                        </div>
                                    </div>
                                </div>
                                <div className="transaction">  
                                    <div className="transaction-type-skeleton">
                                        <div className="skeleton skeleton-text"></div>
                                    </div>
                                    <div className="transaction-main">
                                        <div className="left">
                                            <div className="transaction-title">
                                                <div className="skeleton skeleton-text"></div>
                                            </div>
                                            <div className="short-desc">
                                                <div className="skeleton skeleton-text"></div>
                                                <div className="skeleton skeleton-text last-line"></div>
                                            </div>
                                        </div>
                                        <div className="right">
                                            <div className='points-wrapper-earn' >
                                                <div className="skeleton skeleton-text last-line"></div>
                                            </div>
                                            <div className="time">
                                                <div className="skeleton skeleton-text last-line"></div>
                                            </div>      
                                            <div className="status">
                                                <div className="skeleton skeleton-text last-line"></div>
                                            </div>                                    
                                        </div>
                                    </div>
                                </div>
                                <div className="transaction">  
                                    <div className="transaction-type-skeleton">
                                        <div className="skeleton skeleton-text"></div>
                                    </div>
                                    <div className="transaction-main">
                                        <div className="left">
                                            <div className="transaction-title">
                                                <div className="skeleton skeleton-text"></div>
                                            </div>
                                            <div className="short-desc">
                                                <div className="skeleton skeleton-text"></div>
                                                <div className="skeleton skeleton-text last-line"></div>
                                            </div>
                                        </div>
                                        <div className="right">
                                            <div className='points-wrapper-earn' >
                                                <div className="skeleton skeleton-text last-line"></div>
                                            </div>
                                            <div className="time">
                                                <div className="skeleton skeleton-text last-line"></div>
                                            </div>      
                                            <div className="status">
                                                <div className="skeleton skeleton-text last-line"></div>
                                            </div>                                    
                                        </div>
                                    </div>
                                </div>
                            </>                        
                        }
                        {!initialLoading && transactions.length > 0 && transactions.map(transaction => (
                            <div className="transaction" key={transaction._id}>  
                                <div className="transaction-type">{transaction.type}</div>
                                <div className="transaction-main">
                                    <div className="left">
                                        <div className="transaction-title">{getTitle(transaction)}</div>
                                        <div className="short-desc">{getDesc(transaction)}</div>
                                    </div>
                                    <div className="right">
                                        <div className={ (transaction.status === "Confirmed") ? 'points-wrapper-earn' :'points-wrapper-loss'} >
                                            {transaction.change < 0 && "-"}
                                            <svg width="40" height="66" viewBox="0 0 40 66" fill="none" xmlns="http://www.w3.org/2000/svg" className="transaction-desc-icon">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M20.0143 0.0212326C20.3893 13.4342 28.1322 25.3282 39.9998 33C28.1321 40.6718 20.3891 52.5658 20.0141 65.9788L19.9977 66L19.9859 65.9847C19.613 52.5693 11.8695 40.6729 0 33H0.000399714C11.8698 25.3271 19.6132 13.4306 19.9861 0.0152588L19.9979 0L20.0143 0.0212326Z" fill="currentColor"/>
                                            </svg>
                                            <div>
                                                { transaction.change > 0 ? transaction.change : -transaction.change}
                                            </div>
                                        </div>
                                        <div className="time">{transaction.createdAt}</div>      
                                        <div className="status">{transaction.status}</div>                                    
                                    </div>
                                </div>
                            </div>
                        ))}
                        {!noTrans && reachEnd && <div className="no-more">You've reached the end</div>}
                        {noTrans && !initialLoading && reachEnd && <div className="empty">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="empty-icon">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                            </svg>
                            No transactions
                        </div>}
                        {!reachEnd && !initialLoading && <LoadMoreButton buttonClassName="load-more-button" buttonClassNameLoading="load-more-button-loading" isLoading={loading} onClick={() => {loadMore()}}>Load More</LoadMoreButton>}
                    </div>
                </div>
                <div className="modal-button-container">
                    <button className="input-modal-button" onClick={() => handleClose()}>Done</button>
                </div>
            </div>
        </Modal>
    )
}