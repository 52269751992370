import History from "../History"
import {useEffect, useState, useContext, useRef} from "react";
import { Navigate, Link} from "react-router-dom";
import {UserContext} from "../Context/UserContext";
import {LeftBarContext} from "../Context/LeftBarContext"
import { v4 as uuidv4 } from 'uuid';
import "./ScanHistoryPage.css"

//do NOT useState for search it will have multple different values 
//across different renders
//the right way is useRef
export default function ScanHistoryPage() {
    const [histories,setHistories] = useState([]);
    const {setStatus, userInfo, setUserInfo} = useContext(UserContext);
    const {setLock, setSelected} = useContext(LeftBarContext)
    // const [confirmMsg, setConfirmMsg] = useState(false)
    const search = useRef("")

    useEffect(() => {
        setLock(false)
        setSelected("scan")
        fetchHistory()
    }, []);


    const fetchHistory = async () =>{
        tempHistory()
        const response = await fetch(process.env.REACT_APP_API_URL + '/history', {
            method: 'POST',
            body: JSON.stringify({search: search.current}),
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
        })
        if (response.ok) {
            const historiesDoc = await response.json();
            // console.log(historiesDoc.search)
            // console.log(search.current)
            if (historiesDoc.search === search.current){
                setHistories(historiesDoc.histories)
            } else {
                console.log("expired request")
            }
        } else {
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            }
        }
    }

    //setConfirmMsg cannot be shared and must be from the job itself
    async function handleDelete(_id, setConfirmMsg, setDeleteLoading, setErrorMsg) {
        setDeleteLoading(true)
        const response = await fetch(process.env.REACT_APP_API_URL + '/delete-scan', {
            method: 'POST',
            body: JSON.stringify({ "scan_id": _id }),
            headers: {'Content-Type': 'application/json'},
            credentials: 'include' // cookies will be considered credentials
        });
        if (response.ok) {
            // tempHistory()
            await fetchHistory()
            setDeleteLoading(false)
            setConfirmMsg(null)
        } else {
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                setErrorMsg({title: "Oops", desc: message})
                setDeleteLoading(false)
                setConfirmMsg(null)
            }
        }
    }

    async function tempHistory() {
        const newHistories = [];
        for (let i = 0; i < 2; i++) {
            // Not having unique id will cause react to not remove some components
            const skelotonHistory = {_id: uuidv4(), title: null, job: null, results: null, createdAt: null, updatedAt: null};
            newHistories.push(skelotonHistory);
        }
        setHistories(newHistories)
    }

    return (
        <div className="history-page-wrapper">
            <div className="top-bar-top">
                <div className="top-bar-top-left">
                    <div className="top-bar-search">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="top-bar-search-icon">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                        </svg>
                        <input 
                            className="top-bar-search-input"
                            type="text" 
                            placeholder={"Search scan"}
                            value={search.current} 
                            maxLength="100"
                            onChange={(e) => {
                                search.current = e.target.value
                                fetchHistory()
                            }}/>  
                    </div>
                </div>
                <div className="top-bar-top-right">
                    <Link className="top-bar-new-job" to={"/scan"}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="add-event-plus">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                        New scan
                    </Link> 
                    <Link className='job-page-account-circle' to={"/membership"}>
                        <div className='job-page-account-text'>{userInfo.username[0].toUpperCase()}</div>
                    </Link>
                </div>                     
            </div>
            <div className="history-area-wrapper">
                {histories.length > 0 ? 
                <div className="history-area-inner-wrapper">
                    {histories.map(history => (
                        <History {...history} key={history._id} handleDelete={handleDelete}/>
                    ))}
                </div>: 
                <div className="empty">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="empty-icon">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                    </svg>
                    No scans
                </div>}                
            </div>
        </div>
    )

}