// import { useContext } from "react";
// import { DialogContext } from "./Context/DialogContext";
import SuccessImg from '../images/success.svg';
import Modal from './Modal'
import './Modal.css'

export default function Success({successMsg, setSuccessMsg, customClick}){

    function handleClose() {
        // will call fetch offer and remove this modal immediately
        customClick && customClick()
        setSuccessMsg(null)
    }

    return (
        <Modal open={successMsg} onClose={() => handleClose()}>
            {successMsg &&
                <div className="error-modal-inner-wrapper">
                    <img src={SuccessImg} alt="success image" className="error-modal-image"/>
                    <div className="error-modal-title">{successMsg.title}</div>
                    <div className="error-modal-text">{successMsg.desc}</div>
                    <button className="error-modal-button success-modal-button" onClick={() => handleClose()}>Okay</button>
                </div>
            }
        </Modal>
    )
}