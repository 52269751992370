import Offer from "../Offer";
import Reward from "../Reward";
import {useEffect, useState, useContext, useRef} from "react";
import { Link } from "react-router-dom";
import {UserContext} from "../Context/UserContext";
import {LeftBarContext} from "../Context/LeftBarContext"
import "./Membership.css"
import PremiumLogo from '../images/premium_logo_white.svg';
import Coin3D from '../images/coin_3d.svg';
import StandardCoin from '../images/standard_coin.svg';
import PremiumCoin from '../images/premium_coin.svg';
import Transactions from '../modals/Transactions'
import Loading from "../Loading"

export default function MyTemplatesPage() {
    const [offers,setOffers] = useState([]);
    const [rewards, setRewards] = useState([]);
    const [progressValue, setProgressValue] = useState(0)
    const [membership, setMembership] = useState("")
    const [membershipExpire, setMembershipExpire] = useState(null)
    const [page,setPage] = useState("Rewards");
    const [userPoints, setUserPoints] = useState(null)
    const { status, setStatus, userInfo, setUserInfo} = useContext(UserContext);
    const {setLock, setSelected} = useContext(LeftBarContext)
    const [transactionsOpen, setTransactionsOpen] = useState(null)
    const scrollToObjectRef = useRef(null)
    
    useEffect(() => {
        setLock(false)
        setSelected("membership")
        getPremiumExp()
        getPoints()
        fetchOffer()
        fetchReward()

    }, []);

    useEffect(() => {
        if (progressValue < userPoints){
            setTimeout(() => {
                setProgressValue(progressValue + 1)
            }, 3)
        }
        if (progressValue > userPoints){
            setTimeout(() => {
                setProgressValue(progressValue - 1)
            }, 3)
        }
    }, [progressValue, userPoints])

    //scroll to rewards
    const scrollTo = () => {
        setPage("Rewards")
        // Scroll to the object smoothly
        scrollToObjectRef.current?.scrollIntoView({ behavior: 'smooth' });
    }

    const getPremiumExp = async () =>{
        const response = await fetch(process.env.REACT_APP_API_URL + '/user', {credentials: 'include',})
        if (response.ok) {
            const jsonResponse = await response.json()

            if (new Date(jsonResponse.premium_expires) >= new Date()) {
                setMembership("Premium Member")
                setMembershipExpire(jsonResponse.premium_expires)
                
            } else {
                setMembership("Standard Member")
                setMembershipExpire(null)
            }

        } else{
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            }
        }        
    }

    // fetch and json is async
    const getPoints = async () =>{
        const response = await fetch(process.env.REACT_APP_API_URL + '/get-points', {credentials: 'include',})
        if (response.ok) {
            response.json().then(points => {
                setUserPoints(points)
            })
        } else{
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            }
        }
    }

    // fetch and json is async
    const fetchOffer = async () =>{
        const response = await fetch(process.env.REACT_APP_API_URL + '/get-offers', {credentials: 'include',})
        if (response.ok) {
            response.json().then(offers => {
                setOffers(offers)
            })
        } else{
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            }
        }
    }

    // fetch and json is async
    const fetchReward = async () =>{
        const response = await fetch(process.env.REACT_APP_API_URL + '/get-rewards', {credentials: 'include',})
        if (response.ok) {
            response.json().then(rewards => {
                setRewards(rewards)
            })
        } else{
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            }
        }
    }

    // what to display for points
    const pointDisplay = (points) =>{
        if (points < 1000){
            return points
        } else if (points < 10000){
            const formattedPoints = (points / 1000).toFixed(1);
            return `${formattedPoints}k`;
        } else if (points < 100000){
            const formattedPoints = Math.floor(points / 1000);
            return `${formattedPoints}k`;
        } else {
            return "99k+"
        }
    }

    return (
        <>{ membership && (userPoints !== null) ? 
            <div>
                {transactionsOpen && <Transactions transactionsOpen={transactionsOpen} setTransactionsOpen={setTransactionsOpen}/>}
                <div className="membership-banner">
                    <div className="membership-banner-text-wrapper">
                        <div className="membership-banner-text">GET
                            <svg width="40" height="66" viewBox="0 0 40 66" fill="none" xmlns="http://www.w3.org/2000/svg" className="membership-banner-star">
                                <path fillRule="evenodd" clipRule="evenodd" d="M20.0143 0.0212326C20.3893 13.4342 28.1322 25.3282 39.9998 33C28.1321 40.6718 20.3891 52.5658 20.0141 65.9788L19.9977 66L19.9859 65.9847C19.613 52.5693 11.8695 40.6729 0 33H0.000399714C11.8698 25.3271 19.6132 13.4306 19.9861 0.0152588L19.9979 0L20.0143 0.0212326Z" fill="white"/>
                            </svg>
                        </div>
                        <div className="membership-banner-text">PREMIUM</div>
                        <div className="membership-banner-text">FOR&nbsp;&nbsp;FREE</div>
                    </div>
                    <div className="membership-banner-image">
                        <div className="membership-banner-card-top">
                            <img src={PremiumLogo} alt="Company Logo" className="membership-company-logo"/>
                        </div>
                        <div className="membership-banner-card-bottom">
                            <img src={PremiumLogo} alt="Company Logo" className="membership-company-logo"/>
                        </div>
                        <img src={Coin3D} alt="Coin image" className="membership-coin"/>
                    </div>
                </div>

                <div className="membership-form">
                    <div className="membership-points-form">
                        <div className="membership-points-info">
                            <div className="membership-points-top">
                                <div className="membership-points-top-left">
                                    {/* {membership === "Premium Member" ? <img src={PremiumCoin} alt="Premium Member" className="skeleton membership-standard-member-icon"/> :
                                    <img src={StandardCoin} alt="Standard Member" className="skeleton membership-standard-member-icon"/>} */}

                                    <div className={`dashboard-membership-icon ${membership === "Premium Member" && "premium"}`} to="/membership"> 
                                        {userInfo.username[0].toUpperCase()}</div>
                                    
                                    <div className="membership-member">
                                        <div className="membership-member-name">{userInfo.username}</div>
                                        <div className="membership-member-status">{membership}</div>
                                        {membership === "Premium Member" && <div className="membership-premium-expire">until {new Date(membershipExpire).toLocaleDateString('en-US', {
                                            year: '2-digit',
                                            month: '2-digit',
                                            day: '2-digit',
                                            })}
                                        </div>}
                                    </div>
                                </div>
                                <div className="membership-points-count">
                                    <div className="membership-points-count-number">
                                        <svg width="40" height="66" viewBox="0 0 40 66" fill="none" xmlns="http://www.w3.org/2000/svg" className="membership-points-count-star">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M20.0143 0.0212326C20.3893 13.4342 28.1322 25.3282 39.9998 33C28.1321 40.6718 20.3891 52.5658 20.0141 65.9788L19.9977 66L19.9859 65.9847C19.613 52.5693 11.8695 40.6729 0 33H0.000399714C11.8698 25.3271 19.6132 13.4306 19.9861 0.0152588L19.9979 0L20.0143 0.0212326Z" fill="currentColor"/>
                                        </svg>
                                        {`${pointDisplay(userPoints)}`}</div>
                                    <div className="membership-points-count-text">points</div>
                                    <div className="history-button"
                                        onClick={() => {setTransactionsOpen(true)}}>View history</div>
                                </div>
                            </div>
                            <div className="membership-points-bottom">
                                <div className="membership-points-target">{
                                    userPoints >= 100 ? <span onClick={() => {scrollTo()}} style={{cursor: "pointer"}}>Get Premium &gt;</span> : <span>100 points for Premium</span>
                                }</div>
                                <div className="membership-points-bar">
                                    <div className={membership === "Premium Member" && "premium"} style={{ width: `${progressValue > 100 ? 100 : progressValue}%` }}></div>
                                </div>
                            </div>
                        </div>
                        <div className="membership-points-benefits">
                            <div className="membership-points-benefits-title">
                                <div className="benefits-title-left">My Benefits</div>
                                <Link className="benefits-title-right" to="/benefits">Learn more</Link>
                            </div>
                            <div className="items-container">
                                <div className="membership-points-benefits-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="benefits-icon">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                    </svg>
                                    <div className="membership-points-benefits-text">{`${membership === "Premium Member" ? "100" : "5"} new scans per week`}</div>
                                </div>
                                <div className="membership-points-benefits-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="benefits-icon">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                    </svg>
                                    <div className="membership-points-benefits-text">Unlimited rescans in editor</div>
                                </div>
                                <div className="membership-points-benefits-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="benefits-icon">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    <div className="membership-points-benefits-text">{`${membership === "Premium Member" ? "500" : "20"} scan histories`}</div>
                                </div>
                                <div className="membership-points-benefits-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="benefits-icon">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
                                    </svg>
                                    <div className="membership-points-benefits-text">{`${membership === "Premium Member" ? "200" : "5"} resume storage space`}</div>
                                </div>
                                <div className="membership-points-benefits-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="benefits-icon">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
                                    </svg>
                                    <div className="membership-points-benefits-text">{`Track ${membership === "Premium Member" ? "2000" : "100"} job applications`}</div>
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div className="membership-right-container" ref={scrollToObjectRef}>
                        <div className="membership-page-bar">
                            <button className={page === "Rewards" ? "membership-page-button membership-page-selected" : "membership-page-button"} 
                                onClick={() => setPage("Rewards")}>Rewards</button>
                            <button className={page === "Offers" ? "membership-page-button membership-page-selected" : "membership-page-button"} 
                                onClick={() => setPage("Offers")}>Offers</button>
                        </div>
                        {page === "Rewards" &&
                            <div className="rewards-container">
                                <div className="rewards-inner-container">
                                {rewards.length > 0 && rewards.map(reward => (
                                    <Reward {...reward} key={reward._id} getPoints={getPoints} fetchReward={fetchReward} getPremiumExp={getPremiumExp}/>
                                ))}
                                </div>
                            </div>
                        }
                        {page === "Offers" &&
                            <div className="offers-container">
                                <div className="offers-inner-container">
                                    {offers.length > 0 && offers.map(offer => (
                                        <Offer {...offer} key={offer._id} getPoints={getPoints} fetchOffer={fetchOffer}/>
                                    ))}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div> 
            : <Loading text="Loading membership status"/>
        }</>

    )

}