import { useState, useEffect, useContext } from 'react'
import {EditJobPostContext} from "./Context/EditJobPostContext"
import {UserContext} from "./Context/UserContext"
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import CustomQuestionOption from "./CustomQuestionOption"
import TextareaAutosize from 'react-textarea-autosize';

//new questions will not have _id
export default function CustomQuestion({uuid, index, deleteQuestion}){
    const {userInfo, setStatus, setUserInfo} = useContext(UserContext)
    const {questionData, setQuestionData} = useContext(EditJobPostContext)
    const [expanded, setExpanded] = useState(false)
    const [newType, setNewType] = useState(false)
    const [newState, setNewState] = useState("Required")
    const [newQuestion, setNewQuestion] = useState("")
    const [newTitle, setNewTitle] = useState("")
    const [newDescription, setNewDescription] = useState("")
    const [newOptions, setNewOptions] = useState([""])
    const [editQuestionLoading, setEditQuestionLoading] = useState(false)

    useEffect(() => {
        if (!questionData[uuid]._id){
            setExpanded(true)
        }
    },[])

    useEffect(() => {
        setNewType(questionData[uuid].type)
        setNewState(questionData[uuid].state)
        setNewQuestion(questionData[uuid].question)
        setNewTitle(questionData[uuid].title)
        setNewDescription(questionData[uuid].description)
        setNewOptions(questionData[uuid].options)

    },[questionData])

    function expand() { 
        if (!expanded){
            setExpanded(true)
        } else {
            setExpanded(false)
        }
    }

    const stopProp = (e) => {
        e.stopPropagation();
        // Your custom logic here
    };    

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const types = {text: 'Text', textarea: 'Textarea', date: 'Date', dropdown: 'Dropdown', checkbox: 'Checkbox', upload: 'Upload'}

    async function updateState(state){
        // setNewState(state)
        // add to question data map
        const updatedQuestions = {...questionData}
        const updatedQuestion = {...updatedQuestions[uuid]}
        updatedQuestion.state = state
        updatedQuestions[uuid] = updatedQuestion
        setQuestionData(updatedQuestions)

        const response = await fetch(process.env.REACT_APP_API_URL + '/question', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })

        // const response = await fetch(process.env.REACT_APP_API_URL + '/question', {
        //     method: 'PUT',
        //     body: JSON.stringify({_id: _id, type: questionData[_id].type, state: newState, question: questionData[_id].question, options: questionData[_id].options}),
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     credentials: 'include'
        // })
        // if (response.ok) {
        //     const questionInfo = await response.json()

        //     // add to question data map
        //     const updatedQuestions = {...questionData}
        //     updatedQuestions[questionInfo._id] = questionInfo
        //     setQuestionData(updatedQuestions)
        // }else{
        //     const message = await response.json();
        //     if (message === "not logged in"){
        //         setStatus("not logged in")
        //         setUserInfo(null)
        //     } else {
        //         console.log("error")
        //     }
        // }         
    }

    // async function saveQuestion(){
    //     setEditQuestionLoading(true)
    //     const response = await fetch(process.env.REACT_APP_API_URL + '/question', {
    //         method: 'PUT',
    //         body: JSON.stringify({_id: _id, type: newType, state: newState, question: newQuestion, options: newOptions}),
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //         credentials: 'include'
    //     })
    //     if (response.ok) {
    //         const questionInfo = await response.json()

    //         // add to question data map
    //         const updatedQuestions = {...questionData}
    //         updatedQuestions[questionInfo._id] = questionInfo
    //         setQuestionData(updatedQuestions)

    //         setEditQuestionLoading(false)
    //         setExpanded(false)
    //     }else{
    //         const message = await response.json();
    //         if (message === "not logged in"){
    //             setStatus("not logged in")
    //             setUserInfo(null)
    //         } else {
    //             setEditQuestionLoading(false)
    //             setExpanded(false)
    //             console.log("error")
    //         }
    //     }        
    // }

    async function updateType(type){
        // setNewType(type)
        // if (type !== "dropdown" && type !== "checkbox"){
        //     setNewOptions([""])
        // }
        // add to question data map
        const updatedQuestions = {...questionData}
        const updatedQuestion = {...updatedQuestions[uuid]}
        updatedQuestion.type = type
        if (type !== "dropdown" && type !== "checkbox"){
            updatedQuestion.options = [""]
        }
        updatedQuestions[uuid] = updatedQuestion
        setQuestionData(updatedQuestions)
    }

    async function updateQuestion(question){
        // setNewQuestion(question)
        // add to question data map
        const updatedQuestions = {...questionData}
        const updatedQuestion = {...updatedQuestions[uuid]}
        updatedQuestion.question = question
        updatedQuestions[uuid] = updatedQuestion
        setQuestionData(updatedQuestions)
    }

    async function updateTitle(title){
        // setNewTitle(title)
        // add to title data map
        const updatedQuestions = {...questionData}
        const updatedQuestion = {...updatedQuestions[uuid]}
        updatedQuestion.title = title
        updatedQuestions[uuid] = updatedQuestion
        setQuestionData(updatedQuestions)
    }

    async function updateDescription(description){
        // setNewDescription(description)
        // add to title data map
        const updatedQuestions = {...questionData}
        const updatedQuestion = {...updatedQuestions[uuid]}
        updatedQuestion.description = description
        updatedQuestions[uuid] = updatedQuestion
        setQuestionData(updatedQuestions)
    }

    async function createOption(){
        const updatedOptions = [...newOptions, ""]
        // setNewOptions(updatedOptions)

        // add to question data map
        const updatedQuestions = {...questionData}
        const updatedQuestion = {...updatedQuestions[uuid]}
        updatedQuestion.options = updatedOptions
        updatedQuestions[uuid] = updatedQuestion
        setQuestionData(updatedQuestions)
    }

    async function deleteOption(indexToRemove){
        const updatedOptions = newOptions.filter((_, index) => index !== indexToRemove);
        // setNewOptions(updatedOptions)

        // add to question data map
        const updatedQuestions = {...questionData}
        const updatedQuestion = {...updatedQuestions[uuid]}
        updatedQuestion.options = updatedOptions
        updatedQuestions[uuid] = updatedQuestion
        setQuestionData(updatedQuestions)
    }

    return (
        <>
        {(questionData[uuid].type !== "section" && questionData[uuid].type !== "description") ? <div className="job-post-page-custom-question-wrapper">
            <div className="job-post-page-custom-question" onClick={() => {expand()}}>
                <div className="job-post-page-custom-question-title">
                    <div className="job-post-page-fixed-question-question">{questionData[uuid].question}</div>
                    <div className="job-post-page-fixed-question-select">
                        <select className="job-post-page-fixed-question-state" onClick={stopProp} onChange={(e) => updateState(e.target.value)} value={newState}>
                            <option value={"Required"}>Required</option>
                            <option value={"Optional"}>Optional</option>
                            <option value={"Disabled"}>Disabled</option>
                        </select>
                        {/* <div className='job-post-page-fixed-question-state-icon'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                            </svg>
                        </div> */}
                    </div>
                </div>
                <div className={expanded ? "job-post-page-fixed-question-arrow arrow-expanded" : "job-post-page-fixed-question-arrow"}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                    </svg>
                </div>
            </div>
            <div className={expanded ? "job-post-page-fixed-question-expansion fixed-question-expanded" : "job-post-page-fixed-question-expansion"}>
                <div className='job-post-page-fixed-question-expansion-inner'>
                    <div className='job-post-page-custom-question-section'>
                        <div className="job-post-page-custom-question-input-title">Answer format <span className='required'>*</span></div>
                        <select className="job-post-page-dropdown" onChange={(e) => updateType(e.target.value)} value={newType}>
                            <option value="text">Text</option>
                            <option value="textarea">Textarea</option>
                            <option value="dropdown">Dropdown</option>
                            <option value="checkbox">Checkbox</option>
                            <option value="yes/no">Yes/No</option>
                            <option value="upload">Upload</option>
                        </select>
                    </div>
                    <div className='job-post-page-custom-question-section'>
                        <div className="job-post-page-custom-question-input-title">Question description <span className='required'>*</span></div>
                        <TextareaAutosize 
                            className="job-post-page-custom-question-input" 
                            onChange={(e) => updateQuestion(e.target.value)}
                            value={newQuestion}
                            minRows={2} 
                            data-gramm="false"/>
                    </div>
                    {   (newType === "Checkbox" || newType === "Dropdown") &&
                        <div className='job-post-page-custom-question-section'>
                            <div className="job-post-page-custom-question-input-title">Question options <span className='required'>*</span></div>
                            {newOptions && newOptions.map((desc, index) => {
                                return (<CustomQuestionOption key={uuid + "-option-" + index} index={index} desc={desc} newOptions={newOptions} setNewOptions={setNewOptions} deleteOption={deleteOption} uuid={uuid}/>)
                            })}
                            <div className="custom-question-section-add-option" onClick={() => createOption()}>
                                <div className="custom-question-section-add-option-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                    </svg>
                                </div>
                                <div className="custom-question-section-add-option-text">Add option</div>
                            </div>
                        </div>
                    }
                    <div className='job-post-page-custom-question-section'>
                        <div className="job-post-page-custom-question-input-title">Delete question</div>
                        <div className="job-post-page-custom-question-input-desc">Only questions that have not been answered can be deleted. Questions already answered may be disabled instead.</div>
                        <div className='job-post-page-custom-question-delete' onClick={() => {deleteQuestion(index)}}>Delete question</div>
                    </div>
                    {/* <div onClick={() => {saveQuestion()}}>save</div>
                    <div onClick={() => {setExpanded(false)}}>cancel</div> */}
                </div>
            </div>
        </div>
        : <div className="job-post-page-custom-question-wrapper">
            <div className="job-post-page-custom-question" onClick={() => {expand()}}>
                <div className="job-post-page-custom-question-title">
                    <div className={questionData[uuid].type === "section" ? "job-post-page-custom-section-title" : "job-post-page-fixed-question-question"}>{questionData[uuid].title}</div>
                </div>
                <div className={expanded ? "job-post-page-fixed-question-arrow arrow-expanded" : "job-post-page-fixed-question-arrow"}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                    </svg>
                </div>
            </div>
            <div className={expanded ? "job-post-page-fixed-question-expansion fixed-question-expanded" : "job-post-page-fixed-question-expansion"}>
                <div className='job-post-page-fixed-question-expansion-inner'>
                    <div className='job-post-page-custom-question-section'>
                        <div className="job-post-page-custom-question-input-title">{questionData[uuid].type === "section" ? <span>Section title <span className='required'>*</span></span> : "Description title"}</div>
                        <TextareaAutosize 
                            className="job-post-page-custom-question-input" 
                            onChange={(e) => updateTitle(e.target.value)}
                            value={newTitle}
                            minRows={2}
                            data-gramm="false" />
                    </div>
                    {questionData[uuid].type === "description" &&
                        <div className='job-post-page-custom-question-section'>
                            <div className="job-post-page-custom-question-input-title">Description content <span className='required'>*</span></div>
                            <TextareaAutosize 
                                className="job-post-page-custom-question-input" 
                                onChange={(e) => updateDescription(e.target.value)}
                                value={newDescription}
                                minRows={2} 
                                data-gramm="false" />
                        </div>
                    }
                    <div className='job-post-page-custom-question-section'>
                        <div className="job-post-page-custom-question-input-title">{questionData[uuid].type === "section" ? "Delete section" : "Delete description"}</div>
                        <div className='job-post-page-custom-question-delete' onClick={() => {deleteQuestion(index)}}>{questionData[uuid].type === "section" ? "Delete section" : "Delete description"}</div>
                    </div>
                    {/* <div onClick={() => {saveQuestion()}}>save</div>
                    <div onClick={() => {setExpanded(false)}}>cancel</div> */}
                </div>
            </div>
        </div>}
        </>
    )
}