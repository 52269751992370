import "./Checkbox.css"
export default function Checkbox({checked, setChecked, text, disabled}){

    return(
        <div className="checkbox-wrapper">    
            <div className={checked ? "checkbox-checked" : disabled ? "checkbox-unchecked checkbox-box-disabled" : "checkbox-unchecked"} 
                onClick={() => {checked ? setChecked(false) : (!disabled && setChecked(true))}}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={4} stroke="currentColor"
                    className={checked ? "checkbox-checkmark-checked" : "checkbox-checkmark-hidden"}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                </svg>
            </div>
            <div className={disabled ? "checkbox-text checkbox-text-disabled" : "checkbox-text"} 
            onClick={() => {checked ? setChecked(false) : (!disabled && setChecked(true))}}>{text}</div>
        </div>       
    )
}