import { useState, useEffect } from 'react'

export default function Question({_id, title, sectionState, sectionSetFunction, sectionOptions, last}){
    // const [expanded, setExpanded] = useState(false)

    // function expand() {
    //     if (expanded){
    //         setExpanded(false)
    //     } else {
    //         setExpanded(true)
    //     }
    // }

    // function toForm(title, state, setFunction) {
    //     return (
    //         <div key={title}>
    //             <div>{title}</div>
    //             <select onChange={(e) => setFunction(e.target.value)} value={state}>
    //                 {rules[title].map((option, index) => (
    //                     <option key={title + "-" + option} value={option}>
    //                         {option}
    //                     </option>
    //                 ))}
    //             </select>
    //         </div>
    //     )
    // }
    
    // const stopProp = (e) => {
    //     e.stopPropagation()
    // }

    return (
        <div className={last? "job-post-page-fixed-question-wrapper fixed-question-wrapper-last" : "job-post-page-fixed-question-wrapper"}>
            <div className="job-post-page-fixed-question">
                <div className="job-post-page-fixed-question-title">
                    <div>{title}</div>
                    {!sectionOptions ? 
                        <div className="job-post-page-fixed-question-required">Required</div>
                        :
                        // <div className="job-post-page-fixed-question-select">
                        //     <select className="job-post-page-fixed-question-state" onChange={(e) => sectionSetFunction(e.target.value)} value={sectionState}>
                        //         {sectionOptions.map((option) => (
                        //             <option key={title + "-" + option} value={option}>
                        //                 {option}
                        //             </option>
                        //         ))}
                        //     </select>
                        //     <div className='job-post-page-fixed-question-state-icon'>
                        //         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        //             <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                        //         </svg>
                        //     </div>
                        // </div>
                        <select className="job-post-page-fixed-question-state" onChange={(e) => sectionSetFunction(e.target.value)} value={sectionState}>
                            {sectionOptions.map((option) => (
                                <option key={title + "-" + option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                        }
                </div>
            </div>
            {/* <div className={expanded ? "job-post-page-fixed-question-expansion fixed-question-expanded" : "job-post-page-fixed-question-expansion"}>
                {sectionOptions && <div>
                    <div>{title}</div>
                    <select onChange={(e) => sectionSetFunction(e.target.value)} value={sectionState}>
                        {sectionOptions.map((option) => (
                            <option key={title + "-" + option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>}                    
                {sectionState !== "Disabled" && <div>
                    {subQuestions && subQuestions.map(({title, state, setFunction}) => {
                        return toForm(title, state, setFunction)
                    })}
                </div>}
            </div> */}
        </div>
    )
}