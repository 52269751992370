import { useContext, useState, useRef } from "react"
import ApplicationQuestion from "./ApplicationQuestion.js"
import Experience from "./Experience.js"
import Education from "./Education.js"
import { v4 as uuidv4 } from 'uuid';
import {ApplicationContext} from "./Context/ApplicationContext"
import LocationDropdown from "./LocationDropdownOneLine.js"
import Button from "./Button/Button.js"
import DragAndDrop from "./DragAndDrop.js"
import ApplicationCustomSection from "./ApplicationCustomSection.js"

export default function Application({sendApplication}){
    const {expArray, setExpArray, eduArray, setEduArray, color, loading, newFirstName, setNewFirstName,
        newLastName, setNewLastName, newPhone, setNewPhone, newEmail, setNewEmail, newLocation, setNewLocation, newExperience
        , setNewExperience, newEducation, setNewEducation, newResume, setNewResume, phone, address, experience, education
        , resume, questions, errors} = useContext(ApplicationContext)

    // const [location, setLocation] = useState("")

    async function handleCreateExp () {
        const newExp = {
            uuid: uuidv4(),
            title: "",
            company: "",
            location: "",
            from: "",
            to: "",
            desc: "",
            current: false,
            newObj: true,
        }

        // add to question array 
        if (expArray){
            setExpArray([...expArray, newExp])
        } else {
            setExpArray([newExp])
        }
    }

    async function handleCreateEdu () {
        const newEdu = {
            uuid: uuidv4(),
            school: "",
            degree: "",
            major: "",
            gpa: "",
            from: "",
            to: "",
            current: false,
            newObj: true,
        }

        // add to question array 
        if (eduArray){
            setEduArray([...eduArray, newEdu])
        } else {
            setEduArray([newEdu])
        }
    }

    const deleteExperience = (index) => {
        const newArray = [...expArray]
        newArray.splice(index, 1)
        setExpArray(newArray)
    }

    const deleteEducation = (index) => {
        const newArray = [...eduArray]
        newArray.splice(index, 1)
        setEduArray(newArray)
    }

    return(
        <div className="apply-page-main-inner">
            <div className="apply-page-application-note"><span className='required'>*</span> Required field</div>
            <div className="apply-page-section-title">Basic information</div>
            <div className="apply-page-application-name">
                <ApplicationQuestion type="text" state="Required" question="First Name" half="left" answer={newFirstName} setAnswer={setNewFirstName}/>
                <ApplicationQuestion type="text" state="Required" question="Last Name" half="right" answer={newLastName} setAnswer={setNewLastName}/>
            </div>
            <ApplicationQuestion type="text" state="Required" question="Email" answer={newEmail} setAnswer={setNewEmail}/>
            {phone !== "Disabled" && <ApplicationQuestion type="text" state={phone} question="Phone" validation="phone" answer={newPhone} setAnswer={setNewPhone}/>}
            {address !== "Disabled" 
                && <LocationDropdown state={address} selectedLocation={newLocation} setSelectedLocation={setNewLocation}/>}
            {(experience !== "Disabled" || education !== "Disabled" || resume !== "Disabled")
                && <div className="apply-page-section-title">Profile</div>}
            {experience !== "Disabled" &&
                <div className="apply-page-application-experience-wrapper">
                    <div className="apply-page-application-experience">
                        <div className="application-question-title">Experience <span className='application-question-optional'>{"(Optional)"}</span></div>
                        <div className="apply-page-add-experience" onClick={() => {handleCreateExp()}} >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="add-experience-plus">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                            </svg>
                            Add
                        </div> 
                    </div>
                    <div>
                        {expArray && expArray.map((exp, index) => {
                            return <Experience key={exp.uuid} index={index} deleteExperience={deleteExperience}/>
                        })}
                    </div>
                </div>
            }
            {education !== "Disabled" &&
                <div className="apply-page-application-experience-wrapper">
                    <div className="apply-page-application-experience">
                        <div className="application-question-title">Education <span className='application-question-optional'>{"(Optional)"}</span></div>
                        <div className="apply-page-add-experience" onClick={() => {handleCreateEdu()}} >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="add-experience-plus">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                            </svg>
                            Add
                        </div> 
                    </div>
                    <div>
                        {eduArray && eduArray.map((edu, index) => {
                            return <Education key={edu.uuid} index={index} deleteEducation={deleteEducation}/>
                        })}
                    </div>
                </div>
            }
            {resume !== "Disabled" && <DragAndDrop files={newResume} setFiles={setNewResume} types={["pdf", "doc", "docx"]} dndStyle={"drag-style"} state={resume} question="Resume"/>}
            {questions && questions.map((question) => {
                return <ApplicationCustomSection key={question} id={question} error={errors[question]}/>
            })}            
            <Button onClick={() => {sendApplication()}} isLoading={loading} buttonClassName={"submit-application-button"} buttonClassNameLoading={"submit-application-button-loading"} buttonColor={"#8075FF"}>Submit</Button>

        </div>
    ) 
}