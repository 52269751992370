import { useState } from "react";
// import { DialogContext } from "./Context/DialogContext";
import ConfirmImg from '../images/confirm.svg';
import Modal from './Modal'
import './Modal.css'
import DelayButton from '../Button/DelayButton'
import DatePicker from 'react-datepicker';
import Select from 'react-select'
import 'react-datepicker/dist/react-datepicker.css';
import SelectStyle from '../SelectStyle'

export default function EventInput({inputMsg, setInputMsg, customClick, customClose, buttonText, loading, setLoading, newDate, setNewDate, newTime, setNewTime, 
    newType, setNewType, newDescription, setNewDescription, newCompleted, setNewCompleted, errorMsg, setErrorMsg, setConfirmMsg, isCreate = false}){

    const [dateError, setDateError] = useState("")
    const [showDateError, setShowDateError] = useState("")

    const [timeError, setTimeError] = useState("")
    const [showTimeError, setShowTimeError] = useState("")

    const [descError, setDescError] = useState("")
    const [showDescError, setShowDescError] = useState("")

    const eventDescLimit = 1000

    const typeOptions = [
        { value: 'saved', label: 'Status - Saved' },
        { value: 'applied', label: 'Status - Applied' },
        { value: 'interviewing', label: 'Status - Interviewing' },
        { value: 'offer', label: 'Status - Offer' },
        { value: 'rejected', label: 'Status - Rejected' },
        { value: 'interview', label: 'Interview' },
        { value: 'assessment', label: 'Assessment' }
    ]

    const completedOptions = [
        { value: false, label: 'Incomplete' },
        { value: true, label: 'Completed' }
    ]

    function handleClose() {
        if (!loading){
            customClose && customClose()
            setInputMsg("")
        }

        // setShowBottomError(false)
        setShowDateError(false)
        setShowTimeError(false)
        setShowDescError(false)

    }

    function handleDelete(){
        if (!loading){
            setInputMsg("")

            // setShowBottomError(false)
            setShowDateError(false)
            setShowTimeError(false)
            setShowDescError(false)

            setConfirmMsg({title: "Reminder", desc: "This action cannot be reversed, are you sure?"})
        }
    }

    function CheckDateError(value){
        if ( !value ){
            return "Date cannot be empty."
        }

        return ""
    }

    function CheckTimeError(value){
        if ( !value ){
            return "Time cannot be empty."
        }

        const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

        if (!timeRegex.test(value)){
            return "Time must be in HH:MM format."
        }

        return ""
    }

    function CheckDescError(value){

        if ( !isNotAllSpaces(value) ){
            return "Description cannot be empty."
        }

        if ( value.length > eventDescLimit){
            return `Input exceeds maximum character limit of ${eventDescLimit}.`
        }

        return ""
    }

    function handleClick(){
        setLoading(true)
        
        setShowDateError(false)
        setShowTimeError(false)
        setShowDescError(false)

        // setShowBottomError(false)

        const dateErrMsg = CheckDateError(newDate)
        const timeErrMsg = CheckTimeError(newTime)
        const descErrMsg = CheckDescError(newDescription)

        if (dateErrMsg || timeErrMsg || descErrMsg ){
            
            setErrorMsg("Please fix the highlighted errors.")

            if (dateErrMsg){
                setDateError(dateErrMsg)
                setShowDateError(true)
            }

            if (timeErrMsg){
                setTimeError(timeErrMsg)
                setShowTimeError(true)
            }

            if (descErrMsg){
                setDescError(descErrMsg)
                setShowDescError(true)
            }

            const timeout = setTimeout(() => {
                setLoading(false)
                // customClick && customClick()
            }, 400);
        
            return () => {
                clearTimeout(timeout);
            };
        }

        customClick && customClick()
        // setValue("")
        // setInputMsg(null)        
    }

    const handleCompletedChange = (selectedOption) => {
        setNewCompleted(selectedOption)
    }

    const handleTypeChange = (selectedOption) => {
        setNewType(selectedOption)
        if (selectedOption.value === "assessment"){
            setNewCompleted({ value: false, label: 'Incomplete' })
        } else {
            setNewCompleted(null)
        }  
    }

    function handleDateText(value){
        setShowDateError(false)
        setNewDate(value)
    }

    function handleTimeText(value){
        setShowTimeError(false)
        setNewTime(value)
    }

    function handleDescText(value){
        setShowDescError(false)
        setNewDescription(value)
    }

    function isNotAllSpaces(inputString) {
        // Use a regular expression to check if the string contains at least one non-space character
        return /\S/.test(inputString);
    }

    return (
        <Modal open={inputMsg} onClose={() => handleClose()}>
            {inputMsg &&
                <div className="input-modal-inner-wrapper">
                    <div className="input-modal-title">
                        {inputMsg.title}
                        <div className="input-modal-desc">Required fields are marked with <span className='required'>*</span></div>
                    </div>
                    <div className="input-modal-main-area">
                        {inputMsg.desc && <div className="input-modal-text">{inputMsg.desc}</div>}
                        <div className="input-modal-label">Event type <span className='required'>*</span></div>
                        <Select options={typeOptions} 
                            // menuIsOpen={true}
                            value={newType}
                            onChange={handleTypeChange}
                            styles={SelectStyle({error: false})}
                        />
                        <div className="input-modal-spacing"></div>
                        <div className="input-modal-section">
                            <div className="input-modal-label">{(newType && newType.value === "assessment") ? "Due date " : "Event date "}<span className='required'>*</span></div>
                            <DatePicker
                                className={showDateError && "input-modal-input-box-error"}
                                selected={newDate}
                                onChange={(date) => handleDateText(date)}
                                placeholderText="Event date"
                            />
                            <div className={ showDateError ? "input-modal-error input-modal-error-expanded" : "input-modal-error"}>{dateError}</div>
                        </div>
                        {/* <div className="input-modal-spacing"></div> */}
                        <div className="input-modal-section">
                            <div className="input-modal-label">{(newType && newType.value === "assessment") ? "Due time " : "Event time "}<span className='required'>*</span></div>
                            <input 
                                className={showTimeError ? "input-modal-input input-modal-input-box-error" : "input-modal-input"}
                                type="text" 
                                placeholder={"HH:MM"}
                                value={newTime} 
                                onChange={ev => handleTimeText(ev.target.value)}/>
                            <div className={ showTimeError ? "input-modal-error input-modal-error-expanded" : "input-modal-error"}>{timeError}</div>
                        </div>
                        {newType && newType.value === "assessment" && 
                            <div className="input-modal-section">
                                <div className="input-modal-label">Assessment status <span className='required'>*</span></div>
                                <Select options={completedOptions} 
                                    // menuIsOpen={true}
                                    value={newCompleted}
                                    onChange={handleCompletedChange}
                                    styles={SelectStyle({error: false})}
                                />
                                {/* <div className="input-modal-spacing"></div> */}
                            </div>
                        }
                        <div className="input-modal-section">
                            <div className="input-modal-label">Event description <span className='required'>*</span></div>
                            <textarea 
                                className={showDescError ? "input-modal-input input-modal-input-box-error" : "input-modal-input"}
                                rows="5" 
                                placeholder={"Event description"}
                                value={newDescription} 
                                onChange={ev => handleDescText(ev.target.value)}>
                            </textarea>
                            <div className={ showDescError ? "input-modal-error input-modal-error-expanded" : "input-modal-error"}>{descError}</div>
                        </div>
                        {!isCreate && <div className={loading ? "delete-event-button delete-event-button-disabled" : "delete-event-button"} disabled={loading} onClick={() => handleDelete()}>Delete event</div>}
                    </div>
                    <div className={(loading || !errorMsg) ? "input-error-msg input-error-msg-hidden" : "input-error-msg"}>{errorMsg}</div>
                    <div className="input-modal-button-container">
                        <button className={loading ? "input-modal-cancel input-modal-cancel-disabled" : "input-modal-cancel"} onClick={() => handleClose()}>Cancel</button>
                        {/* <button className="input-modal-button" onClick={() => handleClick()}>{buttonText}</button> */}
                        <DelayButton buttonClassName="input-modal-button" buttonClassNameLoading="input-modal-button-loading" isLoading={loading} onClick={() => handleClick()}>{buttonText}</DelayButton>
                        {/* <DelayButton buttonClassName="input-modal-button" buttonClassNameLoading="input-modal-button-loading" isLoading={false} onClick={() => handleClick()}>{buttonText}</DelayButton> */}
                    </div>
                </div>
            }
        </Modal>
    )
}