import { useState, useContext, useRef, useEffect } from "react";
// import { DialogContext } from "./Context/DialogContext";
import Modal from './Modal'
import './Modal.css'
import DelayButton from '../Button/DelayButton'
import {UserContext} from "../Context/UserContext"
import LoadMoreButton from '../Button/Button'
import { v4 as uuidv4 } from 'uuid';

import 'react-datepicker/dist/react-datepicker.css';
//for template file select
export default function FileSelect({inputMsg, setInputMsg, templateId, setTemplateId, files, setFiles, setTemplateTitle, selectFromLocalClick}){
    const {setStatus, setUserInfo} = useContext(UserContext)
    const [tempTemplate, setTempTemplate] = useState("") //template
    const [tempTemplateTitle, setTempTemplateTitle] = useState("") //template

    const [templates,setTemplates] = useState([])
    const amount = 10 // numbers of transactions to request at a time
    const [reachEnd,setReachEnd] = useState(false)
    const [loading,setLoading] = useState(false)
    const [initialLoading,setInitialLoading] = useState(true)
    const [noFiles,setNoFiles] = useState(false) //no templates
    const inputRef = useRef(null)

    // only set when click on search or more items
    // must save item not id because we need updated time as well
    const [headItem, setHeadItem] = useState(null) 

    useEffect(() => {
        //preselect to current templateId
        setTempTemplate(templateId)
    }, [inputMsg]);

    //it's too fast, which pushes the loading down fast
    const fetchTemplatesDelay = async () => {
        setLoading(true)
        const timeout = setTimeout(() => {
            fetchTemplates()
        }, 400)
    }

    //inputMsg triggered when reopen
    useEffect(() => {

        if (!headItem){
            setReachEnd(false)
            setLoading(false)
            setInitialLoading(true)
        }

        //only fetch when opened
        inputMsg && fetchTemplatesDelay()
    }, [headItem, inputMsg]);

    //only place to set nofiles
    useEffect(() => {
        //check if no files
        if (!templates.length){
            setNoFiles(true)
        } else {
            setNoFiles(false)
        }
    }, [templates]);

    const fetchTemplates = async () =>{
        const response = await fetch(process.env.REACT_APP_API_URL + '/resumes', {
            method: 'POST',
            body: JSON.stringify({headItem: headItem, amount}),
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
        })
        if (response.ok) {
            const jsonResponse = await response.json()
            // make sure the returned response is not empty and is the one we requested

            if (!jsonResponse.headItem && !headItem || (jsonResponse.headItem && headItem && (jsonResponse.headItem._id === headItem._id))){

                    if (jsonResponse.templates.length) {
                        
                        //to force cleanup after running initialize
                        if (!jsonResponse.headItem){
                            setTemplates([...jsonResponse.templates])
                        } else {
                            setTemplates([...templates, ...jsonResponse.templates])
                        }

                        // headItem.current = jsonResponse.templates[jsonResponse.templates.length - 1]
                    }
                    if (jsonResponse.templates.length < amount){
                        setReachEnd(true)
                    }
                    // first request returns nothing means it's empty
                    if (!jsonResponse.headItem && !jsonResponse.templates.length){
                        setTemplates([])
                    }
                    setLoading(false)
                    setInitialLoading(false)
                }

        } else{
            const message = await response.json();
            if (message === "not logged in"){
                setStatus("not logged in")
                setUserInfo(null)
            } else {
                //show error message in-place
                setLoading(false)
                setInitialLoading(false)

                //if error reload
                setHeadItem(null)
            }
        }
    }

    const loadMore = () =>{
        templates ? setHeadItem(templates[templates.length - 1]) : setHeadItem(null)
    }

    function handleClose() {
        setTemplates([])
        setHeadItem(null)
        setReachEnd(false)
        setLoading(false)
        setNoFiles(false)
        setTempTemplate("")

        setInputMsg("")
    }

    //confirm selection
    function handleClick(){
        setTemplates([])
        setHeadItem(null)
        setReachEnd(false)
        setLoading(false)
        setNoFiles(false)
        setTempTemplate("")

        setTemplateId(tempTemplate)
        setTemplateTitle(tempTemplateTitle)
        setFiles("")
        setInputMsg("")
    }

    function selectFile(_id, title){
        setTempTemplate(_id)
        setTempTemplateTitle(title)
    }

    // triggers the file select when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click();
    };

    const handleClickUpload = (ev) => {
        if (ev.target.files){
            setFiles(ev.target.files)
            setTemplateId(null)
            setTemplateTitle(null)

            handleClose()
        }
    }

    function DaysAgo(date) {
        const createdAtDate = new Date(date)
        const currentDate = new Date()

        // Calculate the time difference in milliseconds
        const timeDifference = currentDate - createdAtDate;
      
        // Calculate the number of days
        const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

        if (daysAgo === 1){
            return "1 day ago"
        } else if (daysAgo < 100){
            return daysAgo + " days ago"
        } else {
            return "99+ days ago"
        }
    }

    return (
        <Modal open={inputMsg} onClose={() => handleClose()}>
            <div className="input-modal-inner-wrapper wide-modal">
                <div className="input-modal-title">{inputMsg.title}</div>
                <div className="file-select-modal-main-area">
                    <div className="input-modal-label">Select local file</div>
                    <div className="input-modal-local-upload-box">
                        <input id="input-file-upload" ref={inputRef} type="file" accept=".pdf"
                            onChange={handleClickUpload}/> 
                        {files ? <div className="upload-box-file-name">{files[0].name}</div>:
                        <div className="upload-box-no-file-name">No file selected</div>}
                        <button type="button" className="upload-box-upload-button" onClick={() => onButtonClick()}>Select file</button>
                    </div>
                    <div className="or-line">or</div>
                    <div className="input-modal-label">Select resume</div>
                    <div className="file-select-modal-main">
                        <div className="select-template-table-keys">
                            <div className="template-title">Title</div>
                            <div className="template-update-time">Updated</div> 
                        </div>
                        <div className={initialLoading ? "select-template-main-scroll no-scroll" : "select-template-main-scroll"}>
                            {!initialLoading && templates.length > 0 && templates.map(template => (
                                <div className={tempTemplate === template._id ? "file selected-file" : "file"} key={template._id} onClick={() => selectFile(template._id, template.title)}>
                                    <div className="template-title">{template.title}</div>
                                    <div className="template-update-time">{DaysAgo(template.updatedAt)}</div>                                
                                </div>
                            ))}
                            {initialLoading && 
                                <>
                                    <div className="skeleton-file">
                                        <div className="template-title">
                                            <div className="skeleton skeleton-text-no-margin last-line"></div>
                                        </div>
                                        <div className="template-update-time">
                                            <div className="skeleton skeleton-text-no-margin"></div>
                                        </div>                                   
                                    </div>
                                    <div className="skeleton-file">
                                        <div className="template-title">
                                            <div className="skeleton skeleton-text-no-margin last-line"></div>
                                        </div>
                                        <div className="template-update-time">
                                            <div className="skeleton skeleton-text-no-margin"></div>
                                        </div>                                   
                                    </div>
                                    <div className="skeleton-file">
                                        <div className="template-title">
                                            <div className="skeleton skeleton-text-no-margin last-line"></div>
                                        </div>
                                        <div className="template-update-time">
                                            <div className="skeleton skeleton-text-no-margin"></div>
                                        </div>                                   
                                    </div>
                                    <div className="skeleton-file">
                                        <div className="template-title">
                                            <div className="skeleton skeleton-text-no-margin last-line"></div>
                                        </div>
                                        <div className="template-update-time">
                                            <div className="skeleton skeleton-text-no-margin"></div>
                                        </div>                                   
                                    </div>
                                </>
                            }
                            {!noFiles && reachEnd && <div className="no-more">You've reached the end</div>}
                            {noFiles && !initialLoading && reachEnd && <div className="empty">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="empty-icon">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                                </svg>
                                No templates
                            </div>}
                            {!reachEnd && !initialLoading && <LoadMoreButton buttonClassName="load-more-button" buttonClassNameLoading="load-more-button-loading" isLoading={loading} onClick={() => {loadMore()}}>Load More</LoadMoreButton>}
                        </div>
                    </div>
                </div>
                <div className="input-modal-button-container">
                    <button className="input-modal-cancel" onClick={() => handleClose()}>Cancel</button>
                    <button className="input-modal-button" disabled={!tempTemplate} onClick={() => handleClick()}>Select</button>
                </div>
            </div>
        </Modal>
    )
}