import { useContext, useState, useRef } from "react"
import DemoFormFirst from "./DemoFormFirst"
import DemoFormSecond from "./DemoFormSecond"
import FormThird from "./FormThird"
import { FormContext } from "./Context/FormContext"
import { v4 as uuidv4 } from 'uuid'

export default function DemoForm(){
    const {setDemoResults, demoPage, setDemoPage} = useContext(FormContext) //add needed contexts
    const formTitles = ["Upload Your Resume", "Upload Job Description", "Results"]
    const formDesc = [
        ["Share what have at the moment and", " we’ll go from there!"], 
        ["Copy and paste the job title and", "description you’re applying."], 
        ["We're analyzing your documents,","this may take a couple seconds."]
    ]
    const [demoFile, setDemoFile] = useState("")

    //use to cancel requests
    const interupt = useRef(false)

    async function scanResume() {

        interupt.current = false

        const demoResultsText = {
            "hardMatch": [
                [
                    "programming",
                    [
                        1,
                        4
                    ]
                ],
                [
                    "computer science",
                    [
                        1,
                        3
                    ]
                ],
                [
                    "technology",
                    [
                        3,
                        2
                    ]
                ],
                [
                    "education",
                    [
                        1,
                        1
                    ]
                ],
                [
                    "algorithms",
                    [
                        1,
                        1
                    ]
                ],
                [
                    "Python",
                    [
                        2,
                        1
                    ]
                ],
                [
                    "Javascript",
                    [
                        5,
                        1
                    ]
                ],
                [
                    "html",
                    [
                        1,
                        1
                    ]
                ],
                [
                    "CSS",
                    [
                        1,
                        1
                    ]
                ],
                [
                    "React",
                    [
                        2,
                        1
                    ]
                ],
                [
                    "quality assurance",
                    [
                        1,
                        1
                    ]
                ]
            ],
            "hardNotMatch": [
                [
                    "sports",
                    5
                ],
                [
                    "TV",
                    4
                ],
                [
                    "video",
                    3
                ],
                [
                    "content",
                    2
                ],
                [
                    "mobile",
                    2
                ],
                [
                    "android",
                    2
                ],
                [
                    "support",
                    2
                ],
                [
                    "scrum",
                    2
                ],
                [
                    "software development",
                    2
                ],
                [
                    "documentation",
                    2
                ],
                [
                    "Java",
                    2
                ],
                [
                    "networking",
                    1
                ],
                [
                    "strategy",
                    1
                ],
                [
                    "brand",
                    1
                ],
                [
                    "CMS",
                    1
                ],
                [
                    "iOS",
                    1
                ],
                [
                    "QA",
                    1
                ],
                [
                    "automation",
                    1
                ],
                [
                    "engineering",
                    1
                ],
                [
                    "user interface",
                    1
                ],
                [
                    "technical",
                    1
                ],
                [
                    "maintenance",
                    1
                ],
                [
                    "Flex",
                    1
                ],
                [
                    "front-end",
                    1
                ],
                [
                    "API",
                    1
                ],
                [
                    "angular",
                    1
                ],
                [
                    "PHP",
                    1
                ],
                [
                    "Swift",
                    1
                ],
                [
                    "Objective-C",
                    1
                ],
                [
                    "distribution",
                    1
                ],
                [
                    "advertising",
                    1
                ],
                [
                    "training",
                    1
                ],
                [
                    "medical",
                    1
                ],
                [
                    "insurance",
                    1
                ],
                [
                    "outreach",
                    1
                ],
                [
                    "equal employment opportunity",
                    1
                ]
            ],
            "hardSkillScore": 23,
            "softMatch": [],
            "softNotMatch": [
                [
                    "networking",
                    1
                ],
                [
                    "mentorship",
                    1
                ],
                [
                    "planning",
                    1
                ],
                [
                    "driven",
                    1
                ],
                [
                    "vision",
                    1
                ]
            ],
            "softSkillScore": 0,
            "credentialsScore": 80,
            "credentialsSuggestions": [
                {
                    "type": "Measurable Results",
                    "success": true,
                    "measurableResults": "scholarship for a Top 2% cumulative GPA"
                },
                {
                    "type": "Measurable Results",
                    "success": true,
                    "measurableResults": "AWARDS & SCHOLARSHIPS  2021 Top 5% Worldwide in"
                },
                {
                    "type": "Measurable Results",
                    "success": true,
                    "measurableResults": "term grade average over 3.7)   2020"
                },
                {
                    "type": "Measurable Results",
                    "success": true,
                    "measurableResults": "Continuing Undergraduate Students (Three-time recipient"
                },
                {
                    "type": "Measurable Results",
                    "success": true,
                    "measurableResults": "teammates to collect 15000+ pieces of"
                },
                {
                    "type": "Measurable Results",
                    "success": true,
                    "measurableResults": "financial text (achieved 92% accuracy)  • Researched"
                },
                {
                    "type": "Measurable Results",
                    "success": true,
                    "measurableResults": "the team in 15+ projects in"
                },
                {
                    "type": "Measurable Results",
                    "success": true,
                    "measurableResults": "events by more than 30%  • Co-organized"
                },
                {
                    "type": "Measurable Results",
                    "success": true,
                    "measurableResults": "Experience Camp for 40+ prospective university"
                },
                {
                    "type": "Job Title",
                    "success": false,
                    "remove": false,
                    "matchedTitle": null,
                    "partialMatched": null
                },
                {
                    "type": "Degree",
                    "success": true,
                    "remove": false,
                    "eduLevel": 3,
                    "jobLevel": 3
                }
            ],
            "formatScore": 83,
            "formatSuggestions": [
                {
                    "type": "Email",
                    "success": true,
                    "remove": false,
                    "email": "skillglance@gmail.com"
                },
                {
                    "type": "Phone",
                    "success": true,
                    "remove": false,
                    "phone": "+1 (310) 123-4567"
                },
                {
                    "type": "Personal Pronouns",
                    "success": false,
                    "remove": true,
                    "pronouns": [
                        "I",
                        "myself"
                    ]
                },
                {
                    "type": "Sections",
                    "success": true,
                    "header": "Education ",
                    "headerTitle": "Education"
                },
                {
                    "type": "Sections",
                    "success": true,
                    "header": "Professional Experience",
                    "headerTitle": "Experience"
                },
                {
                    "type": "Word Count",
                    "success": true,
                    "words": 505
                }
            ],
            "overallScore": 50,
            "stats": {
                "hardSkillPercentile": "44",
                "softSkillPercentile": "0",
                "credentialsPercentile": "44",
                "formatPercentile": "90",
                "overallPercentile": "10"
            },
        }

        setTimeout(() => {
            if (!interupt.current){
                setDemoResults(demoResultsText)
            }
        }, "2000");

    }

    async function handlePrev() {
        setDemoPage((currentPage) => currentPage - 1)
        interupt.current = true

    }

    async function handleNext() {
        setDemoPage((currentPage) => currentPage + 1)
        interupt.current = true
    }

    const pageDisplay = () => {
        if (demoPage === 0){
            return <DemoFormFirst setDemoFile={setDemoFile} demoFile={demoFile}/>
        } else if (demoPage === 1){
            return <DemoFormSecond />
        } else {
            return <FormThird />
        }
    }

    function handleSubmit(ev){
        ev.preventDefault()
        setDemoResults(null)
        setDemoPage(2)
        scanResume()
    }

    return(
        <div className="upload-form-container">
            <form className="upload_form" onSubmit={handleSubmit}>
                <div className="form-container">
                    <div className="form-inner-container">
                        <div className="progress-bar">
                            <div style={{width: demoPage === 0? "33.3%" : demoPage === 1? "66.6%" : "100%"}}></div>
                        </div>
                        <div className="header">
                            <h1 id="form-title">{formTitles[demoPage]}</h1>
                            <div className="form-desc-container">
                                <div className="form-desc">{formDesc[demoPage][0]}</div>
                                <div className="form-desc">{formDesc[demoPage][1]}</div>
                            </div>
                        </div>
                        <div className={ demoPage === 2 ? "form-body form-body-justify-center" : "form-body"}>{pageDisplay()}</div>
                        <div className="form-footer">
                            <button
                                type="button"
                                className={ demoPage === 1 ? "next-previous-button right-space" : "next-previous-button"}
                                style={{display: demoPage === 0 ? "none" : "block"}}
                                onClick={() => handlePrev()}>&#60; Prev
                            </button>
                            <button 
                                type="button"
                                className="next-previous-button"
                                disabled={demoPage === 0 && !demoFile }
                                style={{display: demoPage === 0 ? "block" : "none"}}
                                onClick={() => handleNext()}>Next &#62;
                            </button>
                            <button 
                                type="submit"
                                className={ demoPage === 1 ? "submit-button left-space" : "submit-button"}
                                style={{display: demoPage === 1? "block" : "none"}}>Submit
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>

    ) 
}