import { useContext, useEffect, useState} from "react"
import { Link} from "react-router-dom"
import { UserContext } from "./Context/UserContext"
import {LeftBarContext} from "./Context/LeftBarContext"

export default function LeftBar() {
    const {setUserInfo, userInfo, status, setStatus} = useContext(UserContext);
    const {expand, setExpand, lock, selected} = useContext(LeftBarContext)

    const [minExpand, setMinExpand] = useState(false)

    async function logout() {
        const response = await fetch(process.env.REACT_APP_API_URL + '/logout', {
            credentials: 'include',
            method: 'POST',
        })
        if (response.ok) {
            setStatus("not logged in")
            setUserInfo(null)
        } else {
            console.log("logout failed")
        }
    }

    // useEffect (() => {
    //     setExpand(false)
    // }, [])

    return (
        <div className="header-container">
            <div className={ expand || lock ? "left-bar left-bar-expanded" : "left-bar"} onMouseEnter={() => setExpand(true)} onMouseLeave={() => setExpand(false)}>
                <nav id="left-bar-top">
                    <Link to="/" id="left-bar-logo">
                        <svg width="101" height="137" viewBox="0 0 101 137" fill="none" xmlns="http://www.w3.org/2000/svg" className="logo-icon">
                            <path fillRule="evenodd" clipRule="evenodd" d="M3 108C4.99461 113.986 8.02652 119.068 12.0957 123.246C16.2461 127.562 21.3373 130.827 27.3692 133.041C33.4011 135.255 40.8441 136.361 49.6983 136.361C66.2998 136.361 78.7233 131.492 86.9688 121.752C90.0275 118.17 92.3247 113.586 93.8603 108H69.4488C67.9573 109.762 65.9669 111.275 63.4776 112.538C59.7699 114.42 54.8724 115.36 48.7852 115.36C44.0261 115.36 39.9033 114.696 36.417 113.368C33.0967 112.156 30.3537 110.367 28.1878 108H3Z" fill="#8075FF"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M55 25.4634C57.4326 25.9074 59.9392 26.1394 62.5 26.1394C79.8499 26.1394 94.7132 15.4925 100.913 0.37579C97.5146 0.878803 94.0378 1.13939 90.5 1.13939C86.1669 1.13939 81.9252 0.748478 77.8081 0C73.899 4.49725 68.296 7.31465 62.0722 7.31465C59.5937 7.31465 57.2138 6.86788 55 6.04617V25.4634Z" fill="#8075FF"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M48.5 102.14C75.2858 102.14 97 80.4255 97 53.6396C97 26.8538 75.2858 5.13965 48.5 5.13965C21.7142 5.13965 0 26.8538 0 53.6396C0 80.4255 21.7142 102.14 48.5 102.14ZM48 80.1396C61.8071 80.1396 73 68.2752 73 53.6396C73 39.0041 61.8071 27.1396 48 27.1396C34.1929 27.1396 23 39.0041 23 53.6396C23 68.2752 34.1929 80.1396 48 80.1396Z" fill="#8075FF"/>
                        </svg>
                    </Link>
                    <Link to="/dashboard" className={selected === "dashboard" ? "left-bar-selected left-bar-button" : "left-bar-button"}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="left-bar-top-icon">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                        </svg>
                        <div className="left-bar-text">Dashboard</div>
                    </Link>
                    {/* <Link to="/scan" className={selected === "scan" ? "left-bar-selected left-bar-button" : "left-bar-button"}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="left-bar-top-icon">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <div className="left-bar-text">New Scan</div>
                    </Link> */}
                    <Link to="/history" className={selected === "scan" ? "left-bar-selected left-bar-button" : "left-bar-button"}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="left-bar-top-icon">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
                        </svg>

                        <div className="left-bar-text">Scans</div>
                    </Link>
                    <Link to="/resumes" className={selected === "templates" ? "left-bar-selected left-bar-button" : "left-bar-button"}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="left-bar-top-icon">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                        </svg>
                        <div className="left-bar-text">Resumes</div>
                    </Link>
                    <Link to="/jobs" className={selected === "jobs" ? "left-bar-selected left-bar-button" : "left-bar-button"}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="left-bar-top-icon">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
                        </svg>
                        <div className="left-bar-text">Jobs</div>
                    </Link>
                    <Link to="/membership" className={selected === "membership" ? "left-bar-selected left-bar-button" : "left-bar-button"}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="left-bar-top-icon">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                        <div className="left-bar-text">Membership</div>
                    </Link>
                    <Link to="/settings" className={selected === "settings" ? "left-bar-selected left-bar-button" : "left-bar-button"}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="left-bar-top-icon">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                        </svg>
                        <div className="left-bar-text">Settings</div>
                    </Link>
                </nav>
                <a onClick={logout} id="left-bar-bottom">
                    <svg id="left-bar-logout-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                    </svg>
                    <div className="left-bar-text">Logout</div>
                </a>
            </div>
            <div className={ minExpand ? "leftbar-overlay" : ""} onClick={() => {setMinExpand(false)}}></div>
            <div className={ minExpand ? "min-left-bar min-left-bar-expanded" : "min-left-bar"}>
                <nav id="left-bar-top">
                    <div className="left-bar-top-logo-wrapper">
                        <Link to="/" id="min-left-bar-logo">
                            <svg width="101" height="137" viewBox="0 0 101 137" fill="none" xmlns="http://www.w3.org/2000/svg" className="logo-icon">
                                <path fillRule="evenodd" clipRule="evenodd" d="M3 108C4.99461 113.986 8.02652 119.068 12.0957 123.246C16.2461 127.562 21.3373 130.827 27.3692 133.041C33.4011 135.255 40.8441 136.361 49.6983 136.361C66.2998 136.361 78.7233 131.492 86.9688 121.752C90.0275 118.17 92.3247 113.586 93.8603 108H69.4488C67.9573 109.762 65.9669 111.275 63.4776 112.538C59.7699 114.42 54.8724 115.36 48.7852 115.36C44.0261 115.36 39.9033 114.696 36.417 113.368C33.0967 112.156 30.3537 110.367 28.1878 108H3Z" fill="#8075FF"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M55 25.4634C57.4326 25.9074 59.9392 26.1394 62.5 26.1394C79.8499 26.1394 94.7132 15.4925 100.913 0.37579C97.5146 0.878803 94.0378 1.13939 90.5 1.13939C86.1669 1.13939 81.9252 0.748478 77.8081 0C73.899 4.49725 68.296 7.31465 62.0722 7.31465C59.5937 7.31465 57.2138 6.86788 55 6.04617V25.4634Z" fill="#8075FF"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M48.5 102.14C75.2858 102.14 97 80.4255 97 53.6396C97 26.8538 75.2858 5.13965 48.5 5.13965C21.7142 5.13965 0 26.8538 0 53.6396C0 80.4255 21.7142 102.14 48.5 102.14ZM48 80.1396C61.8071 80.1396 73 68.2752 73 53.6396C73 39.0041 61.8071 27.1396 48 27.1396C34.1929 27.1396 23 39.0041 23 53.6396C23 68.2752 34.1929 80.1396 48 80.1396Z" fill="#8075FF"/>
                            </svg>
                        </Link>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="left-bar-close" onClick={() => {setMinExpand(false)}}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                    <Link to="/dashboard" className={selected === "dashboard" ? "min-left-bar-selected min-left-bar-button" : "min-left-bar-button"} onClick={() => {setMinExpand(false)}}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="left-bar-top-icon">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                        </svg>
                        <div className="left-bar-text">Dashboard</div>
                    </Link>
                    {/* <Link to="/scan" className={selected === "scan" ? "min-left-bar-selected min-left-bar-button" : "min-left-bar-button"} onClick={() => {setMinExpand(false)}}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="left-bar-top-icon">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <div className="left-bar-text">New Scan</div>
                    </Link> */}
                    <Link to="/history" className={selected === "scan" ? "min-left-bar-selected min-left-bar-button" : "min-left-bar-button"} onClick={() => {setMinExpand(false)}}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="left-bar-top-icon">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
                        </svg>


                        <div className="left-bar-text">Scans</div>
                    </Link>
                    <Link to="/resumes" className={selected === "templates" ? "min-left-bar-selected min-left-bar-button" : "min-left-bar-button"} onClick={() => {setMinExpand(false)}}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="left-bar-top-icon">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                        </svg>
                        <div className="left-bar-text">Resumes</div>
                    </Link>
                    <Link to="/jobs" className={selected === "jobs" ? "min-left-bar-selected min-left-bar-button" : "min-left-bar-button"} onClick={() => {setMinExpand(false)}}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="left-bar-top-icon">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
                        </svg>
                        <div className="left-bar-text">Jobs</div>
                    </Link>
                    <Link to="/membership" className={selected === "membership" ? "min-left-bar-selected min-left-bar-button" : "min-left-bar-button"} onClick={() => {setMinExpand(false)}}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="left-bar-top-icon">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                        <div className="left-bar-text">Membership</div>
                    </Link>
                    <Link to="/settings" className={selected === "settings" ? "min-left-bar-selected min-left-bar-button" : "min-left-bar-button"} onClick={() => {setMinExpand(false)}}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="left-bar-top-icon">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                        </svg>
                        <div className="left-bar-text">Settings</div>
                    </Link>
                </nav>
                <a onClick={logout} id="left-bar-bottom">
                    <svg id="left-bar-logout-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                    </svg>
                    <div className="left-bar-text">Logout</div>
                </a>
            </div>
            <div className='top-menu'>
                <a className='top-menu-item' onClick={() => {setMinExpand(true)}}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                    </svg>
                </a>                
                <Link to="/" className="top-menu-logo">
                    <svg width="101" height="137" viewBox="0 0 101 137" fill="none" xmlns="http://www.w3.org/2000/svg" className="logo-icon">
                        <path fillRule="evenodd" clipRule="evenodd" d="M3 108C4.99461 113.986 8.02652 119.068 12.0957 123.246C16.2461 127.562 21.3373 130.827 27.3692 133.041C33.4011 135.255 40.8441 136.361 49.6983 136.361C66.2998 136.361 78.7233 131.492 86.9688 121.752C90.0275 118.17 92.3247 113.586 93.8603 108H69.4488C67.9573 109.762 65.9669 111.275 63.4776 112.538C59.7699 114.42 54.8724 115.36 48.7852 115.36C44.0261 115.36 39.9033 114.696 36.417 113.368C33.0967 112.156 30.3537 110.367 28.1878 108H3Z" fill="#8075FF"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M55 25.4634C57.4326 25.9074 59.9392 26.1394 62.5 26.1394C79.8499 26.1394 94.7132 15.4925 100.913 0.37579C97.5146 0.878803 94.0378 1.13939 90.5 1.13939C86.1669 1.13939 81.9252 0.748478 77.8081 0C73.899 4.49725 68.296 7.31465 62.0722 7.31465C59.5937 7.31465 57.2138 6.86788 55 6.04617V25.4634Z" fill="#8075FF"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M48.5 102.14C75.2858 102.14 97 80.4255 97 53.6396C97 26.8538 75.2858 5.13965 48.5 5.13965C21.7142 5.13965 0 26.8538 0 53.6396C0 80.4255 21.7142 102.14 48.5 102.14ZM48 80.1396C61.8071 80.1396 73 68.2752 73 53.6396C73 39.0041 61.8071 27.1396 48 27.1396C34.1929 27.1396 23 39.0041 23 53.6396C23 68.2752 34.1929 80.1396 48 80.1396Z" fill="#8075FF"/>
                    </svg>
                </Link>
                <Link to="/membership" className='top-menu-item'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                </Link>
            </div>        
        </div>
    )

}