import { useState, useEffect, useContext } from 'react'
import {EditJobPostContext} from "./Context/EditJobPostContext"

export default function CustomQuestionOption({index, desc, newOptions, setNewOptions, deleteOption, uuid}){
    const [newOption, setNewOption] = useState("")
    const {questionData, setQuestionData} = useContext(EditJobPostContext)

    useEffect(() => {
        setNewOption(desc)
    }, [desc])

    const handleChange = (e) => {
        const updatedOptions = [...newOptions]
        updatedOptions[index] = e.target.value
        // setNewOptions(updatedOptions)
        // setNewOption(e.target.value)

        // add to question data map
        const updatedQuestions = {...questionData}
        const updatedQuestion = {...updatedQuestions[uuid]}
        updatedQuestion.options = updatedOptions
        updatedQuestions[uuid] = updatedQuestion
        setQuestionData(updatedQuestions)
    } 

    return (
        <div className='job-post-page-custom-question-option'> 
            <input className={index === 0 ? 'job-post-page-custom-question-option-input first-option' : "job-post-page-custom-question-option-input"} onChange={handleChange} value={newOption}></input>
            {index !== 0 && <div className="job-post-page-custom-question-option-delete" onClick={() => {deleteOption(index)}}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
            </div>}
        </div>
    )
}