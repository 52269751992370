import { useContext, useState, useEffect} from 'react'
import { FormContext } from "./Context/FormContext"

export default function SectionButton({title, score, section, setSection, scrollTo}){
    const [progressValue, setProgressValue] = useState(0)
    const [desc, setDesc] = useState("")

    useEffect(() => {
        if (progressValue < score){
            setTimeout(() => {
                setProgressValue(progressValue + 1)
            }, 3)
        } else if (progressValue > score){
            setTimeout(() => {
                setProgressValue(progressValue - 1)
            }, 3)
        }
    }, [progressValue, score])

    useEffect(() => {
        if (score === 100){
            setDesc("Perfect score!")
        } else if (score >= 70){
            setDesc("Looking good!")
        } else if (score >= 50){
            setDesc("Almost there!")
        } else {
            setDesc("It's a start!")
        }
    }, [score])


    const handleClick = (title) => {
        setSection(title)
    }

    useEffect(() => {
        if (section){
            scrollTo()
        }
        
    }, [section])

    return (
        <div className={ title === section? "results-section-button-selected" : "results-section-button" }  onClick={() => handleClick(title)}>
            <div className="results-section-score-circle">
                <div className="results-section-score">{score}</div>
            </div>
            <div className="results-section-middle">
                <div className="results-section-text-title">{title}</div>
                <div className="results-section-text-desc">{desc}</div>
                <div className="score-bar">
                    <div style={{ width: `${progressValue}%` }}></div>
                </div>
            </div>
            <div className="results-section-arrow">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                </svg>
            </div>
        </div>
    )
}