// status = pending unless server responses
// display loading while pending (needed when refresh page)
export default function LoadingGeneric ({text}) {
    return <div className='loader-outer-container'>
        <div className='loader-container-generic'>
            <svg className="loader-circle-container-generic">
                <circle cx="19" cy="19" r="19"></circle>
            </svg>
        </div>
    </div>
}

