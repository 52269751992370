import { useContext, useState, useEffect} from 'react'

export default function IndexPageSectionButton({score}){
    const [progressValue, setProgressValue] = useState(0)

    useEffect(() => {
        if (progressValue < score){
            setTimeout(() => {
                setProgressValue(progressValue + 1)
            }, 4)
        }
    }, [progressValue, score])

    return (
        <div className="section-button-index-page">
            <div className="section-score-circle-index-page">
                <div className="section-score-index-page">{score}</div>
            </div>
            <div className="section-middle-index-page">
                <div className="section-text-title-index-page">Hard Skills</div>
                <div className="section-text-desc-index-page">Great! You're ready to apply</div>
                <div className="score-bar-index-page">
                    <div style={{ width: `${progressValue}%` }}></div>
                </div>
            </div>
        </div>
    )
}