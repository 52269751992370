import { useContext, useState, forwardRef, useEffect } from "react"
import DatePicker from 'react-datepicker';
import Checkbox from './Checkbox';
import {ApplicationContext} from "./Context/ApplicationContext"
import LocationDropdown from "./LocationDropdownOneLine.js"

export default function Experience({index, deleteExperience, newObj}){
    const {expArray, setExpArray} = useContext(ApplicationContext)
    const [title, setTitle] = useState("")
    const [company, setCompany] = useState("")
    const [location, setLocation] = useState("")
    const [from, setFrom] = useState(undefined)
    const [to, setTo] = useState(undefined)
    const [desc, setDesc] = useState("")
    const [current, setCurrent] = useState(false)
    const [expanded, setExpanded] = useState(true)
    const [dropdown, setDropdown] = useState(false)

    useEffect(() => {
        expArray[index].newObj && document.getElementById("experience" + index).scrollIntoView({
            behavior: 'smooth'
        });

        const newExp = {
            uuid: expArray[index].uuid,
            title: title,
            company: company,
            location: location,
            from: from,
            to: to,
            desc: desc,
            current: current,
            newObj: false,
        }

        const newArray = [...expArray]
        newArray[index] = newExp
        setExpArray(newArray)
    }, [])

    const titleChange = (e) => {
        setTitle(e.target.value)
    }

    const companyChange = (e) => {
        setCompany(e.target.value)
    }

    // const locationChange = (e) => {
    //     setLocation(e.target.value)
    // }

    const fromChange = (value) => {
        setFrom(value)
    }

    const toChange = (value) => {
        setTo(value)
    }

    const descChange = (e) => {
        setDesc(e.target.value)
    }

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <input className='input-modal-input' readOnly={true} onClick={onClick} value={value} ref={ref}></input>
    ))

    const handleSave = () => {
        const newExp = {
            uuid: expArray[index].uuid,
            title: title,
            company: company,
            location: location,
            from: from,
            to: to,
            desc: desc,
            current: current,
        }

        // console.log(desc)

        const newArray = [...expArray]
        newArray[index] = newExp
        setExpArray(newArray)

        setExpanded(false)
    }

    const handleCancel = () => {

        if (!expArray[index].title){
            deleteExperience(index)
        }

        setTitle(expArray[index].title)
        setCompany(expArray[index].company)
        setLocation(expArray[index].location)
        setFrom(expArray[index].from)
        setTo(expArray[index].to)
        setDesc(expArray[index].desc)
        setCurrent(expArray[index].current)

        setExpanded(false)
    }

    const formatDate = () => {
        const fromDate = new Date(from);
        const formattedFrom = fromDate.toLocaleString("en-US", { month: "long", year: "numeric" });
        const toDate = new Date(to);
        const formattedTo = toDate.toLocaleString("en-US", { month: "long", year: "numeric" });

        return formattedFrom + " - " + formattedTo
    }

    const editClick = () => {
        setDropdown(false)
        setExpanded(true)
    }

    const deleteClick = () => {
        deleteExperience(index)
    }

    return(
        <div id={"experience" + index}>
        {expanded ?
            <div className="apply-page-application-experience-box">
                <div className="application-experience-title-company-wrapper">
                    <div className="application-question application-question-left">
                        <div className="application-question-title">
                            Title <span className='required'>*</span>
                        </div>
                        <input className='input-modal-input blue-border' onChange={titleChange} value={title}></input>
                    </div>
                    <div className="application-question application-question-right">
                        <div className="application-question-title">
                            Company <span className='required'>*</span>
                        </div>
                        <input className='input-modal-input blue-border' onChange={companyChange} value={company}></input>
                    </div>
                </div>
                <LocationDropdown state={"Required"} selectedLocation={location} setSelectedLocation={setLocation}/>
                <div className="application-experience-title-company-wrapper">
                    <div className="application-question application-question-left">
                        <div className="application-question-title">
                            From <span className='required'>*</span>
                        </div>
                        <DatePicker
                            // className={showDateError && "input-modal-input-box-error"}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            selected={from}
                            onChange={(date) => fromChange(date)}
                            // customInput={<ExampleCustomInput />}
                        />
                    </div>
                    <div className="application-question application-question-right">
                        <div className="application-question-title">
                            To <span className='required'>*</span>
                        </div>
                        <DatePicker
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            // className={showDateError && "input-modal-input-box-error"}
                            selected={to}
                            onChange={(date) => toChange(date)}
                            disabled={current ? true : false}
                            // customInput={<ExampleCustomInput />}
                        />
                    </div>
                </div>
                <div className="application-question">
                    <div style={{fontSize: "0.8em", fontWeight: "500"}}>
                        <Checkbox checked={current} setChecked={setCurrent} text="Current experience"/>
                    </div>
                </div>
                <div className="application-question">
                    <div className="application-question-title">
                        Description <span className='application-question-optional'>{"(Optional)"}</span>
                    </div>
                    <textarea 
                        className='input-modal-input blue-border' 
                        onChange={descChange} 
                        value={desc}
                        rows={5}></textarea>
                </div>
                <div className="application-experience-buttons">
                    <button className="input-modal-cancel" onClick={() => handleCancel()}>Cancel</button>
                    <button className="input-modal-button" onClick={() => handleSave()}>Save</button>
                </div>

            </div>
                
            :<div className="apply-page-application-experience-box">
                <div className="apply-page-application-experience-box-top">
                    <div className="apply-page-application-experience-box-top-left">
                        <div className="application-experience-title">{title}</div>
                        <div className="application-experience-company">{company}</div>
                        <div className="application-experience-date">{formatDate()}</div>
                        <div className="application-experience-date">{location.string}</div>
                    </div>
                    <div className="apply-page-application-experience-box-top-right">
                    <div className="apply-page-application-experience-dropdown">
                        <div className="template-dropdown" tabIndex={0} onBlur={() => {setDropdown(false)}}>
                            <div className={dropdown ? "template-dropdown-menu selected-dropdown" : "template-dropdown-menu"}>
                                    <div className="experience-dropdown-item" onClick={() => {editClick()}}>Edit</div>
                                    <div className="experience-dropdown-item" onClick={() => {deleteClick()}}>Delete</div>
                                </div>
                                <div className="templates-page-more-icon-wrapper" onClick={() => {setDropdown(true)}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="templates-page-more-icon">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                                    </svg>
                                </div>
                            </div>                          
                        </div>
                    </div>
                </div>
                <div className="application-experience-desc">{desc.trim().replace(/\n$/, "")}</div>
            </div>}
        </div>
    ) 
}