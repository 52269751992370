import { useState, createContext } from "react";

export const UserContext = createContext({});

export function UserContextProvider({children}) {
    const [userInfo, setUserInfo] = useState({})
    const [status, setStatus] = useState("pending") //logged in, not logged in, or pending
    // const [expand, setExpand] = useState(false)
    // const [lock, setLock] = useState(false)
    // const [selected, setSelected] = useState("dashboard")
    const [indexPage, setIndexPage] = useState(null)
    return (
        // <UserContext.Provider value={{userInfo, setUserInfo, status, setStatus, expand, setExpand, lock, setLock, selected, setSelected, indexPage, setIndexPage}}>
        <UserContext.Provider value={{userInfo, setUserInfo, status, setStatus, indexPage, setIndexPage}}>
            {children}
        </UserContext.Provider>
    )
}
