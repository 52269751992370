import { Outlet } from "react-router-dom"
import LeftBar from "./LeftBar"
import {UserContext} from "./Context/UserContext";
import {useContext} from 'react'


export default function LayoutLeftBar(){

    const {userInfo} = useContext(UserContext)

    return (
        <main>
            <LeftBar />
            <div id="left-bar-outlet">
                <Outlet classname="outlet"/>
            </div>
        </main>
    )
}