// status = pending unless server responses
// display loading while pending (needed when refresh page)
export default function SelectStyleGeneric ({error}) {
    return {
        control: (styles, {isFocused}) => ({ ...styles, 
            // display: 'block',
            // width: '100%',
            padding: '1px 0px',
            border: isFocused ? 'solid 1px #4d90fe !important' : (error ? '#ec6f61 solid 1px !important' :'1px solid rgb(213, 213, 213) !important'),
            // border: isFocused && 'solid 1px #8075FF !important',
            borderRadius: '5px',
            fontFamily: 'Helvetica',
            fontSize: '0.8em',
            boxShadow: "none",
            lineHeight: "1.5rem",
            cursor: "text"
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        //   const color = chroma(data.color);
          return {
            ...styles,
            color: 'auto',
            // fontSize: '0.8em',
            // backgroundColor: isSelected || isFocused && '#aca4ff',
            cursor: isDisabled ? 'not-allowed' : 'default',
            paddingTop: '6px',
            paddingBottom: '6px',
            cursor: "pointer"
          }
        },
        menu: (styles) => {
            //   const color = chroma(data.color);
            return {
                ...styles,
                width: "100%",
                // marginLeft: "5px",
                zIndex: "999 !important",
                fontSize: '0.8em',
            }
        }
    }
}

